import qs from 'query-string';
import {
  LoansSummary,
  ValuationSummary,
  FinanceInfo,
  FinanceInformation,
  FinanceSaleInformation,
} from '@house-id/houseid-types/dist/finances/finances';

import { propertyApi } from '../../../api/api.property';
import { PropertyId } from '../../../types/types.property';
import { HIDApiTags } from '../../../../../api/HIDApiTags';
import { InsuranceWithParams } from '../../Content/modules/Insurance/types.insurance';

type PatchPropertyInformationRequest = PropertyId & {
  financeInformation: Partial<FinanceInformation> | Partial<FinanceSaleInformation>;
};

export const financeApi = propertyApi.injectEndpoints({
  endpoints: (builder) => ({
    getLoansSummary: builder.query<LoansSummary, PropertyId>({
      query: ({ propertyId }) => `/loans?${qs.stringify({ propertyId })}`,
      providesTags: () => [HIDApiTags.LOAN],
    }),
    getMovestaValuation: builder.query<ValuationSummary, PropertyId>({
      query: ({ propertyId }) => `/partner/movesta/valuation/latest?${qs.stringify({ propertyId })}`,
      transformResponse: (response: { valuation: ValuationSummary }) => response.valuation,
      providesTags: () => [HIDApiTags.VALUATION],
    }),
    getFinanceInformation: builder.query<FinanceInfo, PropertyId>({
      query: ({ propertyId }) => `/properties/${propertyId}/finance/information`,
      providesTags: () => [HIDApiTags.FINANCE_INFORMATION],
    }),
    getInsurances: builder.query<Array<InsuranceWithParams>, void>({
      query: () => 'v2/insurances',
      transformResponse: (response: { insurances: Array<InsuranceWithParams> }) => response.insurances,
      providesTags: () => [HIDApiTags.INSURANCES],
    }),
    patchPropertyFinanceInformation: builder.mutation<FinanceInfo, PatchPropertyInformationRequest>({
      query: ({ propertyId, financeInformation }) => ({
        url: `/properties/${propertyId}/finance/information`,
        method: 'PATCH',
        body: financeInformation,
      }),
      invalidatesTags: [
        HIDApiTags.PROPERTY_FINANCE,
        HIDApiTags.PROPERTY_PROGRESS,
        HIDApiTags.FINANCE_INFORMATION,
      ],
    }),
  }),
});

export const {
  useGetLoansSummaryQuery,
  useGetMovestaValuationQuery,
  useGetFinanceInformationQuery,
  useGetInsurancesQuery,
  usePatchPropertyFinanceInformationMutation,
} = financeApi;
