import React, {
  FC,
  useMemo,
  useState,
} from 'react';
import { skipToken } from '@reduxjs/toolkit/query';
import { useTranslation } from 'react-i18next';
import * as R from 'ramda';
import { differenceInMonths } from 'date-fns';
import {
  Stack,
  Tab,
  useTheme,
} from '@mui/material';
import {
  TabContext,
  TabList,
} from '@mui/lab';
import {
  XAxis,
  CartesianGrid,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  AreaChart,
  Area,
  BarChart,
  Bar,
} from 'recharts';

import {
  useGetIndicationValuationHistoryQuery,
  useGetLatestIndicationValuationQuery,
} from '../../../api/api.valuation';
import useGetCurrentPropertyId from '../../../../../../../hooks/useGetCurrentPropertyId';
import { useGetFormatMonth } from '../../../../../../../../../utils/translation';
import { capitalize } from '../../../../../../../../../utils/url';
import { IndicativeValuationHistoryItem } from '../../../types.valuation';
import HIDTypography from '../../../../../../../../../components/HIDTypography';
import useBreakPointsSizes from '../../../../../../../../../hooks/useBreakpointsSizes';
import {
  formatPercentage,
  hidSpacing,
} from '../../../../../../../../../utils/number';
import {
  DateTimeFormats,
  formatDate,
} from '../../../../../../../../../utils/date';
import SummaryChartTooltip from '../../../../../../../../../components/charts/SummaryChartTooltip';

enum IndicationValuationFilterRange {
  ALL = 'all',
  HALF_YEAR = 'half_year',
  YEAR = 'year',
  TWO_YEARS = 'two_years',
}

const FilterRangeMap: Record<IndicationValuationFilterRange, number> = {
  [IndicationValuationFilterRange.ALL]: 0,
  [IndicationValuationFilterRange.HALF_YEAR]: 6,
  [IndicationValuationFilterRange.YEAR]: 12,
  [IndicationValuationFilterRange.TWO_YEARS]: 24,
};

const getStartOfMonthDate = (date: Date) => new Date(date.getFullYear(), date.getMonth(), 1);

const getHistoryForMonths = (
  historyValuation: Array<IndicativeValuationHistoryItem>,
  selectedMonthFilter: number,
): Array<IndicativeValuationHistoryItem> =>
  R.sortWith(
    [
      R.ascend(({ createdAt }) => createdAt),
    ],
    selectedMonthFilter
      ? historyValuation
        .filter(
          ({ createdAt }) =>
            selectedMonthFilter
              ? differenceInMonths(new Date(), getStartOfMonthDate(new Date(createdAt))) < Number(selectedMonthFilter)
              : true,
        )
      : historyValuation,
  );

const IndicationValuationHistoryChart: FC = () => {
  const theme = useTheme();
  const { t } = useTranslation(['finances']);

  const { data: propertyId } = useGetCurrentPropertyId();

  const {
    isDownXl,
    isDownLg,
    isDownMd,
    isDownSm,
    isDownXs,
  } = useBreakPointsSizes();

  const {
    data: historyValuation,
  } = useGetIndicationValuationHistoryQuery(propertyId ? { propertyId } : skipToken);

  const {
    data: indicativeValuation,
  } = useGetLatestIndicationValuationQuery(propertyId ? { propertyId } : skipToken);

  const [selectedMonthFilter, setSelectedMonthFilter] = useState(IndicationValuationFilterRange.ALL);

  const formatMonth = useGetFormatMonth({ short: isDownSm });

  const tabs = useMemo(
    () => {
      const purchasePriceChangePercent = indicativeValuation?.purchasePriceChange?.percent;

      const valuationChangePercentFor24Month = indicativeValuation
        ?.performance[FilterRangeMap[IndicationValuationFilterRange.TWO_YEARS]]
        ?.percent;

      const valuationChangePercentFor12Month = indicativeValuation
        ?.performance[FilterRangeMap[IndicationValuationFilterRange.YEAR]]
        ?.percent;

      const valuationChangePercentFor6Month = indicativeValuation
        ?.performance[FilterRangeMap[IndicationValuationFilterRange.HALF_YEAR]]
        ?.percent;

      return [
        purchasePriceChangePercent && {
          id: IndicationValuationFilterRange.ALL,
          title: capitalize(t('finances:latest_valuation_finance_info_text')),
          purchasePriceChangePercent,
        },
        valuationChangePercentFor24Month && {
          id: IndicationValuationFilterRange.TWO_YEARS,
          title: formatMonth(24),
          purchasePriceChangePercent: valuationChangePercentFor24Month,
        },
        valuationChangePercentFor12Month && {
          id: IndicationValuationFilterRange.YEAR,
          title: formatMonth(12),
          purchasePriceChangePercent: valuationChangePercentFor12Month,
        },
        valuationChangePercentFor6Month && {
          id: IndicationValuationFilterRange.HALF_YEAR,
          title: formatMonth(6),
          purchasePriceChangePercent: valuationChangePercentFor6Month,
        },
      ].filter(Boolean);
    },
    [indicativeValuation, isDownSm],
  );

  const historyValuationPerPeriod = useMemo(
    () => getHistoryForMonths(historyValuation || [], FilterRangeMap[selectedMonthFilter])
      .map(({ valuation, createdAt }) => ({
        valuation: valuation.expectation,
        date: formatDate(new Date(createdAt), DateTimeFormats.MONTH_AND_YEAR),
      })),
    [historyValuation, selectedMonthFilter],
  );

  const xAxisInterval = Math.floor(
    (historyValuationPerPeriod?.length || 1) / (
      R.cond([
        [() => isDownXs, R.always(4)],
        [() => isDownSm, R.always(5)],
        [() => isDownMd, R.always(6)],
        [() => isDownLg, R.always(5)],
        [() => isDownXl, R.always(6)],
        [R.T, R.always(8)],
      ])()
    ),
  );

  const Chart = historyValuationPerPeriod.length > 4
    ? AreaChart
    : BarChart;

  return (
    <Stack style={{ marginRight: hidSpacing(-1.5) }}>
      <ResponsiveContainer
        height="100%"
        minHeight={200}
        style={{ overflow: 'visible' }}
        width="100%"
      >
        <Chart
          data={historyValuationPerPeriod}
          margin={{ right: hidSpacing(2), top: hidSpacing(1.5) }}
          maxBarSize={80}
        >
          <defs>
            <linearGradient
              id="colorValuation"
              x1="0"
              x2="0"
              y1="0"
              y2="1"
            >
              <stop offset="0%" stopColor={theme.palette.primary.lighter} stopOpacity={0.85} />
              <stop offset="100%" stopColor={theme.palette.primary.lightest} stopOpacity={0} />
            </linearGradient>
          </defs>
          <CartesianGrid strokeDasharray="3 3" />
          <Tooltip content={<SummaryChartTooltip />} />
          <XAxis
            dataKey="date"
            interval={xAxisInterval}
          />
          <YAxis dataKey="valuation" tickFormatter={(value: number) => `${value / 1000000} M`} />
          <Area
            dataKey="valuation"
            fill="url(#colorValuation)"
            fillOpacity={1}
            stroke={theme.palette.primary.main}
            strokeWidth={2}
            type="monotone"
          />
          <Bar
            dataKey="valuation"
            fill={theme.palette.primary.main}
            isAnimationActive={false}
          />
        </Chart>
      </ResponsiveContainer>
      {tabs.length > 1 && (
        <TabContext value={selectedMonthFilter}>
          <TabList onChange={(_, tab: IndicationValuationFilterRange) => setSelectedMonthFilter(tab)}>
            {tabs.map((tab) => (
              <Tab
                icon={
                  <HIDTypography
                    sx={{
                      color: tab.id === selectedMonthFilter
                        ? theme.palette.common.black
                        : theme.palette.grey[500],
                    }}
                  >
                    {tab.title}
                  </HIDTypography>
                }
                key={tab.id}
                label={
                  <HIDTypography
                    sx={{
                      color: tab.purchasePriceChangePercent < 0
                        ? theme.palette.error.main
                        : theme.palette.primary.main,
                    }}
                    variant="subtitle1"
                  >
                    {
                      formatPercentage(
                        Math.trunc(tab.purchasePriceChangePercent) / 100,
                        { withPrefixSign: true, wholeNumber: true },
                      )
                    }
                  </HIDTypography>
                }
                sx={{ flex: 1 }}
                value={tab.id}
              />
            ))}
          </TabList>
        </TabContext>
      )}
    </Stack>
  );
};

export default IndicationValuationHistoryChart;
