import React, { FC } from 'react';
import { Stack } from '@mui/material';

import {
  DynamicContainerInputStyle,
  DynamicContentListInput,
  DynamicContentListItem as DynamicContentListItemProps,
} from '../../../types/types.dynamicContent';
import DynamicContentSource from './DynamicContentSource';
import HIDClickable from '../../../../../../../../../components/HIDClickable';
import useNavigateFromLink from '../../../../../../../hooks/useNavigateFromLink';

type DynamicContentListProps = DynamicContentListInput & DynamicContainerInputStyle;

const DynamicContentListItem: FC<DynamicContentListItemProps & DynamicContainerInputStyle> = ({
  label,
  link,
}) => {
  const handleClick = useNavigateFromLink(link);

  return (
    <HIDClickable
      key={label}
      onClick={handleClick}
    >
      {label}
    </HIDClickable>
  );
};

const DynamicContentList: FC<DynamicContentListProps> = ({
  source,
  value,
}) => {
  const items = value;

  return (
    <Stack spacing={1}>
      {items.map((item: DynamicContentListItemProps, index: number) => (
        <DynamicContentListItem key={index} {...item} />
      ))}
      <DynamicContentSource source={source} />
    </Stack>
  );
};
export default DynamicContentList;
