import React from 'react';

import { Icon } from '../../types/common';
import HIDSvgIcon from './HIDSvgIcon';

const SFLogo: Icon = () => (
  <HIDSvgIcon height={32} viewBox="0 0 35 32" width={35}>
    <path d="M1 8C1 7.5 1.5 6 2 5C4 2 7 0 10 0C10 7 10 14 10 20C9 20 8 19 6 18C3 16 1 14 1 11C1 10 1 9 1 8Z" fill="#202020" />
    <path d="M1 22C4 22 7 22 10 22C10 25 10 29 10 32C9 32 7 31 5 30C3 29 1 27 1 25C1 23 1 22 1 22Z" fill="#202020" />
    <path d="M30 0H35C35 1 34 3 33 5C32 7 30 9 28 9C25 10 20 10 15 10C15 9 15 8 15 7C15 5 16 3 18 2C20 1 23 0 30 0Z" fill="#202020" />
    <path d="M21 27C19 30 16 32 13 32C13 25 13 19 13 12C14 12 16 13 18 14C21 16 22 19 22 22C22 24 21 26 21 27Z" fill="#202020" />
    <path d="M31 16C30 18 28 19 26 20C25 20 24 20 24 12C27 12 30 12 33 12C33 14 32 16 31 16Z" fill="#202020" />
  </HIDSvgIcon>
);

export default SFLogo;
