import React, { useState } from 'react';
import {
  Card,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { useNavigate } from 'react-router';
import {
  GridColDef,
  GridEventListener,
  GridRenderCellParams,
  GridRowSelectionModel,
} from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import { skipToken } from '@reduxjs/toolkit/query';
import { Product } from '@house-id/houseid-types/dist/content/product';
import { EntityType } from '@house-id/houseid-types/dist/entityType';

import HomeListLayout from '../../../../../../pages/Home/components/HomeListLayout';
import useBreakPointsSizes from '../../../../../../../../hooks/useBreakpointsSizes';
import {
  formatDate,
  DateTimeFormats,
} from '../../../../../../../../utils/date';
import {
  EMPTY_VALUE,
  formatMoney,
} from '../../../../../../../../utils/string';
import useGetCurrentPropertyId from '../../../../../../hooks/useGetCurrentPropertyId';
import ContentImage from '../../../../components/ContentImage';
import {
  useGetAllProductsQuery,
  useDeleteProductsMutation,
  useGetProductsSummaryQuery,
} from '../../api/api.product';
import { LIST_CHEVRON_COLUMN_CONFIG } from '../../../../../../../../constants/columns';
import ListEntitiesToolbarActions, { SelectionModeType } from '../../../../components/actions/ListEntitiesToolbarActions';
import ListEntitiesActions from '../../../../components/actions/ListEntitiesActions';
import { useNavigateBackOr } from '../../../../../../../../utils/routes';
import FiltersSection from '../../../../components/sections/FiltersSection';
import { getHomePath } from '../../../../../../navigation/navigation.property';
import { getProductPath } from '../../navigation.product';
import {
  getFirstExternalImageUrl,
  getProductsSummaryChartData,
} from '../../utils.products';
import ProductsSummaryChart from './components/ProductsSummaryChart';
import {
  ContentCustomFilterType,
  ContentFilters,
  TimeIntervalsGroupingType,
} from '../../../../types/types.content';
import useDialog from '../../../../../../../../hooks/useDialog';
import DialogNames from '../../../../../../../../hooks/useDialog/DialogNames';
import SuggestionsIconButton from '../../../Suggestion/components/SuggestionsIconButton';
import { getPathWithPropertyIdOrInit } from '../../../../../../../Auth/navigation/navigation.auth';
import useSearch from '../../../../../../../../hooks/useSearch';
import { hidSpacing } from '../../../../../../../../utils/number';
import { ProductState } from '../../types.product';

type UseGetProductsDataGridColumns = (props: {
  isSelectionMode: boolean;
  brandNameVisible?: boolean;
  purchaseDateVisible?: boolean;
  purchasePlaceVisible?: boolean;
}) => Array<GridColDef>;

export const useGetProductsDataGridColumns: UseGetProductsDataGridColumns = ({
  isSelectionMode,
  brandNameVisible,
  purchaseDateVisible,
  purchasePlaceVisible,
}) => {
  const { t } = useTranslation(['common', 'products']);
  const theme = useTheme();
  const { isDownXl, isDownMd } = useBreakPointsSizes();

  const columns = [
    {
      field: 'name',
      headerName: t('common:name'),
      flex: isDownMd ? 0.6 : 0.4,
      type: 'string',
      sortable: true,
      renderCell: (params: GridRenderCellParams) => {
        const { name, externalMedia, blobs } = params?.row as Product;

        return (
          <Stack
            alignItems="center"
            direction="row"
            sx={{ minWidth: 0 }}
          >
            <ContentImage
              blobs={blobs}
              externalImage={getFirstExternalImageUrl(externalMedia)}
              imageSx={{ maxHeight: theme.spacing(8) }}
              sx={{ marginRight: theme.spacing(1.5) }}
            />
            <Stack
              direction="column"
              justifyContent="center"
              sx={{ minWidth: 0 }}
            >
              <Stack direction="row" sx={{ minWidth: 0 }}>
                <Typography noWrap variant="subtitle1">
                  {name}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        );
      },
    },
    !isDownXl && brandNameVisible && {
      field: 'brandName',
      headerName: t('products:brand'),
      flex: 0.15,
      type: 'string',
      sortable: true,
      renderCell: (params: GridRenderCellParams) => {
        const { brand } = params?.row as Product;
        return (
          <Typography noWrap sx={{ color: theme.palette.grey[500] }} variant="body2">
            {brand || EMPTY_VALUE}
          </Typography>
        );
      },
    },
    !isDownMd && purchaseDateVisible && {
      field: 'purchaseDate',
      headerName: t('products:purchase_date'),
      flex: 0.15,
      type: 'string',
      sortable: true,
      renderCell: (params: GridRenderCellParams) => {
        const { purchaseDate } = params?.row as Product;
        return (
          <Typography noWrap sx={{ color: theme.palette.grey[500] }} variant="body2">
            {purchaseDate ? formatDate(new Date(purchaseDate), DateTimeFormats.DATE_ONLY) || EMPTY_VALUE : EMPTY_VALUE}
          </Typography>
        );
      },
    },
    !isDownXl && purchasePlaceVisible && {
      field: 'purchasePlace',
      headerName: t('products:purchase_place'),
      flex: 0.15,
      type: 'string',
      sortable: true,
      renderCell: (params: GridRenderCellParams) => {
        const { place } = params?.row as Product;
        return (
          <Typography noWrap sx={{ color: theme.palette.grey[500] }} variant="body2">
            {place || EMPTY_VALUE}
          </Typography>
        );
      },
    },
    {
      field: 'price',
      headerName: t('products:product_price'),
      flex: isDownMd ? 0.4 : 0.15,
      type: 'string',
      sortable: true,
      renderCell: (params: GridRenderCellParams) => {
        const { price } = params?.row as Product;
        return (
          <Typography noWrap sx={{ color: theme.palette.grey[500] }} variant="body2">
            {price ? formatMoney(price) : EMPTY_VALUE}
          </Typography>
        );
      },
    },
    !isSelectionMode && LIST_CHEVRON_COLUMN_CONFIG,
  ].filter(Boolean);

  return columns;
};

const Products = () => {
  const { t } = useTranslation(['products']);
  const navigate = useNavigate();
  const navigateBackOr = useNavigateBackOr();

  const { data: propertyId } = useGetCurrentPropertyId();
  const [selectionModeType, setSelectionModeType] = useState<SelectionModeType | undefined>();
  const [selectedProductIds, setSelectedProductIds] = useState<Array<string>>([]);

  const { displaySearch } = useSearch(EntityType.PRODUCT);

  const isSelectionMode = Boolean(selectionModeType);

  const [deleteProducts, { isLoading: isDeleting }] = useDeleteProductsMutation();

  const productStateOptions = Object.values(ProductState)
    .map((state) => ({ id: state, label: t(`products:products_${state}`) }));

  const [filters, setFilters] = useState<ContentFilters>({
    from: undefined,
    to: undefined,
    groupingType: TimeIntervalsGroupingType.Yearly,
    columnsVisibilityMap: {
      brandName: { label: t('products:brand'), visible: true },
      purchaseDate: { label: t('products:purchase_date'), visible: true },
      purchasePlace: { label: t('products:purchase_place'), visible: true },
    },
    customFilters: {
      state: {
        title: `${t('products:product_status')}:`,
        label: t('products:historical_products'),
        value: ProductState.ALL,
        type: ContentCustomFilterType.SWITCH,
        options: productStateOptions,
      },
    },
  });

  const brandNameVisible = Boolean(filters.columnsVisibilityMap?.brandName?.visible);
  const purchaseDateVisible = Boolean(filters.columnsVisibilityMap?.purchaseDate?.visible);
  const purchasePlaceVisible = Boolean(filters.columnsVisibilityMap?.purchasePlace?.visible);

  const productState = filters.customFilters?.state?.value as ProductState | undefined;

  const {
    data: { products = [] } = {},
    isLoading: isLoadingAllProducts,
  } = useGetAllProductsQuery(propertyId ? { propertyId, state: productState } : skipToken);

  const {
    data: productsSummary,
    isLoading: isLoadingSummary,
  } = useGetProductsSummaryQuery(
    propertyId
      ? {
        propertyId,
        from: filters.from,
        to: filters.to,
        groupingType: filters.groupingType,
        state: productState,
      }
      : skipToken,
  );

  const columns = useGetProductsDataGridColumns({
    isSelectionMode,
    brandNameVisible,
    purchaseDateVisible,
    purchasePlaceVisible,
  });

  const handleRowClick: GridEventListener<'rowClick'> = (params) => {
    if (isSelectionMode) {
      return;
    }

    const product = params?.row as Product;
    navigate(getPathWithPropertyIdOrInit(getProductPath, { propertyId, id: product.id }));
  };

  const handleRowSelectionModelChange = (rowSelectionModel: GridRowSelectionModel) =>
    setSelectedProductIds(rowSelectionModel as Array<string>);

  const [openSearchProductDialog] = useDialog(DialogNames.SEARCH_PRODUCTS_DIALOG);

  const handleAdd = openSearchProductDialog;

  const handleEnterDeleteMode = () => {
    setSelectionModeType(SelectionModeType.DELETE);
    setSelectedProductIds([]);
  };

  const handleDelete = () => {
    if (propertyId) {
      deleteProducts({ propertyId, ids: selectedProductIds });
    }

    setSelectionModeType(undefined);
    setSelectedProductIds([]);
  };

  const isLoading = isLoadingAllProducts || isLoadingSummary;

  return (
    <HomeListLayout
      DataGridToolbar={
        <ListEntitiesToolbarActions
          count={products.length}
          entity={EntityType.PRODUCT}
          isFetching={isDeleting}
          isLoading={isLoading}
          selectedCount={selectedProductIds.length}
          selectionModeType={selectionModeType}
          onCancel={() => setSelectionModeType(undefined)}
          onDelete={handleDelete}
        />
      }
      ListHeaderComponent={
        !isSelectionMode
          ? (
            <ProductsSummaryChart
              data={getProductsSummaryChartData(productsSummary || [], filters.groupingType)}
              groupingType={filters.groupingType}
              isLoading={isLoadingSummary}
              style={{ marginBottom: hidSpacing(2) }}
            />
          )
          : undefined
      }
      SideColumn={
        <>
          <Card sx={{ padding: 2 }}>
            <ListEntitiesActions
              disabled={isSelectionMode}
              onAdd={handleAdd}
              onDelete={handleEnterDeleteMode}
              onSearch={displaySearch}
            />
          </Card>
          {!isSelectionMode && (
            <Card sx={{ padding: 2 }}>
              <FiltersSection
                showChartGroupingTypeFilers
                showColumnsVisibilityFilters
                showCustomFilers
                showDateFilers
                filters={filters}
                onChange={setFilters}
              />
            </Card>
          )}
        </>
      }
      TitleRightComponent={<SuggestionsIconButton entityType={EntityType.PRODUCT} />}
      columns={columns}
      initialState={{
        sorting: {
          sortModel: [
            {
              field: 'purchaseDate',
              sort: 'desc',
            },
          ],
        },
      }}
      isLoading={isLoadingAllProducts}
      isSelectionMode={isSelectionMode}
      rows={products}
      sideDrawerElements={[
        <ListEntitiesActions
          disabled={isSelectionMode}
          key={ListEntitiesActions.name}
          onAdd={handleAdd}
          onDelete={handleEnterDeleteMode}
          onSearch={displaySearch}
        />,
        !isSelectionMode && (
          <FiltersSection
            showChartGroupingTypeFilers
            showColumnsVisibilityFilters
            showDateFilers
            filters={filters}
            key={FiltersSection.name}
            onChange={setFilters}
          />
        ),
      ].filter(Boolean)}
      title={t('products:products_title')}
      onBack={() => navigateBackOr(getPathWithPropertyIdOrInit(getHomePath, { propertyId }))}
      onRowClick={handleRowClick}
      onRowSelectionModelChange={handleRowSelectionModelChange}
    />
  );
};

export default Products;
