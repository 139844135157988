import {
  AuthError,
} from 'firebase/auth';
import { useTranslation } from 'react-i18next';

type UseGetAuthErrorMessage = (error?: AuthError) => string | undefined;

const useGetAuthErrorMessage: UseGetAuthErrorMessage = (error?: AuthError) => {
  const { t } = useTranslation('auth_error_messages');

  return error
    // @ts-ignore
    ? t(`auth_error_messages:${error.code}` as keyof ReturnType<typeof t>)
    : undefined;
};

export default useGetAuthErrorMessage;
