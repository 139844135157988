import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { RecurringExpenseCollectionType } from '@house-id/houseid-types/dist/finances/recurringExpenses';
import {
  Stack,
  useTheme,
} from '@mui/material';
import { addMonths } from 'date-fns';
import * as R from 'ramda';

import { Wallet } from '../../../../../../../components/icons/Icons';
import FinancesEmptyStateCard from '../../../components/FinancesEmptyStateCard';
import useStartRecurringExpenseCollection from '../hooks/useStartRecurringExpenseCollection';
import FinancesChart from '../../../components/FinancesChart';
import { range } from '../../../../../../../utils/array';

const now = new Date();

const primaryValues = range(0, 5).map((index) => 5000 + ((index % 5) * 300 - 200));

const totalValue = R.sum(primaryValues);
const average = totalValue / primaryValues.length;

const chartData = primaryValues
  .map((value, index) => {
    const date = addMonths(now, -index);
    return {
      primary: value,
      line: average,
      date: { year: date.getFullYear(), month: date.getMonth() + 1 },
    };
  });

const RecurrentExpensesEmptyStateCard: FC = () => {
  const theme = useTheme();
  const { t } = useTranslation(['finances']);

  const collectionKey = 'EMPTY_STATE';

  const openAddRecurringExpensesCollectionDialog = useStartRecurringExpenseCollection({
    recurringExpenseCollectionType: RecurringExpenseCollectionType.ADD,
    collectionKey,
  });

  return (
    <FinancesEmptyStateCard
      Icon={Wallet}
      addTitle={t('finances:get_costs')}
      color={theme.palette.primary}
      subTitle={t('finances:add_fixed_expenses_to_get_an_overview')}
      title={t('finances:what_do_your_fixed_expenses_look_like')}
      onAdd={openAddRecurringExpensesCollectionDialog}
    >
      <Stack
        justifyContent="stretch"
        maxWidth={600}
        width="100%"
      >
        <FinancesChart
          bars={[
            {
              dataKey: 'primary',
              color: theme.palette.primary.main,
              name: '2024',
            },
          ].filter(Boolean)}
          chartData={chartData}
          lineData={{ dataKey: 'line' }}
          minHeight={200}
          showGrid={false}
          showLegend={false}
          showTooltip={false}
          showXAxis={false}
          showYAxis={false}
          sx={{
            padding: 0,
            border: 'none',
          }}
        />
      </Stack>
    </FinancesEmptyStateCard>
  );
};

export default RecurrentExpensesEmptyStateCard;
