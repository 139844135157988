import React from 'react';
import {
  ButtonGroup,
  List,
  ListItemButton,
  ListItemText,
  useTheme,
} from '@mui/material';
import * as R from 'ramda';
import {
  CheckBox,
  CheckBoxOutlineBlank,
} from '@mui/icons-material';

import { FCC } from '../../../../../../types/common';
import useBreakPointsSizes from '../../../../../../hooks/useBreakpointsSizes';
import HIDButton from '../../../../../../components/buttons/HIDButton';
import {
  ContentCustomFilter,
  ContentCustomFilterType,
} from '../../types/types.content';
import HIDTypography from '../../../../../../components/HIDTypography';

type CustomFilterSectionProps = {
  customFilters: ContentCustomFilter;
  onChange: (customFilters: ContentCustomFilter) => void;
};

const CustomFilterSection: FCC<CustomFilterSectionProps> = ({
  customFilters,
  sx,
  onChange,
}) => {
  const theme = useTheme();

  const customFiltersVisibilityList = R.toPairs(customFilters);

  const { isDownXl } = useBreakPointsSizes();

  const handleChangeCustomFilters = (id: string, value: boolean | string) => onChange(
    {
      ...customFilters,
      [id]: {
        ...customFilters[id],
        value,
      },
    },
  );

  return (
    <List sx={sx}>
      {
        customFiltersVisibilityList
          .map(
            ([id, {
              title,
              label,
              type,
              value,
              options,
            }]) => (
              <>
                {
                  title && (
                    <HIDTypography sx={{ marginBottom: 1 }} variant="subtitle1">
                      {title}
                    </HIDTypography>
                  )
                }
                {
                  type === ContentCustomFilterType.FLAG
                    ? (
                      <ListItemButton
                        key={id}
                        onClick={() => handleChangeCustomFilters(id, !value)}
                      >
                        <ListItemText primary={label} />
                        {
                          value
                            ? <CheckBox sx={{ color: theme.palette.primary.main }} />
                            : <CheckBoxOutlineBlank />
                        }
                      </ListItemButton>
                    )
                    : type === ContentCustomFilterType.SWITCH
                      ? (
                        <ButtonGroup size={isDownXl ? 'small' : 'medium'} variant="outlined">
                          {
                            options?.map((option) => (
                              <HIDButton
                                color={option.id === value ? 'primary' : 'secondary'}
                                key={option.id}
                                sx={isDownXl ? undefined : { paddingX: 2.5 }}
                                onClick={() => handleChangeCustomFilters(id, option.id)}
                              >
                                {option.label}
                              </HIDButton>
                            ))
                          }
                        </ButtonGroup>
                      )
                      : null
                }
              </>
            ),
          )
      }
    </List>
  );
};

export default CustomFilterSection;
