export const roundPercentage = (number: number, digitsAfterComa = 2) => Math.round(number * (10 ** digitsAfterComa))
  / (10 ** digitsAfterComa);

export const hidSpacing = (number: number) => number * 8;

// eslint-disable-next-line no-bitwise
export const isOdd = (number: number) => Boolean(number & 1);

export const remToPixels = (rem: number) => rem * 16;

export const removeFollowingZeros = (formattedNumber: string) => formattedNumber.replace(/(\.0+|0+)$/, '');

export const formatNumber = (n: number, precision = 0, removeZeros = false): string => {
  const formatted = n.toFixed(precision);
  return removeZeros
    ? removeFollowingZeros(formatted)
    : formatted;
};

export const getNumberSignPrefix = (value: number) => value >= 0 ? '+' : '-';

type FormatPercentageOptions = {
  wholeNumber?: boolean;
  withPrefixSign?: boolean;
};

export const formatPercentage = (percentage = 0, options?: FormatPercentageOptions) => {
  const numberValue = (formatNumber(Number(percentage || 0) * 100, options?.wholeNumber ? 0 : 2, false))
    .replace('.', ',');

  const prefix = options?.withPrefixSign && percentage >= 0 ? '+' : '';
  return `${prefix}${numberValue}%`;
};

export const truncatePercentage = (percentage: number) => Math.trunc(percentage * 100) / 100;
