import React, { FC } from 'react';
import {
  Card,
  Stack,
  useTheme,
} from '@mui/material';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import * as R from 'ramda';
import {
  Search,
  Sync,
} from '@mui/icons-material';
import { enqueueSnackbar } from 'notistack';
import { useEffectOnce } from 'usehooks-ts';
import {
  HIDBlob,
  HIDEntityId,
  ModifyActionType,
} from '@house-id/houseid-types/dist/common';
import {
  ExternalMediaType,
  Product,
} from '@house-id/houseid-types/dist/content/product';
import { EntityType } from '@house-id/houseid-types/dist/entityType';

import HomeLayout from '../../../../../../pages/Home/components/HomeLayout';
import {
  useDeleteExternalMedialMutation,
  useDeleteProductsMutation,
  useGetProductWithCache,
  useUpdateProductMutation,
} from '../../api/api.product';
import {
  useNavigateBackOr,
  useRouteParams,
} from '../../../../../../../../utils/routes';
import useGetCurrentPropertyId from '../../../../../../hooks/useGetCurrentPropertyId';
import {
  getProductsPath,
  getUpdateProductPath,
} from '../../navigation.product';
import ViewEntityActions from '../../../../components/actions/ViewEntityActions';
import ContentFileViewer from '../../../../components/ContentFileViewer';
import ProductBasicInfoSection from './components/ProductBasicInfoSection';
import { ContentFile } from '../../../ContentFile/types.contentFile';
import ProductSpecificationSection from './components/ProductSpecificationSection';
import AnnotationSection from '../../../../components/sections/AnnotationSection';
import ProductDescriptionSection from './components/ProductDescriptionSection';
import { ImageMimeTypes } from '../../../../../../../../constants/mimeTypes';
import PrisjaktSection from './components/PrisjaktSection';
import {
  useCreateBlobFromExternalMediaMutation,
  useCreateContentFileFromExternalMediaMutation,
} from '../../../ContentFile/api/api.contentFile';
import {
  getIdentifierValue,
  hasExternalMedia,
  useGetNavigateAfterExternalMediaCreate,
} from '../../utils.products';
import {
  ProductIdentifier,
  ProductInitialData,
} from '../../types.product';
import ContentConnectionsTabContext from '../../../../components/ContentConnectionsTabContext';
import { OVERVIEW_TAB_ID } from '../../../../constants/constants.content';
import useDialog from '../../../../../../../../hooks/useDialog';
import DialogNames from '../../../../../../../../hooks/useDialog/DialogNames';
import ContentConnections from '../../../../components/ContentConnections';
import useBreakPointsSizes from '../../../../../../../../hooks/useBreakpointsSizes';
import ExternalMediaText from './components/ExternalMediaText';
import { getMutationFixedCacheKey } from '../../../../utils/cacheKeys';
import EntityDetailsLoaderSkeleton from '../../../../../../../../components/skeletonLoaders/EntityDetailsLoaderSkeleton';
import { getPathWithPropertyIdOrInit } from '../../../../../../../Auth/navigation/navigation.auth';
import useGetPropertyPermissions from '../../../../../../hooks/useGetPropertyPermissions';

const ViewProducts: FC = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const navigateBackOr = useNavigateBackOr();
  const { isDownMd } = useBreakPointsSizes();

  const { t } = useTranslation(['common', 'products']);

  const { id: productId } = useRouteParams<HIDEntityId>();

  const { data: propertyId } = useGetCurrentPropertyId();

  const { data: { canUpdate } = {} } = useGetPropertyPermissions();

  const [updateProduct, { isLoading: isUpdatingProduct }] = useUpdateProductMutation();
  const [deleteProducts] = useDeleteProductsMutation({
    fixedCacheKey: getMutationFixedCacheKey(productId),
  });

  const {
    product,
    isLoading: isLoadingProduct,
    isUninitialized: isProductNotStartedLoading,
    refetch: refetchProduct,
  } = useGetProductWithCache({ propertyId, productId });

  useEffectOnce(() => {
    // TODO: find better solution
    if (!isLoadingProduct && !isProductNotStartedLoading) {
      refetchProduct();
    }
  });

  const [openSearchProductDialog] = useDialog(DialogNames.SEARCH_PRODUCTS_DIALOG);

  const handleAdd = openSearchProductDialog;

  const handleUpdate = () => navigate(getPathWithPropertyIdOrInit(getUpdateProductPath, { propertyId, id: productId }));
  const [openManageConnectionsDialog] = useDialog(DialogNames.MANAGE_CONTENT_CONNECTIONS_DIALOG);
  const handleManageConnections = () => {
    if (product) {
      openManageConnectionsDialog({
        entityType: EntityType.PRODUCT,
        entity: product,
      });
    }
  };

  const handleDelete = () => {
    if (propertyId) {
      deleteProducts({ propertyId, ids: [productId] })
        .then(() => navigateBackOr(getProductsPath({ propertyId }), { replace: true }));
    }
  };

  const handleContentFilesSelected = (contentFiles: Array<ContentFile>) => {
    const newBlobs = R.pluck('blob', contentFiles);
    if (propertyId && productId) {
      updateProduct({
        id: productId,
        propertyId,
        blobs: newBlobs.map((blob) => ({ ...blob, action: ModifyActionType.CREATE })),
      });
    }
  };

  const handleDeleteBlob = (blobId: string) => {
    const blobToDelete = product?.blobs?.find((blob) => blob.id === blobId);

    if (blobToDelete && propertyId) {
      updateProduct({
        id: productId,
        propertyId,
        blobs: [{ ...blobToDelete, action: ModifyActionType.DELETE }],
      });
    }
  };

  const handleAddBlob = (blob: HIDBlob) => {
    if (propertyId) {
      updateProduct({
        id: productId,
        propertyId,
        blobs: [{ ...blob, action: ModifyActionType.CREATE }],
      });
    }
  };

  const [deleteExternalMedia, { isLoading: externalMediaIsDeleting }] = useDeleteExternalMedialMutation();

  const [createBlobFromExternalMediaMutation, { isLoading: externalImageIsMoving }] = useCreateBlobFromExternalMediaMutation();
  const [createContentFileFromExternalMedia, { isLoading: externalMediaIsMoving }] = useCreateContentFileFromExternalMediaMutation();

  const navigateAfterExternalMediaCreate = useGetNavigateAfterExternalMediaCreate(propertyId, productId);

  const handleDeleteExternalMedia = (url: string) => {
    if (propertyId && productId) {
      deleteExternalMedia({
        id: productId,
        propertyId,
        url,
      })
        .then(() => refetchProduct());
    }
  };

  const handleMoveExternalMedia = (url: string, mediaType: ExternalMediaType) => {
    if (productId && product && propertyId) {
      if (mediaType === ExternalMediaType.IMAGE) {
        createBlobFromExternalMediaMutation({ propertyId, url })
          .unwrap()
          .then((blob) => handleAddBlob(blob))
          .then(() => refetchProduct());
      } else if (mediaType === ExternalMediaType.DOCUMENT) {
        createContentFileFromExternalMedia({ propertyId, url })
          .unwrap()
          .then((contentFile) => navigateAfterExternalMediaCreate({ mediaType, url, contentFileId: contentFile.id }));
      } else {
        navigateAfterExternalMediaCreate({ mediaType, url, name: product.name });
      }
    }
  };

  const [openSearchDialog] = useDialog(DialogNames.SEARCH_PRODUCTS_DIALOG);

  const showExternalMediaUpdateSnack = (product: Product | undefined, updatedProduct: Product) => {
    if (hasExternalMedia(updatedProduct)) {
      enqueueSnackbar(t('products:product_information_updated'), {
        description: <ExternalMediaText product={product} updatedProduct={updatedProduct} />,
        variant: 'success',
      });
    } else {
      enqueueSnackbar(t('products:no_search_results'), {
        description: t('products:could_not_match_result_from_search'),
        variant: 'warning',
      });
    }
  };

  const handleAddExternalMedia = (initialProduct: ProductInitialData) => {
    if (propertyId) {
      updateProduct({
        propertyId,
        id: productId,
        externals: initialProduct.externals,
      })
        .unwrap()
        .then((updatedProduct) => showExternalMediaUpdateSnack(product, updatedProduct));
    }
  };

  const handleSearchForExternalMedia = () => openSearchDialog({
    searchForMatch: true,
    showAddManual: false,
    initialSearch: product
      ? getIdentifierValue(ProductIdentifier.GTIN, product)
      : '',
    onSelect: handleAddExternalMedia,
  });

  const handleSync = () => {
    if (propertyId && product && product.externals && product.externals.length > 0) {
      updateProduct({
        propertyId,
        id: productId,
        externals: product.externals,
      })
        .unwrap()
        .then((updatedProduct) => showExternalMediaUpdateSnack(product, updatedProduct));
    }
  };

  const customActions = [
    product && !hasExternalMedia(product) && {
      id: 'search',
      label: t('products:search_and_sync'),
      Icon: Search,
      onClick: handleSearchForExternalMedia,
    },
    Boolean(product && product.externals && product.externals.length > 0) && canUpdate && {
      id: 'sync',
      label: t('products:sync_product_information'),
      Icon: Sync,
      onClick: handleSync,
    },
  ].filter(Boolean);

  const contentFilesUpdating = isUpdatingProduct
    || externalMediaIsDeleting
    || externalImageIsMoving
    || externalMediaIsMoving;

  const productBlobs = product?.blobs || [];

  return (
    <HomeLayout
      BodyLoaderSkeleton={EntityDetailsLoaderSkeleton}
      SideColumn={
        <>
          <Card sx={{ padding: 2 }}>
            <ViewEntityActions
              customActions={customActions}
              onAdd={handleAdd}
              onDelete={handleDelete}
              onManageConnections={handleManageConnections}
              onUpdate={handleUpdate}
            />
          </Card>
          <ContentFileViewer
            showFiles
            blobs={productBlobs}
            externalMedia={product?.externalMedia}
            isLoading={isLoadingProduct}
            isUpdating={contentFilesUpdating}
            mimeTypes={ImageMimeTypes}
            variant="side_column"
            onContentFilesSelected={handleContentFilesSelected}
            onDeleteBlob={handleDeleteBlob}
            onDeleteExternalMedia={handleDeleteExternalMedia}
            onMoveExternalMedia={handleMoveExternalMedia}
          />
          {product && !R.isEmpty(product.links) && (
            <Card sx={{ padding: 2 }}>
              <PrisjaktSection product={product} />
            </Card>
          )}
        </>
      }
      breadcrumbsLinks={[
        {
          link: getPathWithPropertyIdOrInit(getProductsPath, { propertyId }),
          name: t('products:products_title'),
        },
      ]}
      isLoading={isLoadingProduct}
      sideDrawerElements={[
        <ViewEntityActions
          customActions={customActions}
          key={ViewEntityActions.name}
          onAdd={handleAdd}
          onDelete={handleDelete}
          onManageConnections={handleManageConnections}
          onUpdate={handleUpdate}
        />,

        <ContentFileViewer
          showFiles
          blobs={productBlobs}
          externalMedia={product?.externalMedia}
          isLoading={isLoadingProduct}
          isUpdating={contentFilesUpdating}
          key={ContentFileViewer.name}
          mimeTypes={ImageMimeTypes}
          variant="side_drawer"
          onContentFilesSelected={handleContentFilesSelected}
          onDeleteBlob={handleDeleteBlob}
          onDeleteExternalMedia={handleDeleteExternalMedia}
          onMoveExternalMedia={handleMoveExternalMedia}
        />,

        product && !R.isEmpty(product.links) ? <PrisjaktSection product={product} /> : undefined,
      ].filter(Boolean)}
      title={product?.name}
      onBack={() => navigateBackOr(getPathWithPropertyIdOrInit(getProductsPath, { propertyId }), { replace: true })}
    >
      {product && (
        <ContentConnectionsTabContext
          sourceEntity={product}
          sourceEntityType={EntityType.PRODUCT}
          tabs={[
            {
              id: OVERVIEW_TAB_ID,
              label: t('common:overview'),
              TabComponent: (
                <Stack>
                  <ContentFileViewer
                    showImages
                    blobs={productBlobs}
                    externalMedia={product?.externalMedia}
                    isLoading={isLoadingProduct}
                    isUpdating={contentFilesUpdating}
                    mimeTypes={ImageMimeTypes}
                    showFiles={isDownMd}
                    variant="inline"
                    onContentFilesSelected={handleContentFilesSelected}
                    onDeleteBlob={handleDeleteBlob}
                    onDeleteExternalMedia={handleDeleteExternalMedia}
                    onMoveExternalMedia={handleMoveExternalMedia}
                  />
                  <Stack spacing={2} sx={{ marginTop: theme.spacing(2) }}>
                    <ProductBasicInfoSection product={product} />
                    <AnnotationSection entity={product} />
                    <ProductDescriptionSection product={product} />
                    {Boolean(product.specification?.length) && (
                      <ProductSpecificationSection specifications={product.specification} />
                    )}
                  </Stack>
                  <ContentConnections
                    entity={product}
                    entityType={EntityType.PRODUCT}
                  />
                </Stack>
              ),
            },
          ]}
        />
      )}
    </HomeLayout>
  );
};

export default ViewProducts;
