import { useNavigate } from 'react-router';
import { differenceInDays } from 'date-fns';
import {
  AnalyticsEvent,
  HIDEventCustomCategory,
} from '@house-id/houseid-types/dist/analytics';

import RouteNames from '../../../routes/RouteNames';
import { generateRoutePath } from '../../../utils/routes';
import {
  PropertyId,
  PropertyIdNavigation,
} from '../types/types.property';
import { SF_MOVE_SERVICE } from '../../../constants/links';
import useIsSFProperty from '../hooks/useIsSfProperty';
import useGetCurrentProperty from '../hooks/useCurrentProperty';
import useGetLogAnalyticsEvent from '../../Analytics/hooks/useGetLogAnalyticsEvent';
import useGetOpenUrlWithAnalytics from '../../Analytics/hooks/useGetOpenUrlWithAnalytics';
import { PartnersSource } from '@house-id/houseid-types/dist/partners';

export const getHomePath = ({ propertyId }: PropertyIdNavigation) => generateRoutePath(
  RouteNames.PROPERTY_HOME,
  { propertyId },
);

export const getPropertySettingsPath = ({ propertyId }: PropertyIdNavigation) => generateRoutePath(
  RouteNames.PROPERTY_SETTINGS,
  { propertyId },
);

type GetExternalServicesPath = PropertyId & { id?: string, suggestionKey?: string };
export const getExternalServicesPath = ({ propertyId, id, suggestionKey }: GetExternalServicesPath) => generateRoutePath(
  RouteNames.EXTERNAL_SERVICES,
  { propertyId },
  { id, suggestionKey },
);

export const getPropertyStoragePath = ({ propertyId }: PropertyIdNavigation) => generateRoutePath(
  RouteNames.PROPERTY_STORAGE,
  { propertyId },
);

export const getUserStoragePath = () => generateRoutePath(
  RouteNames.USER_STORAGE,
);

export const getMovePath = () => generateRoutePath(
  RouteNames.MOVE,
);

export const useNavigateToMove = () => {
  const navigate = useNavigate();
  const { data: property } = useGetCurrentProperty();
  const isSfProperty = useIsSFProperty();
  const logEvent = useGetLogAnalyticsEvent();
  const openUrlWithAnalytics = useGetOpenUrlWithAnalytics();

  const accessDateMoreThan30days = property?.accessDate
    ? differenceInDays(new Date(), new Date(property.accessDate)) > 30
    : false;

  return () => {
    if (isSfProperty) {
      if (accessDateMoreThan30days) {
        logEvent({
          event: AnalyticsEvent.OPEN_MOVING_SERVICES_LINK,
          source: PartnersSource.HOUSE_ID,
          hidCategory: HIDEventCustomCategory.MOVE,
        });
        navigate(getMovePath());
      } else {
        openUrlWithAnalytics({
          url: SF_MOVE_SERVICE,
          event: AnalyticsEvent.OPEN_MOVING_SERVICES_LINK,
          source: PartnersSource.SF,
          hidCategory: HIDEventCustomCategory.MOVE,
        });
      }
    } else {
      logEvent({
        event: AnalyticsEvent.OPEN_MOVING_SERVICES_LINK,
        source: PartnersSource.HOUSE_ID,
        hidCategory: HIDEventCustomCategory.MOVE,
      });
      navigate(getMovePath());
    }
  };
};
