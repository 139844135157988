import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import HIDSection from '../../../../../../../components/HIDSection';
import useBreakPointsSizes from '../../../../../../../hooks/useBreakpointsSizes';
import HIDButton from '../../../../../../../components/buttons/HIDButton';
import LatestValuationInfo from './LatestValuationInfo';
import useGetCurrentPropertyId from '../../../../../hooks/useGetCurrentPropertyId';
import { getValuationHistoryPath } from '../navigation.valuation';
import { getPathWithPropertyIdOrInit } from '../../../../../../Auth/navigation/navigation.auth';

const ValuationOverviewLatestValuation: FC<{ isLoading: boolean }> = ({ isLoading }) => {
  const navigate = useNavigate();
  const { t } = useTranslation(['finances']);

  const { data: propertyId } = useGetCurrentPropertyId();

  const { isDownSm } = useBreakPointsSizes();

  const handleNavigateToHistory = () => navigate(getPathWithPropertyIdOrInit(getValuationHistoryPath, { propertyId }));

  return (
    <HIDSection sx={{ gap: 3 }}>
      <LatestValuationInfo />
      <HIDButton
        loading={isLoading}
        sx={{ alignSelf: isDownSm ? 'center' : 'flex-end' }}
        onClick={handleNavigateToHistory}
      >
        {t('finances:show_valuation_history')}
      </HIDButton>
    </HIDSection>
  );
};

export default ValuationOverviewLatestValuation;
