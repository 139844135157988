import {
  Product,
  ProductExternals,
  ProductSourceLink,
} from '@house-id/houseid-types/dist/content/product';

import { IconResource } from '../../../../../../types/common';
import { DynamicInputField } from '../DynamicContent/types/types.dynamicContent';

export type ParseSpecificationItem = {
  name: string;
  value: string | undefined;
  isLink: boolean;
};

export type ParsedSpecificationCategory = {
  name: string;
  children?: Array<ParsedSpecificationCategory | ParseSpecificationItem>;
};

export enum ProductIdentifier {
  GTIN = 'gtin',
  RSK = 'rsk',
}

export type ProductSearchResultHit = {
  id: string;
  name: string;
  category: {
    id: string;
    name: string;
  };
  brand: string;
  productLink?: string;
  place?: string;
  image?: {
    thumbnailUrl?: string;
    url?: string;
  };
  identifiers: Record<string, string | Array<string> | undefined>;
  score: number;
  source?: {
    id: string;
    name: string;
  };
  priority?: number;
  externals?: Array<ProductExternals>;
  merged: Array<ProductSearchResultHit>;
};

export type ProductExternalImage = { url?: string, thumbnailUrl: string } | { url: string, thumbnailUrl?: string };

export type ProductInitialData = {
  name: Product['name'];
  brand: Product['brand'];
  place?: Product['place'];
  type?: Product['type'];
  purchaseDate?: Product['purchaseDate'];
  price?: Product['price'];
  annotation?: Product['annotation'];
  productLink?: Product['productLink'];
  externals?: Array<ProductExternals>;
  gtin?: string;
  image?: ProductExternalImage;
  searchMetadata?: {
    hitIndex: number;
    hitId: string,
    searchedText: string,
    score: number,
    searchId?: string
  }
};

export type ProductTypeGuide = {
  type: string;
  name: string;
  icon: IconResource;
  fields: Array<DynamicInputField>
};

export type ProductExternalMediaSummary = {
  hasDescription: boolean;
  hasSpecification: boolean;
  accessories: Array<ProductSourceLink>;
  documents: Array<ProductSourceLink>;
  images: Array<ProductSourceLink>;
};

export type ProductSearchResults = { hits: Array<ProductSearchResultHit>, searchId?: string };

export enum ProductState {
  ALL = 'all',
  ACTUAL = 'active',
  HISTORIC = 'decommissioned',
}
