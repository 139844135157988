import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Card,
  CircularProgress,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import {
  useNavigate,
} from 'react-router';
import {
  GridAlignment,
  GridRenderCellParams,
  GridRowSelectionModel,
} from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import * as R from 'ramda';
import { match } from 'ts-pattern';
import {
  ArrowForward,
  Cancel,
  DeleteOutline,
  FileDownloadOutlined,
  OpenInBrowser,
} from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { skipToken } from '@reduxjs/toolkit/query';
import { EntityType } from '@house-id/houseid-types/dist/entityType';
import { AnalyticsEvent } from '@house-id/houseid-types/dist/analytics';

import HomeListLayout from '../../../../../../pages/Home/components/HomeListLayout';
import {
  DateTimeFormats,
  formatDate,
} from '../../../../../../../../utils/date';
import {
  EMPTY_VALUE,
} from '../../../../../../../../utils/string';
import useGetCurrentPropertyId from '../../../../../../hooks/useGetCurrentPropertyId';
import useBreakPointsSizes from '../../../../../../../../hooks/useBreakpointsSizes';
import {
  ContentFile,
} from '../../types.contentFile';
import ListEntitiesToolbarActions, { SelectionModeType } from '../../../../components/actions/ListEntitiesToolbarActions';
import ListEntitiesActions from '../../../../components/actions/ListEntitiesActions';
import ContentImage from '../../../../components/ContentImage';
import {
  useDeleteContentFilesMutation,
  useGetAllContentFilesQuery,
  useUploadContentFilesMutation,
} from '../../api/api.contentFile';
import HIDFilePicker, { HIDFilePickerRef } from '../../../../../../../../components/filePicker/HIDFilePicker';
import { getCreatePhotosPath } from '../../../Photo/navigation.photo';
import { useGetEntityTypeNames } from '../../../../../../../../constants/entityTypeName';
import { getCreateDocumentPath } from '../../../Document/navigation.document';
import { getCreateReceiptPath } from '../../../Receipt/navigation.receipt';
import { downloadBlobs } from '../../../../../../../../utils/download';
import { openFileInNewTab } from '../../../../../../../../utils/file';
import HIDIconButton from '../../../../../../../../components/buttons/HIDIconButton';
import HIDIconButtonContextMenu from '../../../../../../../../components/contextMenu/HIDIconButtonContextMenu';
import { getHomePath } from '../../../../../../navigation/navigation.property';
import { RootState } from '../../../../../../../../store/store';
import useGetLogAnalyticsEvent from '../../../../../../../Analytics/hooks/useGetLogAnalyticsEvent';
import { getPathWithPropertyIdOrInit } from '../../../../../../../Auth/navigation/navigation.auth';
import useSearch from '../../../../../../../../hooks/useSearch';
import useGetPropertyPermissions from '../../../../../../hooks/useGetPropertyPermissions';

const ContentFiles = () => {
  const { t } = useTranslation(['common', 'content_files']);
  const navigate = useNavigate();
  const theme = useTheme();

  const { displaySearch } = useSearch(EntityType.CONTENT_FILE);

  const { data: propertyId } = useGetCurrentPropertyId();
  const {
    isDownLg,
    isDownMd,
    isDownSm,
  } = useBreakPointsSizes();
  const { isMenuOpened } = useSelector((state: RootState) => state.layout);

  const filePickerRef = useRef<HIDFilePickerRef | undefined>(undefined);

  const [selectionModeType, setSelectionModeType] = useState<SelectionModeType | undefined>(undefined);

  const [selectedContentFileIds, setSelectedContentFileIds] = useState<Array<string>>([]);
  const [createdContentFiles, setCreatedContentFiles] = useState<Array<ContentFile>>([]);

  const { data: { canCreate, canUpdate } = {} } = useGetPropertyPermissions();

  const [deleteContentFiles, { isLoading: isDeleting }] = useDeleteContentFilesMutation();

  const {
    data: contentFiles = [],
    isLoading,
  } = useGetAllContentFilesQuery(
    propertyId ? { propertyId } : skipToken,
    {
      // TODO: Get rid of when the task https://github.com/orgs/House-ID/projects/17/views/1?pane=issue&itemId=33307955 is fixed
      refetchOnMountOrArgChange: true,
    },
  );

  const [uploadContentFiles, {
    data: createdFiles,
    status: filesUploading,
    isLoading: isCreating,
  }] = useUploadContentFilesMutation();

  useEffect(() => {
    if (createdFiles.length && createdFiles !== createdContentFiles) {
      setCreatedContentFiles(createdFiles);
    }
  }, [createdFiles]);

  // eslint-disable-next-line deprecation/deprecation
  const entityNames = useGetEntityTypeNames();

  const allContentFiles = useMemo(
    () => R.uniqBy(R.prop('id'), contentFiles.concat(createdContentFiles)),
    [contentFiles, createdContentFiles],
  );

  const isSelectionMode = Boolean(selectionModeType);
  const circularProgressSize = 40;

  const getContentFileIcon = (contentFile: ContentFile) => match(filesUploading[contentFile.id])
    .with('loading', () => (
      <CircularProgress
        size={circularProgressSize}
        sx={{
          marginRight: theme.spacing(1.5),
          minWidth: circularProgressSize,
        }}
      />
    ))
    .with('error', () => <Cancel color="error" sx={{ marginRight: theme.spacing(1.5) }} />)
    .with('success', () => (
      <ContentImage
        blobs={[contentFile.blob]}
        imageSx={{ maxHeight: theme.spacing(8) }}
        sx={{ marginRight: theme.spacing(1.5) }}
      />
    ))
    .otherwise(() => (
      <ContentImage
        blobs={[contentFile.blob]}
        imageSx={{ maxHeight: theme.spacing(8) }}
        sx={{ marginRight: theme.spacing(1.5) }}
      />
    ));

  const handleRowSelectionModelChange = useCallback(
    (rowSelectionModel: GridRowSelectionModel) => setSelectedContentFileIds(rowSelectionModel as Array<string>),
    [],
  );

  const handleNavigateToCategories = useCallback(
    () => navigate(getPathWithPropertyIdOrInit(getHomePath, { propertyId })),
    [navigate, propertyId],
  );

  const logAnalyticsEvent = useGetLogAnalyticsEvent();

  const handleFilesSelected = (files: Array<File>) => {
    if (propertyId) {
      uploadContentFiles(propertyId, files);

      logAnalyticsEvent({
        event: AnalyticsEvent.CREATE_CONTENT_FILE,
        hidCategory: EntityType.CONTENT_FILE,
      });
    }
  };

  const handleAdd = () => filePickerRef?.current?.open();

  const handleEnterSelectionMode = (selectionModeType: SelectionModeType) => {
    setSelectionModeType(selectionModeType);
    setSelectedContentFileIds([]);
  };

  const handleDelete = (selectedContentFileIds: Array<string>) => {
    if (propertyId) {
      deleteContentFiles({ propertyId, ids: selectedContentFileIds });
    }
    setSelectionModeType(undefined);
    setCreatedContentFiles([]);
  };

  const handleMove = (entityType: EntityType, selectedContentFileIds: Array<string>) => {
    match(entityType)
      .with(
        EntityType.PHOTO,
        () => navigate(getPathWithPropertyIdOrInit(getCreatePhotosPath, { propertyId, contentFileIds: selectedContentFileIds })),
      )
      .with(
        EntityType.DOCUMENT,
        () => navigate(getPathWithPropertyIdOrInit(getCreateDocumentPath, { propertyId, contentFileIds: selectedContentFileIds })),
      )
      .with(
        EntityType.RECEIPT,
        () => navigate(getPathWithPropertyIdOrInit(getCreateReceiptPath, { propertyId, contentFileIds: selectedContentFileIds })),
      )
      .otherwise(() => R.always<void>);

    setSelectionModeType(undefined);
  };

  const handleDownload = (selectedContentFileIds: Array<string>) => {
    downloadBlobs(contentFiles
      .filter((file) => selectedContentFileIds.includes(file.id))
      .map((file) => file.blob));

    setSelectionModeType(undefined);
    setSelectedContentFileIds([]);
  };

  const MoveActions = [{
    id: EntityType.PHOTO,
    mode: SelectionModeType.MOVE,
    label: entityNames[EntityType.PHOTO],
    onAction: () => handleMove(EntityType.PHOTO, selectedContentFileIds),
  },
  {
    id: EntityType.DOCUMENT,
    mode: SelectionModeType.MOVE,
    label: entityNames[EntityType.DOCUMENT],
    onAction: () => handleMove(EntityType.DOCUMENT, selectedContentFileIds),
  },
  {
    id: EntityType.RECEIPT,
    mode: SelectionModeType.MOVE,
    label: entityNames[EntityType.RECEIPT],
    onAction: () => handleMove(EntityType.RECEIPT, selectedContentFileIds),
  }];

  const showUserName = !isDownLg;
  const showDate = ((!isMenuOpened && (!isDownMd || !isDownSm)) || !isDownLg);

  const columns = [
    {
      field: 'blob',
      headerName: t('common:name'),
      flex: showUserName ? 0.3 : 0.5,
      type: 'string',
      sortable: true,
      valueGetter: (params: GridRenderCellParams) => (params?.row as ContentFile).blob.name,
      renderCell: (params: GridRenderCellParams) => {
        const contentFile = params?.row as ContentFile;
        const IconComponent = getContentFileIcon(contentFile);

        return (
          <Stack
            alignItems="center"
            direction="row"
            sx={{ minWidth: 0 }}
          >
            {IconComponent}
            <Stack
              direction="column"
              justifyContent="center"
              sx={{ minWidth: 0 }}
            >
              <Stack direction="column" sx={{ minWidth: 0 }}>
                <Typography noWrap variant="subtitle1">
                  {contentFile.blob.name}
                </Typography>
                {!showUserName && (
                  <Typography noWrap sx={{ color: theme.palette.grey[500] }} variant="body2">
                    {contentFile.user?.name}
                  </Typography>
                )}
              </Stack>
            </Stack>
          </Stack>
        );
      },
    },
    showUserName && {
      field: 'user',
      headerName: t('content_files:content_files_uploaded_by'),
      flex: 0.2,
      type: 'string',
      sortable: true,
      valueGetter: (params: GridRenderCellParams) => (params?.row as ContentFile).user.name,
      renderCell: (params: GridRenderCellParams) => {
        const { user } = params?.row as ContentFile;
        return (
          <Typography noWrap sx={{ color: theme.palette.grey[500] }} variant="body2">
            {user?.name}
          </Typography>
        );
      },
    },
    showDate && {
      field: 'updatedAt',
      headerName: t('content_files:content_files_updated_at'),
      flex: 0.2,
      type: 'string',
      align: 'center' as GridAlignment,
      headerAlign: 'center' as GridAlignment,
      sortable: true,
      renderCell: (params: GridRenderCellParams) => {
        const { createdAt } = params?.row as ContentFile;
        return (
          <Typography noWrap sx={{ color: theme.palette.grey[500] }} variant="body2">
            {createdAt ? formatDate(new Date(createdAt), DateTimeFormats.DATE_ONLY) || EMPTY_VALUE : EMPTY_VALUE}
          </Typography>
        );
      },
    },
    {
      field: 'move',
      headerName: '',
      flex: showDate ? 0.4 : 0.5,
      type: 'string',
      align: 'right' as GridAlignment,
      headerAlign: 'right' as GridAlignment,
      sortable: true,
      renderCell: (params: GridRenderCellParams) => {
        const { id, blob } = params?.row as ContentFile;
        const fileUploadingStatus = filesUploading[id];

        const isBlobNotUploaded = (fileUploadingStatus !== undefined && fileUploadingStatus !== 'success');

        return (
          <Stack
            alignItems="center"
            direction="row"
            flex={1}
            justifyContent="flex-end"
            spacing={1}
          >
            <HIDIconButton
              Icon={DeleteOutline}
              disabled={isSelectionMode}
              size={isDownSm ? 'small' : 'medium'}
              title={t('common:delete_label')}
              onClick={() => handleDelete([id])}
            />
            <HIDIconButton
              Icon={OpenInBrowser}
              disabled={isSelectionMode || isBlobNotUploaded}
              size={isDownSm ? 'small' : 'medium'}
              title={t('common:open_label')}
              onClick={() => openFileInNewTab(blob.downloadUrl)}

            />
            <HIDIconButton
              Icon={FileDownloadOutlined}
              disabled={isSelectionMode || isBlobNotUploaded}
              size={isDownSm ? 'small' : 'medium'}
              title={t('common:download_label')}
              onClick={() => handleDownload([id])}
            />
            {canUpdate && (
              <HIDIconButtonContextMenu
                Icon={ArrowForward}
                color="primary"
                disabled={isSelectionMode || isBlobNotUploaded}
                listItems={
                  MoveActions
                    .map((action) => ({
                      id: action.id,
                      label: action.label,
                      onClick: () => handleMove(action.id, [id]),
                    }))
                }
                size={isDownSm ? 'small' : 'medium'}
                title={t('common:move_label')}
              />
            )}
          </Stack>
        );
      },
    },
  ].filter(Boolean);

  const customActions = [
    {
      id: 'download',
      Icon: FileDownloadOutlined,
      label: t('common:download_label'),
      disabled: isSelectionMode,
      onClick: () => handleEnterSelectionMode(SelectionModeType.DOWNLOAD),
    },
  ];

  const isUpdating = isDeleting || isCreating;

  return (
    <HomeListLayout
      DataGridToolbar={
        <ListEntitiesToolbarActions
          count={allContentFiles.length}
          customActions={{
            [SelectionModeType.MOVE]: MoveActions,
          }}
          entity={EntityType.CONTENT_FILE}
          isFetching={isUpdating}
          isLoading={isLoading}
          selectedCount={selectedContentFileIds.length}
          selectionModeType={selectionModeType}
          sx={{ marginTop: selectionModeType ? 0 : 3 }}
          onCancel={() => setSelectionModeType(undefined)}
          onDelete={() => handleDelete(selectedContentFileIds)}
          onDownload={() => handleDownload(selectedContentFileIds)}
        />
      }
      ListHeaderComponent={
        !selectionModeType && canCreate
          ? (
            <Stack
              alignItems="center"
              justifyContent="center"
            >
              <HIDFilePicker
                isLoading={isLoading}
                ref={filePickerRef}
                sx={{
                  width: '100%',
                  minHeight: theme.spacing(32),
                }}
                onFilesSelected={handleFilesSelected}
              />
            </Stack>
          )
          : undefined
      }
      SideColumn={
        <Card sx={{ padding: 2 }}>
          <ListEntitiesActions
            customActions={customActions}
            disabled={isSelectionMode}
            onAdd={handleAdd}
            onDelete={() => handleEnterSelectionMode(SelectionModeType.DELETE)}
            onMove={() => handleEnterSelectionMode(SelectionModeType.MOVE)}
            onSearch={displaySearch}
          />
        </Card>
      }
      columns={columns}
      initialState={{
        sorting: {
          sortModel: [
            {
              field: 'updatedAt',
              sort: 'desc',
            },
          ],
        },
      }}
      isLoading={isLoading}
      isSelectionMode={Boolean(selectionModeType)}
      rows={allContentFiles}
      sideDrawerElements={[
        <ListEntitiesActions
          customActions={customActions}
          disabled={isSelectionMode}
          key={ListEntitiesActions.name}
          onAdd={handleAdd}
          onDelete={() => handleEnterSelectionMode(SelectionModeType.DELETE)}
          onMove={() => handleEnterSelectionMode(SelectionModeType.MOVE)}
          onSearch={displaySearch}
        />,
      ]}
      title={t('content_files:content_files_title')}
      onBack={handleNavigateToCategories}
      onRowSelectionModelChange={handleRowSelectionModelChange}
    />
  );
};

export default ContentFiles;
