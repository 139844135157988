import { EntityType } from '@house-id/houseid-types/dist/entityType';
import { Property } from '@house-id/houseid-types/dist/property';
import {
  PropertySelectedParams,
  AnalyticsEvent,
} from '@house-id/houseid-types/dist/analytics';

import useGetLogAnalyticsEvent from './useGetLogAnalyticsEvent';
import { removeUndefinedValues } from '../../../utils/form';

const now = new Date();

const useLogPropertySelectedEvent = () => {
  const logEvent = useGetLogAnalyticsEvent();

  return (property: Property) => {
    const params = {
      source: property.source,
      lastSyncSource: property.data?.lastSync?.source,
      isVerified: property.isVerified,
      stage: property.stage,
      type: property.type,
      hasFutureAccessData: property.accessDate ? new Date(property.accessDate) > now : undefined,
      origin: property.origin.flow,
    } as PropertySelectedParams;

    logEvent({
      event: AnalyticsEvent.PROPERTY_SELECTED,
      hidCategory: EntityType.PROPERTY,
      // @ts-ignore
      params: removeUndefinedValues(params),
    });
  };
};

export default useLogPropertySelectedEvent;
