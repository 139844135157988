import React, {
  FC,
  useState,
  useEffect,
  useRef,
} from 'react';
import {
  Checkbox,
  FormControlLabel,
  Grid,
  Stack,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { InsertLink } from '@mui/icons-material';
import * as R from 'ramda';
import { useNavigate } from 'react-router';
import { skipToken } from '@reduxjs/toolkit/query';
import {
  HIDBlob,
  HIDBlobModify,
  ModifyActionType,
  HIDEntityId,
} from '@house-id/houseid-types/dist/common';
import {
  Product,
  ExternalMedia,
  ExternalMediaType,
  ProductCategory,
} from '@house-id/houseid-types/dist/content/product';
import { EntityType } from '@house-id/houseid-types/dist/entityType';

import {
  useNavigateBackOr,
  useNavigationParamsAndState,
} from '../../../../../../../utils/routes';
import HomeLayout from '../../../../../pages/Home/components/HomeLayout';
import {
  getProductPath,
  getProductsPath,
} from '../navigation.product';
import useGetCurrentPropertyId from '../../../../../hooks/useGetCurrentPropertyId';
import HIDTextField, { CurrencyAdornment } from '../../../../../../../components/HIDTextField';
import { CreateEntity } from '../../../../../../../types/common';
import {
  ProductIdentifier,
  ProductInitialData,
} from '../types.product';
import {
  useCreateProductMutation,
  useDeleteExternalMedialMutation,
  useGetProductCategoriesQuery,
  useGetProductWithCache,
  useUpdateProductMutation,
} from '../api/api.product';
import { getHandleSetField } from '../../../../../../../utils/form';
import HIDFormSelect from '../../../../../../../components/HIDFormSelect';
import HIDFormDatePicker from '../../../../../../../components/datePicker/HIDFormDatePicker';
import { useGetPurchasePlacesQuery } from '../../../api/api.content';
import HIDFormFreeSelect from '../../../../../../../components/HIDFormFreeSelect';
import {
  useGetPeriodListItems,
  getIdentifierValue,
  useGetNavigateAfterExternalMediaCreate,
  fromSearchResultToExternalSource,
} from '../utils.products';
import ContentFileViewer from '../../../components/ContentFileViewer';
import { ContentFile } from '../../ContentFile/types.contentFile';
import useBreakPointsSizes from '../../../../../../../hooks/useBreakpointsSizes';
import { ImageMimeTypes } from '../../../../../../../constants/mimeTypes';
import { useCreateContentFileFromExternalMediaMutation } from '../../ContentFile/api/api.contentFile';
import CreateContentPageBottomToolbar, {
  CreateContentSaveMode,
  WithSaveMode,
} from '../../../components/CreateContentPageBottomToolbar';
import { EntityConnectionsCommonRouteState } from '../../../types/types.content';
import { IdPropRoute } from '../../../../../../../types/route';
import { HIDImageViewerRef } from '../../../../../../../components/image/HIDImageViewer/HIDImageViewer';
import useEntitySuggestionSection from '../../Suggestion/hooks/useEntitySuggestionSection';
import { handleSelectSuggestion } from '../../Suggestion/utils.suggestion';
import useManageConnectionAfterCreateOrUpdate from '../../../hooks/useManageConnectionAfterCreateOrUpdate';
import InfoBox from '../../../../../../../components/InfoBox';
import HIDLink from '../../../../../../../components/HIDLink';
import DialogNames from '../../../../../../../hooks/useDialog/DialogNames';
import useDialog from '../../../../../../../hooks/useDialog';
import { parseFloatNumber } from '../../../../../../../utils/string';
import { getPathWithPropertyIdOrInit } from '../../../../../../Auth/navigation/navigation.auth';
import HIDTypography from '../../../../../../../components/HIDTypography';
import HIDSection from '../../../../../../../components/HIDSection';

type CreateProduct = CreateEntity<Omit<Product, 'price'>> & { gtin?: string; price?: string } & Pick<ProductInitialData, 'searchMetadata'>;

const CreateUpdateProduct: FC = () => {
  const theme = useTheme();
  const { t } = useTranslation(['common', 'forms_common', 'products']);
  const navigateBackOr = useNavigateBackOr();
  const navigate = useNavigate();

  const {
    routeParams: {
      id: productId,
    },
    queryParams: {
      suggestionKey,
    },
    state: {
      connections,
      initialData: productInitialData,
    },
  } = useNavigationParamsAndState<IdPropRoute, { suggestionKey?: string }, EntityConnectionsCommonRouteState<ProductInitialData>>();

  const hasConnections = Boolean(connections && connections.length);

  const { isDownMd, isDownSm } = useBreakPointsSizes();

  const isUpdate = Boolean(productId);

  const {
    data: propertyId,
    isLoading: propertyIsLoading,
  } = useGetCurrentPropertyId();

  const {
    product,
    isLoading: productIsLoading,
    refetch: refetchProduct,
  } = useGetProductWithCache({ propertyId, productId });

  const { data: places = [] } = useGetPurchasePlacesQuery(propertyId ? { propertyId } : skipToken);

  const { data: productCategories } = useGetProductCategoriesQuery(propertyId ? { propertyId } : skipToken);

  const mapCategory = (category: ProductCategory) => category.children?.length
    ? [
      {
        id: category.id,
        name: category.name,
        isCategory: true,
      },
      ...category.children.map((item) => ({ id: item.id, name: item.name })),
    ]
    : [{
      id: category.id,
      name: category.name,
    }];

  const productCategoriesList = R.flatten(R.map(mapCategory, productCategories || []));

  const [deleteExternalMedia, { isLoading: externalMediaIsDeleting }] = useDeleteExternalMedialMutation();

  const [createContentFileFromExternalMedia, { isLoading: externalMediaIsMoving }] = useCreateContentFileFromExternalMediaMutation();

  const navigateAfterExternalMediaCreate = useGetNavigateAfterExternalMediaCreate(propertyId, productId);

  const [createProduct, { isLoading: isCreatingProduct }] = useCreateProductMutation();
  const [updateProduct, { isLoading: isUpdatingProduct }] = useUpdateProductMutation();

  const [blobs, setBlobs] = useState<Array<HIDBlob | HIDBlobModify>>([]);

  useEffect(() => {
    if (!blobs.length) {
      setBlobs(product?.blobs || []);
    }
  }, [product]);

  const handleGoBack = (entity?: HIDEntityId) => productId
    ? navigateBackOr(getPathWithPropertyIdOrInit(getProductPath, { propertyId, id: productId }))
    : entity?.id
      ? navigate(getPathWithPropertyIdOrInit(getProductPath, { propertyId, id: entity.id }), { replace: true })
      : navigateBackOr(getPathWithPropertyIdOrInit(getProductsPath, { propertyId }));

  const imageViewerRef = useRef<HIDImageViewerRef | null>(null);
  const typeRef = useRef<HTMLInputElement | undefined>();
  const lifetimeRef = useRef<HTMLInputElement | undefined>();
  const warrantyRef = useRef<HTMLInputElement | undefined>();

  const [fieldRefs] = useState<Record<string, React.MutableRefObject<HTMLInputElement | undefined>>>({
    brand: useRef<HTMLInputElement>(),
    price: useRef<HTMLInputElement>(),
    place: useRef<HTMLInputElement>(),
    purchase_date: useRef<HTMLInputElement>(),
  });

  const customFieldSuggestionAction: Record<string, () => void> = {
    file: () => setTimeout(() => imageViewerRef.current?.openFilePicker(), 300),
    type: () => handleSelectSuggestion(typeRef),
    lifetime: () => handleSelectSuggestion(lifetimeRef),
    warranty: () => handleSelectSuggestion(warrantyRef),
  };

  const {
    SuggestionSection,
    refetchSuggestions,
  } = useEntitySuggestionSection({
    entityId: productId,
    entityType: EntityType.PRODUCT,
    customFieldSuggestionAction,
    fieldRefs,
    initialSuggestion: suggestionKey,
  });

  const {
    afterUpdate,
    afterCreate,
  } = useManageConnectionAfterCreateOrUpdate({
    entityType: EntityType.PRODUCT,
    connections,
    onGoBack: handleGoBack,
  });

  const handleFormSubmit = (values: WithSaveMode<CreateProduct>) => {
    const { saveMode } = values;

    if (!propertyId) {
      return;
    }

    const productFields = {
      propertyId,
      name: values.name,
      annotation: values.annotation,
      purchaseDate: values.purchaseDate,
      type: values.type,
      price: parseFloatNumber(values.price),
      brand: values.brand,
      place: values.place,
      serialNumber: values.serialNumber,
      gtin: values.gtin,
      productLink: values.productLink,
      movable: values.movable,
      decommissionedAt: values.decommissionedAt,
      lifetimeInMonth: values.lifetimeInMonth,
      warrantyInMonth: values.warrantyInMonth,
      ...(
        productInitialData
          ? {
            externals: productInitialData.externals,
            searchMetadata: productInitialData.searchMetadata,
          }
          : {}
      ),
    };

    if (isUpdate && productId) {
      updateProduct({
        id: productId,
        ...productFields,
        blobs: [
          ...R.difference(product?.blobs || [], blobs)
            .map((blob) => ({ ...blob, action: ModifyActionType.DELETE })),
          ...R.difference(blobs, product?.blobs || [])
            .map((blob) => ({ ...blob, action: ModifyActionType.CREATE })),
        ],
      })
        .unwrap()
        .then((updatedProduct) => {
          refetchSuggestions();
          afterUpdate(updatedProduct, saveMode);
        });
    } else {
      createProduct({
        ...productFields,
        blobs: blobs.map((blob) => ({ ...blob, action: ModifyActionType.CREATE })),
      })
        .unwrap()
        .then((createdProduct) => {
          afterCreate(createdProduct, saveMode);
        });
    }
  };

  const schema = Yup.object({
    name: Yup.string().required(t('forms_common:field_mandatory')),
    annotation: Yup.string().optional().nullable(),
    purchaseDate: Yup.string().optional().nullable(),
    type: Yup.string().optional().optional().nullable(),
    price: Yup.string().optional().nullable(),
    brand: Yup.string().optional().nullable(),
    place: Yup.string().optional().nullable(),
    serialNumber: Yup.string().optional().nullable(),
    productLink: Yup.string().optional().nullable(),
    gtin: Yup.string().optional().nullable(),
    movable: Yup.boolean().optional().nullable(),
    decommissionedAt: Yup.string().optional().nullable(),
    lifetimeInMonth: Yup.number().optional().nullable(),
    warrantyInMonth: Yup.number().optional().nullable(),
    saveMode: Yup.string().optional().nullable(),
  });

  const formik = useFormik<CreateProduct>({
    initialValues: {
      propertyId: product?.propertyId || propertyId || '',
      name: product?.name || productInitialData?.name || '',
      annotation: product?.annotation || productInitialData?.annotation,
      purchaseDate: product?.purchaseDate || productInitialData?.purchaseDate,
      type: product?.type || productInitialData?.type,
      price: product?.price?.toString() || productInitialData?.price?.toString(),
      brand: product?.brand || productInitialData?.brand,
      place: product?.place || productInitialData?.place,
      serialNumber: product?.serialNumber,
      productLink: product?.productLink || productInitialData?.productLink,
      gtin: product
        ? getIdentifierValue(ProductIdentifier.GTIN, product)
        : productInitialData?.gtin,
      movable: product?.movable,
      decommissionedAt: product?.decommissionedAt,
      lifetimeInMonth: product?.lifetimeInMonth,
      warrantyInMonth: product?.warrantyInMonth,
    },
    enableReinitialize: true,
    validationSchema: schema,
    onSubmit: handleFormSubmit,
  });

  const handleSave = (saveMode: CreateContentSaveMode) => {
    formik.setFieldValue('saveMode', saveMode);
    formik.submitForm();
  };

  const handleSetField = getHandleSetField<CreateProduct>(formik);

  const periodOptions = useGetPeriodListItems();

  const externalMedia = productInitialData?.image
    ? fromSearchResultToExternalSource(productInitialData.image)
    : undefined;

  const [searchExternalMedia, setSearchExternalMedia] = useState<ExternalMedia[] | undefined>(undefined);

  useEffect(() => {
    setSearchExternalMedia(externalMedia ? [externalMedia] : undefined);
  }, [productInitialData]);

  const handleDeleteBlob = (id: string) => setBlobs(blobs.filter((blob) => blob.id !== id));

  const handleDeleteExternalMedia = (url: string) => {
    if (productId && propertyId) {
      deleteExternalMedia({
        id: productId,
        propertyId,
        url,
      })
        .then(() => refetchProduct());
    } else if (productInitialData) {
      setSearchExternalMedia(undefined);
    }
  };

  const handleMoveExternalMedia = (url: string, mediaType: ExternalMediaType) => {
    if (productId && product && propertyId) {
      if ([ExternalMediaType.DOCUMENT, ExternalMediaType.IMAGE].includes(mediaType)) {
        createContentFileFromExternalMedia({
          propertyId,
          url,
        })
          .unwrap()
          .then((contentFile) => navigateAfterExternalMediaCreate({ mediaType, url, contentFileId: contentFile.id }));
      } else {
        navigateAfterExternalMediaCreate({ mediaType, url, name: product.name });
      }
    }
  };

  const handleContentFilesSelected = (contentFiles: Array<ContentFile>) => {
    const newBlobs = contentFiles.map(({ blob }) => blob);
    setBlobs([...blobs, ...newBlobs]);

    if (isUpdate && propertyId && productId) {
      const removedBlobs = R.difference(product?.blobs || [], blobs)
        .map((blob) => ({ ...blob, action: ModifyActionType.DELETE }));

      updateProduct({
        id: productId,
        propertyId,
        blobs: removedBlobs.concat(newBlobs.map((blob) => ({ ...blob, action: ModifyActionType.CREATE }))),
      });
    }
  };

  const [openProductExternalMediaInfoDialog] = useDialog(DialogNames.PRODUCT_EXTERNAL_MEDIA_INFO_DIALOG);
  const handleReadMoreProductExternalMediaInfo = () => {
    openProductExternalMediaInfoDialog({
      externals: productInitialData?.externals,

    });
  };

  const contentFilesLoading = propertyIsLoading
    || productIsLoading
    || externalMediaIsDeleting
    || externalMediaIsMoving;

  return (
    <HomeLayout
      SideColumn={
        <>
          <ContentFileViewer
            showFiles
            showImages
            blobs={blobs}
            externalMedia={product?.externalMedia || searchExternalMedia}
            imageViewerRef={imageViewerRef}
            isLoading={contentFilesLoading}
            mimeTypes={ImageMimeTypes}
            variant="side_column"
            onContentFilesSelected={handleContentFilesSelected}
            onDeleteBlob={handleDeleteBlob}
            onDeleteExternalMedia={handleDeleteExternalMedia}
            onMoveExternalMedia={isUpdate ? handleMoveExternalMedia : undefined}
          />
          {SuggestionSection}
        </>
      }
      breadcrumbsLinks={[
        {
          link: getPathWithPropertyIdOrInit(getProductsPath, { propertyId }),
          name: t('products:products_title'),
        },
      ].filter(Boolean)}
      title={isUpdate ? t('products:edit_product') : t('products:add_product')}
      onBack={handleGoBack}
    >
      <Grid
        container
        alignItems="center"
        columnSpacing={2.5}
        justifyContent="flex-start"
        rowSpacing={1}
      >
        {isDownMd && (
          <ContentFileViewer
            showFiles
            showImages
            blobs={blobs}
            externalMedia={product?.externalMedia || searchExternalMedia}
            imageViewerRef={imageViewerRef}
            isLoading={contentFilesLoading}
            mimeTypes={ImageMimeTypes}
            variant="inline"
            onContentFilesSelected={handleContentFilesSelected}
            onDeleteBlob={handleDeleteBlob}
            onDeleteExternalMedia={handleDeleteExternalMedia}
            onMoveExternalMedia={isUpdate ? handleMoveExternalMedia : undefined}
          />
        )}
        {Boolean(productInitialData?.externals) && (
          <Grid item xxs={12}>
            <InfoBox
              RightComponent={
                <HIDLink
                  color="secondary"
                  label={t('common:show_more')}
                  linkColor={theme.palette.common.black}
                  variant={isDownSm ? 'body2' : 'body1'}
                  onClick={handleReadMoreProductExternalMediaInfo}
                />
              }
              message={t('products:external_media_uploaded_automatically')}
              sx={{ marginBottom: 2 }}
            />
          </Grid>
        )}
        <Grid item xxs={12}>
          <HIDTextField
            required
            error={Boolean(formik.touched.name && formik.errors.name)}
            helperText={formik.touched.name ? formik.errors.name : undefined}
            id="name"
            label={t('forms_common:name')}
            value={formik.values.name}
            onChange={handleSetField('name')}
          />
        </Grid>
        <Grid item sm={6} xxs={12}>
          <HIDFormSelect
            multilevel
            items={productCategoriesList}
            label={t('products:product_type')}
            ref={typeRef}
            value={formik.values.type || ''}
            onChange={handleSetField('type')}
          />
        </Grid>
        <Grid item sm={6} xxs={12}>
          <HIDTextField
            id="product_brand"
            label={t('products:brand')}
            ref={fieldRefs.brand}
            value={formik.values.brand || ''}
            onChange={handleSetField('brand')}
          />
        </Grid>
        <Grid item sm={4} xxs={12}>
          <HIDTextField
            endAdornment={<CurrencyAdornment />}
            error={Boolean((formik.touched.price || isUpdate) && formik.errors.price)}
            helperText={formik.touched.price || isUpdate ? formik.errors.price : undefined}
            id="product_price"
            label={t('products:purchase_price')}
            ref={fieldRefs.price}
            type="number"
            value={formik.values.price?.toString() || ''}
            onChange={handleSetField('price')}
          />
        </Grid>
        <Grid item sm={4} xxs={12}>
          <HIDFormDatePicker
            label={t('products:purchase_date')}
            ref={fieldRefs.purchase_date}
            value={formik.values.purchaseDate ? new Date(formik.values.purchaseDate) : undefined}
            onChange={(date) => formik.setFieldValue('purchaseDate', date?.toISOString())}
          />
        </Grid>
        <Grid item sm={4} xxs={12}>
          <HIDFormFreeSelect
            items={places}
            label={t('products:purchase_place')}
            ref={fieldRefs.place}
            value={formik.values.place || ''}
            onChange={(_event, value) => formik.setFieldValue('place', value)}
          />
        </Grid>
        <Grid
          item
          sm={6}
          sx={{ alignSelf: 'flex-start' }}
          xxs={12}
        >
          <HIDTextField
            id="serialNumber"
            label={t('products:serial_number')}
            value={formik.values.serialNumber || ''}
            onChange={handleSetField('serialNumber')}
          />
        </Grid>
        <Grid item sm={6} xxs={12}>
          <HIDTextField
            helperTextWrap
            id="gtin"
            label={t('products:product_gtin')}
            value={formik.values.gtin || ''}
            onChange={handleSetField('gtin')}
          />
        </Grid>
        <Grid item xxs={12}>
          <HIDTextField
            endAdornment={<InsertLink />}
            id="product_link"
            label={t('products:product_link')}
            value={formik.values.productLink || ''}
            onChange={handleSetField('productLink')}
          />
        </Grid>
        <Grid
          item
          sm={6}
          sx={{ alignSelf: 'flex-start' }}
          xxs={12}
        >
          <HIDFormSelect
            helperTextWrap
            helperText={t('products:filling_in_the_lifespan')}
            items={periodOptions}
            label={t('products:estimated_lifetime')}
            ref={lifetimeRef}
            value={formik.values.lifetimeInMonth?.toString() || ''}
            onChange={handleSetField('lifetimeInMonth', Number.parseInt)}
          />
        </Grid>
        <Grid
          item
          sm={6}
          sx={{ alignSelf: 'flex-start' }}
          xxs={12}
        >
          <HIDFormSelect
            helperTextWrap
            helperText={t('products:filling_in_the_warranty')}
            items={periodOptions}
            label={t('products:warranty_period')}
            ref={warrantyRef}
            value={formik.values.warrantyInMonth?.toString() || ''}
            onChange={handleSetField('warrantyInMonth', Number.parseInt)}
          />
        </Grid>
        <Grid item sx={{ marginTop: 3 }} xxs={12}>
          <HIDTextField
            multiline
            id="annotation"
            label={t('products:product_annotation')}
            showHelperText={false}
            value={formik.values.annotation || ''}
            variant="outlined"
            onChange={handleSetField('annotation')}
          />
        </Grid>
        <Grid
          item
          sx={{ marginTop: 2 }}
          xxs={12}
        >
          <HIDSection>
            <FormControlLabel
              control={<Checkbox checked={formik.values.movable} />}
              label={(
                <HIDTypography variant="subtitle1">
                  {t('products:private_product_short')}
                </HIDTypography>
              )}
              onChange={(_event, checked) => formik.setFieldValue('movable', checked)}
            />
            <HIDTypography>
              {t('products:private_product_description')}
            </HIDTypography>
          </HIDSection>
        </Grid>
        <Grid
          item
          sx={{ marginTop: 2 }}
          xxs={12}
        >
          <HIDSection sx={{ gap: 2 }}>
            <Stack>
              <FormControlLabel
                control={<Checkbox checked={Boolean(formik.values.decommissionedAt)} />}
                label={(
                  <HIDTypography variant="subtitle1">
                    {t('products:marked_as_historical_product')}
                  </HIDTypography>
                )}
                onChange={(_event, checked) => formik.setFieldValue('decommissionedAt', checked ? new Date().toISOString() : undefined)}
              />
              <HIDTypography>
                {t('products:historical_product_description')}
              </HIDTypography>
            </Stack>
            {formik.values.decommissionedAt !== undefined && (
              <Stack sx={{ alignItems: 'flex-start' }}>
                <HIDFormDatePicker
                  fullWidth={false}
                  label={t('products:decommissioned_date')}
                  ref={fieldRefs.purchase_date}
                  value={formik.values.decommissionedAt ? new Date(formik.values.decommissionedAt) : undefined}
                  onChange={(date) => formik.setFieldValue('decommissionedAt', date?.toISOString())}
                />
              </Stack>
            )}
          </HIDSection>
        </Grid>
      </Grid>
      <CreateContentPageBottomToolbar
        disabled={externalMediaIsDeleting}
        loading={isCreatingProduct || isUpdatingProduct}
        showAddConnections={!isUpdate && !hasConnections}
        sx={{ marginTop: theme.spacing(2) }}
        onCancel={handleGoBack}
        onSave={handleSave}
      />
    </HomeLayout>
  );
};

export default CreateUpdateProduct;
