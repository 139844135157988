// TODO: Improve this
export const BudgetCostColors = [
  '#409595',
  '#794189',
  '#4179BA',
  '#F7B62F',
  '#FF5A5F',
  '#7EE081',
  '#E2C2C6',
  '#F2C078',
  '#AF8D86',
  '#E0CA3C',
  '#264027',
  '#EBF5EE',
  '#F8F4A6',
  '#E08E45',
  '#F1E3F3',
  '#D63230',
  '#F092DD',
  '#392C1D',
  '#14591D',
  '#99AA38',
  '#A24936',
];

export const SWEDISH_REGION_ID = 'SE';

export const ALL_RECURRING_EXPENSES_CATEGORY = 'ALL_RECURRING_EXPENSES_CATEGORY';
export const VALUATION_MAX_VALUE = 30000000;
