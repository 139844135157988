import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import DialogWrapper from './DialogWrapper';
import {
  DialogStateListEntry,
  getDialogs,
} from '../../../store/dialogsReducer';

const DialogsManager = () => {
  const dialogs = useSelector(getDialogs);

  const dialogsToShow = useMemo(
    () => dialogs.filter(({ props }) => props?.open),
    [dialogs],
  );

  return (
    <>
      {dialogsToShow
        .map((dialog: DialogStateListEntry) => (
          <DialogWrapper
            id={dialog.id}
            key={dialog.id}
            name={dialog.name}
            props={dialog.props}
          />
        ))}
    </>
  );
};

export default DialogsManager;
