import {
  AuthError,
} from 'firebase/auth';
import { useTranslation } from 'react-i18next';

type UseGetAuthErrorMessageGetter = () => (error?: AuthError) => string | undefined;

const useGetAuthErrorMessageGetter: UseGetAuthErrorMessageGetter = () => {
  const { t } = useTranslation('auth_error_messages');

  return (error?: AuthError) => error
    // @ts-ignore
    ? t(`auth_error_messages:${error.code}` as keyof ReturnType<typeof t>)
    : undefined;
};

export default useGetAuthErrorMessageGetter;
