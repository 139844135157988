import React from 'react';
import { useTheme } from '@mui/material';

import { Icon } from '../../types/common';
import HIDSvgIcon from './HIDSvgIcon';

const HomePrizes: Icon = ({
  iconColor: colorProp,
  size,
  strokeWidth = 2,
}) => {
  const theme = useTheme();
  const color = colorProp || theme.palette.common.black;

  return (
    <HIDSvgIcon size={size}>
      <path
        d="m9.86144,21.58263l-4.90102,0c-0.51993,0 -1.01857,-0.20651 -1.38623,-0.57416c-0.36765,-0.36758 -0.57419,-0.86623 -0.57419,-1.38621l0,-10.78232l8.82195,-6.86142l8.82181,6.86142m-11.76251,6.86151l0,-4.90107l2.94063,0m1.96044,3.43076l0,6.86144c0,0.8136 1.96037,1.47031 4.41094,1.47031c2.4505,0 4.41094,-0.65672 4.41094,-1.47031l0,-6.86144m-8.82188,0c0,-0.81205 1.97485,-1.47031 4.41094,-1.47031c2.43609,0 4.41094,0.65826 4.41094,1.47031m-8.82188,0c0,0.81198 1.97485,1.47031 4.41094,1.47031c2.43609,0 4.41094,-0.65833 4.41094,-1.47031m0,3.43068c0,0.8136 -1.96044,1.47031 -4.41094,1.47031c-2.45057,0 -4.41094,-0.65672 -4.41094,-1.47031"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
      />
    </HIDSvgIcon>
  );
};

export default HomePrizes;
