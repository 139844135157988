import React, {
  useMemo,
  useState,
} from 'react';
import {
  Grid,
  Stack,
} from '@mui/material';
import * as R from 'ramda';
import { useNavigate } from 'react-router';
import { EntityType } from '@house-id/houseid-types/dist/entityType';
import { EntityContentConnection } from '@house-id/houseid-types/dist/content/entityContentConnection';
import { HIDEntityId } from '@house-id/houseid-types/dist/common';

import { FCC } from '../../../../../types/common';
import {
  EntityContentConnectionAction,
  ModificationType,
} from '../types/types.content';
import ConnectionListItem from './ConnectionListItem';
import ConnectionsListToolbar from './ConnectionsListToolbar';
import ListEntitiesToolbarActions, { SelectionModeType } from './actions/ListEntitiesToolbarActions';
import useGetCurrentPropertyId from '../../../hooks/useGetCurrentPropertyId';
import { usePatchEntityConnectionsMutation } from '../api/api.content';
import useGetEntityInfo from '../hooks/useGetEntityInfo';
import useDialog from '../../../../../hooks/useDialog';
import DialogNames from '../../../../../hooks/useDialog/DialogNames';
import { getPathWithPropertyIdOrInit } from '../../../../Auth/navigation/navigation.auth';
import { UnavailableFeature } from '../../../components/dialogs/OpenMobileAppDialog';

type ConnectionsListProps = {
  sourceEntity: Required<HIDEntityId>;
  sourceEntityType: EntityType;
  connections: Array<EntityContentConnection>;
  connectionEntityType: EntityType;
};

const getDocumentCount = R.pipe(
  R.toPairs,
  R.count(([, value]: [string, unknown]) => Boolean(value)),
);

const ConnectionsList: FCC<ConnectionsListProps> = ({
  sourceEntity,
  sourceEntityType,
  connections,
  connectionEntityType,
  sx,
}) => {
  const navigate = useNavigate();
  const { data: propertyId } = useGetCurrentPropertyId();

  const [selectionModeType, setSelectionModeType] = useState<SelectionModeType | undefined>();
  const [selectedDocumentIds, setSelectedDocumentIds] = useState<Record<string, boolean>>({});

  const [openMobileAppDialog] = useDialog(DialogNames.OPEN_MOBILE_APP_DIALOG);

  const selectedDocumentCount: number = useMemo(
    () => getDocumentCount(selectedDocumentIds),
    [selectedDocumentIds],
  );

  const getEntityInfo = useGetEntityInfo();
  const viewEntityConnection = getEntityInfo(connectionEntityType);

  const handleClick = (id: string) => {
    if (!selectionModeType) {
      if (viewEntityConnection?.getViewLink) {
        navigate(getPathWithPropertyIdOrInit(viewEntityConnection?.getViewLink, { propertyId, id }));
      } else {
        openMobileAppDialog({
          unavailableFeature: UnavailableFeature.VIEW_ENTITY_CONNECTION,
        });
      }
    } else {
      setSelectedDocumentIds((prev) => ({ ...prev, [id]: !prev[id] }));
    }
  };

  const [patchEntityConnections, { isLoading }] = usePatchEntityConnectionsMutation();
  const handleDelete = () => {
    const deleteActions = R.pipe(
      // @ts-ignore
      R.toPairs,
      R.filter(([, value]: [string, unknown]) => Boolean(value)),
      R.map(([key]: [string, unknown]) => ({
        type: connectionEntityType,
        entityId: key,
        action: ModificationType.DELETE,
      })),
    )(selectedDocumentIds);

    if (propertyId) {
      const data = {
        propertyId,
        entityId: sourceEntity.id,
        entityType: sourceEntityType,
        actions: deleteActions as Array<EntityContentConnectionAction>,
      };

      return patchEntityConnections(data)
        .then(() => {
          setSelectionModeType(undefined);
          setSelectedDocumentIds({});
        });
    }
  };

  return (
    <Stack sx={sx}>
      {
        selectionModeType
          ? (
            <ListEntitiesToolbarActions
              count={connections.length}
              entity={connectionEntityType}
              isFetching={isLoading}
              selectedCount={selectedDocumentCount}
              selectionModeType={selectionModeType}
              onCancel={() => setSelectionModeType(undefined)}
              onDelete={handleDelete}
            />
          )
          : (
            <ConnectionsListToolbar
              connectionEntityType={connectionEntityType}
              sourceEntity={sourceEntity}
              sourceEntityType={sourceEntityType}
              onDelete={() => setSelectionModeType(SelectionModeType.DELETE)}
            />
          )
      }
      <Grid
        container
        spacing={1.5}
        sx={{ marginTop: 1.5 }}
      >
        {connections.map((connection) => (
          <Grid
            item
            key={`${connection.type} ${connection.entity.id}`}
            lg={6}
            xxs={12}
          >
            <ConnectionListItem
              entityType={connection.type as EntityType}
              isSelected={selectedDocumentIds[connection.entity.id]}
              isSelectionMode={Boolean(selectionModeType)}
              name={connection.entity.name}
              thumbnailUrl={connection.entity.image?.thumbnailUrl}
              onClick={() => handleClick(connection.entity.id)}
            />
          </Grid>
        ))}
      </Grid>
    </Stack>
  );
};

export default ConnectionsList;
