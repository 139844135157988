import React, { FC } from 'react';
import {
  Dialog,
  Stack,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { HIDDialogProps } from '../../../../../../../types/common';
import HIDButton from '../../../../../../../components/buttons/HIDButton';
import HIDDialogActions from '../../../../../../../components/dialogs/HIDDialogActions';
import HIDDialogContent from '../../../../../../../components/dialogs/HIDDialogContent';
import HIDDialogTitle from '../../../../../../../components/dialogs/HIDDialogTitle';
import useBreakPointsSizes from '../../../../../../../hooks/useBreakpointsSizes';
import SFLogo from '../../../../../../../components/icons/SFLogo';
import HIDTypography from '../../../../../../../components/HIDTypography';
import { getPerformActionWithCloseModal } from '../../../../../../../utils/dialog';
import { hidSpacing } from '../../../../../../../utils/number';

export enum RealtorValuationModalMode {
  STANDARD = 'standard',
  ACCURATE = 'accurate',
}

export type BrokerValuationDialogProps = HIDDialogProps & {
  mode?: RealtorValuationModalMode;
  onBook: () => void;
};

const BrokerValuationDialog: FC<BrokerValuationDialogProps> = ({
  open,
  mode = RealtorValuationModalMode.STANDARD,
  onBook,
  onClose,
}) => {
  const theme = useTheme();
  const { isDownSm } = useBreakPointsSizes();

  const { t } = useTranslation(['finances']);

  const closeWithAction = getPerformActionWithCloseModal(onClose);

  const { subtitle, description } = {
    [RealtorValuationModalMode.STANDARD]: {
      subtitle: t('finances:broker_valuation_dialog_title'),
      description: t('finances:broker_valuation_dialog_description'),
    },
    [RealtorValuationModalMode.ACCURATE]: {
      subtitle: t('finances:broker_valuation_dialog_accurate_mode_title'),
      description: t('finances:broker_valuation_dialog_accurate_mode_description'),
    },
  }[mode];

  return (
    <Dialog
      fullWidth
      fullScreen={isDownSm}
      maxWidth="xs"
      open={open}
      onClose={onClose}
    >
      <HIDDialogTitle
        title={t('finances:broker_valuation')}
        onClose={onClose}
      />
      <HIDDialogContent style={isDownSm ? { alignItems: 'center', display: 'flex' } : undefined}>
        <Stack alignItems="center" gap={3}>
          <Stack
            alignItems="center"
            justifyContent="center"
            sx={{
              width: hidSpacing(8),
              height: hidSpacing(8),
              borderStyle: 'solid',
              borderColor: theme.palette.grey[300],
              borderRadius: '50%',
            }}
          >
            <SFLogo />
          </Stack>
          <HIDTypography sx={{ textAlign: 'center' }} variant="h4">
            {subtitle}
          </HIDTypography>
          <HIDTypography sx={{ textAlign: 'center' }} variant="body1">
            {description}
          </HIDTypography>
        </Stack>
      </HIDDialogContent>
      <HIDDialogActions>
        <HIDButton
          color="primary"
          onClick={() => closeWithAction(onBook)}
        >
          {t('finances:book_a_meeting')}
        </HIDButton>
      </HIDDialogActions>
    </Dialog>
  );
};

export default BrokerValuationDialog;
