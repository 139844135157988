import React, { FC } from 'react';
import {
  Grid,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { InsertLink } from '@mui/icons-material';
import * as R from 'ramda';
import { useNavigate } from 'react-router';
import { EntityType } from '@house-id/houseid-types/dist/entityType';
import { HIDEntityId } from '@house-id/houseid-types/dist/common';

import {
  useNavigateBackOr,
  useNavigationParamsAndState,
} from '../../../../../../../utils/routes';
import HomeLayout from '../../../../../pages/Home/components/HomeLayout';
import {
  getNotePath,
  getNotesPath,
} from '../navigation.note';
import useGetCurrentPropertyId from '../../../../../hooks/useGetCurrentPropertyId';
import HIDTextField from '../../../../../../../components/HIDTextField';
import { CreateEntity } from '../../../../../../../types/common';
import { Note } from '../types.note';
import {
  useCreateNoteMutation,
  useGetNoteWithCache,
  useUpdateNoteMutation,
} from '../api/api.note';
import { getHandleSetField } from '../../../../../../../utils/form';
import useBreakPointsSizes from '../../../../../../../hooks/useBreakpointsSizes';
import CreateContentPageBottomToolbar, {
  CreateContentSaveMode,
  WithSaveMode,
} from '../../../components/CreateContentPageBottomToolbar';
import {
  EntityConnectionsCommonRouteState,
  EntityUId,
} from '../../../types/types.content';
import { IdPropRoute } from '../../../../../../../types/route';
import useManageConnectionAfterCreateOrUpdate from '../../../hooks/useManageConnectionAfterCreateOrUpdate';
import { getPathWithPropertyIdOrInit } from '../../../../../../Auth/navigation/navigation.auth';

const CreateUpdateNote: FC = () => {
  const theme = useTheme();
  const { t } = useTranslation(['common', 'forms_common', 'notes']);
  const navigateBackOr = useNavigateBackOr();
  const navigate = useNavigate();

  const {
    routeParams,
    queryParams: {
      url: initialUrl,
      name: initialName,
    },
    state,
  } = useNavigationParamsAndState<IdPropRoute, { name?: string, url?: string }, EntityConnectionsCommonRouteState>();

  const noteId = routeParams?.id;

  const connections = state?.connections || [] as Array<EntityUId>;
  const hasConnections = !R.isEmpty(connections);

  const { isDownXl } = useBreakPointsSizes();

  const { data: propertyId } = useGetCurrentPropertyId();

  const { note, isLoading: noteIsLoading } = useGetNoteWithCache({ propertyId, noteId });

  const isUpdate = Boolean(noteId);

  const [createNote, { isLoading: isCreatingNote }] = useCreateNoteMutation();
  const [updateNote, { isLoading: isUpdatingNote }] = useUpdateNoteMutation();

  const handleGoBack = (entity?: HIDEntityId) => noteId
    ? navigateBackOr(getPathWithPropertyIdOrInit(getNotePath, { propertyId, id: noteId }))
    : entity?.id
      ? navigate(getPathWithPropertyIdOrInit(getNotePath, { propertyId, id: entity.id }), { replace: true })
      : navigateBackOr(getPathWithPropertyIdOrInit(getNotesPath, { propertyId }));

  const {
    afterUpdate,
    afterCreate,
  } = useManageConnectionAfterCreateOrUpdate({
    entityType: EntityType.NOTE,
    connections,
    onGoBack: handleGoBack,
  });

  const handleFormSubmit = (values: WithSaveMode<CreateEntity<Note>>) => {
    const { saveMode } = values;

    if (!propertyId) {
      return;
    }

    const noteFields = {
      propertyId,
      name: values.name,
      annotation: values.annotation,
      url: values.url,
    };

    if (isUpdate && noteId) {
      updateNote({ id: noteId, ...noteFields })
        .unwrap()
        .then((updatedNote) => afterUpdate(updatedNote, saveMode));
    } else {
      createNote(noteFields)
        .unwrap()
        .then((createdNote) => {
          afterCreate(createdNote, saveMode);
        });
    }
  };

  const schema = Yup.object({
    name: Yup.string().required(t('forms_common:field_mandatory')),
    annotation: Yup.string().optional().nullable(),
    url: Yup.string().optional().nullable(),
    saveMode: Yup.string().optional().nullable(),
  });

  const formik = useFormik<CreateEntity<Note>>({
    initialValues: {
      propertyId: propertyId || '',
      name: note?.name || initialName || '',
      annotation: note?.annotation,
      url: note?.url || initialUrl,
    },
    enableReinitialize: true,
    validationSchema: schema,
    onSubmit: handleFormSubmit,
  });

  const handleSave = (saveMode: CreateContentSaveMode) => {
    formik.setFieldValue('saveMode', saveMode);
    formik.submitForm();
  };

  const handleSetField = getHandleSetField<CreateEntity<Note>>(formik);

  return (
    <HomeLayout
      SideColumn={isDownXl ? undefined : <div />}
      breadcrumbsLinks={[
        {
          link: getPathWithPropertyIdOrInit(getNotesPath, { propertyId }),
          name: t('notes:notes_tile'),
        },
      ].filter(Boolean)}
      title={isUpdate ? t('notes:notes_edit_note') : t('notes:notes_add_note')}
      onBack={handleGoBack}
    >
      <Grid
        container
        columnSpacing={2.5}
        justifyContent="flex-start"
        rowSpacing={1}
      >
        <Grid item lg={6} xxs={12}>
          <HIDTextField
            required
            error={Boolean(formik.touched.name && formik.errors.name)}
            helperText={formik.touched.name ? formik.errors.name : undefined}
            id="name"
            label={t('forms_common:name')}
            value={formik.values.name}
            onBlur={formik.handleBlur('name')}
            onChange={handleSetField('name')}
          />
        </Grid>
        <Grid item xxs={12}>
          <HIDTextField
            endAdornment={<InsertLink />}
            id="url"
            label={t('notes:notes_link')}
            value={formik.values.url || ''}
            onBlur={formik.handleBlur('url')}
            onChange={handleSetField('url')}
          />
        </Grid>
        <Grid item xxs={12}>
          <HIDTextField
            multiline
            id="annotation"
            label={t('notes:notes_annotation')}
            value={formik.values.annotation || ''}
            variant="outlined"
            onBlur={formik.handleBlur('annotation')}
            onChange={handleSetField('annotation')}
          />
        </Grid>
      </Grid>
      <CreateContentPageBottomToolbar
        disabled={noteIsLoading}
        loading={isCreatingNote || isUpdatingNote}
        showAddConnections={!isUpdate && !hasConnections}
        sx={{ marginTop: theme.spacing(2) }}
        onCancel={handleGoBack}
        onSave={handleSave}
      />
    </HomeLayout>
  );
};

export default CreateUpdateNote;
