import React, { FC } from 'react';
import {
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  InfoOutlined,
  ManageAccountsOutlined,
  SmsOutlined,
} from '@mui/icons-material';
import * as R from 'ramda';
import i18n from 'i18next';
import { useNavigate } from 'react-router';
import DevicesIcon from '@mui/icons-material/Devices';
import { Language } from '@house-id/houseid-types/dist/common';
import {
  AnalyticsEvent,
  HIDEventCustomCategory,
} from '@house-id/houseid-types/dist/analytics';

import { useNavigateBackOr } from '../../../../utils/routes';
import useGetCurrentPropertyId from '../../../Property/hooks/useGetCurrentPropertyId';
import { useGetCurrentUserQuery } from '../../api/api.user';
import {
  getHomePath,
  getUserStoragePath,
} from '../../../Property/navigation/navigation.property';
import HomeLayout from '../../../Property/pages/Home/components/HomeLayout';
import HIDAvatar from '../../../../components/HIDAvatar';
import Unlock from '../../../../components/icons/Unlock';
import Crown from '../../../../components/icons/Crown';
import {
  HIDHome,
  Send,
  Upload,
} from '../../../../components/icons/Icons';
import HIDButton from '../../../../components/buttons/HIDButton';
import { getActiveAuth } from '../../../../external-services/firebase';
import { useGetSignOut } from '../../hooks/useGetSignOut';
import useBreakPointsSizes from '../../../../hooks/useBreakpointsSizes';
import FlagSV from '../../../../components/icons/FlagSV';
import FlagGB from '../../../../components/icons/FlagGB';
import { getCurrentLanguage } from '../../../../utils/locale';
import useDialog from '../../../../hooks/useDialog';
import DialogNames from '../../../../hooks/useDialog/DialogNames';
import {
  getAboutPath,
  getFeedbackOnboardingPath,
  getFeedbackPath,
  getResetPasswordOnboardingPath,
  getResetPasswordPath,
  getUserDetailsOnboardingPath,
  getUserDetailsPath,
} from '../../navigation/navigation.auth';
import { HOUSE_ID_INTEGRITY_POLICY_LINK } from '../../../../constants/links';
import { openUrlNewTab } from '../../../../utils/file';
import { openSendEmail } from '../../../../utils/email';
import useGetLogAnalyticsEvent from '../../../Analytics/hooks/useGetLogAnalyticsEvent';
import UserSettingsItem from './UserSettingsItem';
import { SettingsItem } from '../../types/types.settings';
import { getSubscriptionPlansPath } from '../../../SubscriptionPlans/navigation/navigation.subscriptionPlans';
import * as HouseIdMoveEvents from '../../../../utils/move';
import { PartnersSource } from '@house-id/houseid-types/dist/partners';

const isLast = (itemsLength: number, index: number) => index + 1 === itemsLength;

const UserSettings: FC = () => {
  const navigate = useNavigate();
  const navigateBackOr = useNavigateBackOr();
  const { t } = useTranslation(['home', 'common', 'settings', 'property']);
  const theme = useTheme();

  const logAnalyticsEvent = useGetLogAnalyticsEvent();

  const { isDownSm } = useBreakPointsSizes();

  const { data: propertyId } = useGetCurrentPropertyId();

  const { data: user } = useGetCurrentUserQuery();

  const [openConnectNewDeviceDialog] = useDialog(DialogNames.CONNECT_NEW_DEVICE_DIALOG);

  const handleGoBack = () => navigateBackOr(propertyId ? getHomePath({ propertyId }) : undefined);

  const changeLanguage = () => {
    const newLanguage = getCurrentLanguage() === Language.EN ? Language.SV : Language.EN;
    i18n.changeLanguage(newLanguage);
    HouseIdMoveEvents.changeLanguage(newLanguage);
  };

  const handleRecommendHouseID = () => {
    const mailSubject = t('settings:settings_house_id_recommendation_subject');
    const mailBody = t('settings:settings_house_id_recommendation');
    openSendEmail('', mailSubject, mailBody);
    logAnalyticsEvent({ event: AnalyticsEvent.RECOMMEND_FRIENDS, hidCategory: HIDEventCustomCategory.USER });
  };

  const handleReadMoreAboutHouseID = () => {
    logAnalyticsEvent({ event: AnalyticsEvent.ABOUT_HOUSE_ID_URL, hidCategory: HIDEventCustomCategory.USER });
    openUrlNewTab(getAboutPath());
  };

  const handleReadPolicyPrivacy = () => {
    openUrlNewTab(HOUSE_ID_INTEGRITY_POLICY_LINK);
    logAnalyticsEvent({ event: AnalyticsEvent.READ_POLICY_AND_INTEGRITY, hidCategory: HIDEventCustomCategory.DEFAULT });
  };

  const [signOut] = useGetSignOut(getActiveAuth());

  const [openDeleteAccountDialog] = useDialog(DialogNames.DELETE_ACCOUNT_DIALOG);

  const handleRemoveAccount = openDeleteAccountDialog;

  const handleGotoSubscriptionPlans = () => {
    logAnalyticsEvent({
      event: AnalyticsEvent.SUBSCRIPTION_USER_SETTINGS_GO_TO_PLANS,
      source: PartnersSource.HOUSE_ID,
      hidCategory: HIDEventCustomCategory.SUBSCRIPTION_PLAN,
    });
    navigate(getSubscriptionPlansPath());
  };

  const userSettingsItems: Array<SettingsItem> = [
    {
      label: t('settings:settings_my_details'),
      Icon: ManageAccountsOutlined,
      onClick: () => propertyId ? navigate(getUserDetailsPath({ propertyId })) : navigate(getUserDetailsOnboardingPath()),
    },
    {
      label: t('settings:settings_password'),
      Icon: Unlock,
      onClick: () => propertyId ? navigate(getResetPasswordPath({ propertyId })) : navigate(getResetPasswordOnboardingPath()),
    },
    {
      label: t('settings:settings_connect_new_device'),
      Icon: DevicesIcon,
      onClick: openConnectNewDeviceDialog,
    },
    {
      label: t('property:property_settings_storage_action'),
      Icon: Upload,
      onClick: () => navigate(getUserStoragePath()),
    },
    {
      label: t('settings:settings_account_type'),
      Icon: () => <Crown fillColor={theme.palette.common.white} />,
      onClick: handleGotoSubscriptionPlans,
    },
    {
      label: t('home:home_user_settings_menu_language_alternate_label'),
      Icon: getCurrentLanguage() === Language.EN ? FlagSV : FlagGB,
      onClick: changeLanguage,
    },
    {
      label: t('home:home_feedback'),
      Icon: () => <SmsOutlined sx={{ marginTop: 0.25 }} />,
      onClick: () => propertyId ? navigate(getFeedbackPath({ propertyId })) : navigate(getFeedbackOnboardingPath()),
    },
    {
      label: t('settings:settings_about_house_id'),
      Icon: HIDHome,
      onClick: handleReadMoreAboutHouseID,
    },
    {
      label: t('settings:settings_recommend_house_id'),
      Icon: Send,
      onClick: handleRecommendHouseID,
    },
    {
      label: t('settings:settings_terms_and_conditions'),
      Icon: InfoOutlined,
      onClick: handleReadPolicyPrivacy,
    },
  ];

  return (
    <HomeLayout
      SideColumn={<div />}
      title={t('home:home_user_settings_menu_user_settings_label')}
      onBack={handleGoBack}
    >
      <Stack direction="column">
        <Stack
          alignItems="center"
          spacing={0.5}
        >
          {Boolean(user) && (
            <HIDAvatar
              blob={R.head(user?.blobs || [])}
              givenName={user?.givenName}
              size="large"
              surname={user?.surname}
            />
          )}
          <Typography
            style={{ marginTop: theme.spacing(3) }}
            sx={{ textAlign: 'center' }}
            variant="h4"
          >
            {user?.name || ''}
          </Typography>
          <Typography
            sx={{
              color: theme.palette.grey[400],
              alignItems: 'center',
            }}
            variant="body1"
          >
            {user?.email}
          </Typography>
          <Typography
            sx={{
              color: theme.palette.grey[400],
              alignItems: 'center',
            }}
            variant="body1"
          >
            {user?.phoneNumber}
          </Typography>
        </Stack>
        <Stack>
          {
            userSettingsItems.map((item, index) => (
              <UserSettingsItem
                item={item}
                key={index}
                last={isLast(userSettingsItems.length, index)}
              />
            ))
          }
          <Stack
            direction={isDownSm ? 'column' : 'row'}
            justifyContent={isDownSm ? 'center' : 'flex-end'}
            spacing={1.5}
            sx={{ marginTop: 3 }}
          >
            <HIDButton
              color="red"
              onClick={handleRemoveAccount}
            >
              {t('settings:settings_remove_account')}
            </HIDButton>
            <HIDButton
              color="primary"
              onClick={signOut}
            >
              {t('settings:settings_logout')}
            </HIDButton>
          </Stack>
        </Stack>
      </Stack>
    </HomeLayout>
  );
};

export default UserSettings;
