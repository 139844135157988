import { useNavigate } from 'react-router-dom';

import { NavigationLink } from '../../../types/common';
import useGetEntityInfo from '../modules/Content/hooks/useGetEntityInfo';
import useGetCurrentPropertyId from './useGetCurrentPropertyId';
import { openUrlNewTab } from '../../../utils/file';
import useGetNavigationAction from './useGetNavigationAction';
import useDialog from '../../../hooks/useDialog';
import DialogNames from '../../../hooks/useDialog/DialogNames';
import { UnavailableFeature } from '../components/dialogs/OpenMobileAppDialog';

const useNavigateFromLink = (link?: NavigationLink) => {
  const navigate = useNavigate();
  const { data: propertyId } = useGetCurrentPropertyId();

  const getEntityInfo = useGetEntityInfo();
  const getNavigationAction = useGetNavigationAction();

  const [openMobileAppDialog] = useDialog(DialogNames.OPEN_MOBILE_APP_DIALOG);
  const [openExpertsCommunicationFormDialog] = useDialog(
    DialogNames.EXPERTS_COMMUNICATION_FORM_DIALOG,
  );

  const {
    actionId,
    params,
    url,
    form,
    // backward compatibility
    navigationData,
    // source,
    // affiliate,
    // modalData,
    _meta,
  } = link || {};

  // TODO: Add event logging when analytics is ready
  const navigateToScreenFromLink = () => {
    const navigationActionId = actionId || navigationData?.actionId;
    if (navigationActionId) {
      const navigationActionParams = params || navigationData?.params;
      const getActionPath = getNavigationAction(navigationActionId);
      if (getActionPath) {
        navigate(getActionPath(navigationActionParams));
      } else {
        openMobileAppDialog({
          unavailableFeature: navigationActionId as unknown as UnavailableFeature,
        });
      }

      return;
    }

    if (_meta) {
      const meta = _meta;
      const entityInfo = getEntityInfo(meta.entity.type);
      if (entityInfo?.getViewLink && propertyId) {
        navigate(entityInfo.getViewLink({ propertyId, id: meta.entity.id }));
      }
      return;
    }

    if (url) {
      openUrlNewTab(url);
    }

    if (form) {
      const { dynamicContentId, ...rest } = form || {};
      openExpertsCommunicationFormDialog({
        id: dynamicContentId,
        params: rest,
      });
    }
  };

  return navigateToScreenFromLink;
};

export default useNavigateFromLink;
