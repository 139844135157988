import { PropertyRealtorValuation } from '@house-id/houseid-types/dist/finances/finances';

export const getValuationDifferencePercent = (marketPrice?: number, purchasePrice?: number) => {
  if (marketPrice && purchasePrice) {
    const ratio = Math.abs(1 - marketPrice / purchasePrice);
    return marketPrice > purchasePrice ? ratio : ratio * -1;
  }

  return 0;
};

export const getValuationBrokerNameAndCompany = (realtorValuation?: PropertyRealtorValuation) => {
  const { brokerCompany, brokerName } = realtorValuation || {};
  const brokerText = brokerName && brokerCompany
    ? `${brokerName}, ${brokerCompany}`
    : brokerName || brokerCompany;

  return brokerText;
};
