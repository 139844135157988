import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { EntityType } from '@house-id/houseid-types/dist/entityType';
import { useAuthState } from 'react-firebase-hooks/auth';

import {
  PropertyFinancesValuationSectionData,
  PropertyProgressCategorySectionProps,
} from '../../../types.propertyProgress';
import PropertyProgressCategorySectionContainer from '../../../components/PropertyProgressCategorySectionContainer';
import PropertyProgressCategorySectionContent from '../../../components/PropertyProgressCategorySectionContent';
import ValuationWidget from '../../../../../pages/PropertyHome/components/ValuationWidget';
import { getValuationOverviewPath } from '../../../../Finances/modules/Valuation/navigation.valuation';
import { getPathWithPropertyIdOrInit } from '../../../../../../Auth/navigation/navigation.auth';
import useGetCurrentPropertyId from '../../../../../hooks/useGetCurrentPropertyId';
import useDialog from '../../../../../../../hooks/useDialog';
import DialogNames from '../../../../../../../hooks/useDialog/DialogNames';
import { isTestEnv } from '../../../../../../../utils/env';
import { getActiveAuth } from '../../../../../../../external-services/firebase';
import { useGetTokenDataQuery } from '../../../../../../Auth/api/api.user';

const PropertyFinancesValuationSection: FC<PropertyProgressCategorySectionProps<PropertyFinancesValuationSectionData>> = ({
  section,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation(['forms_common', 'property']);

  const { data: propertyId } = useGetCurrentPropertyId();

  const [openMobileAppDialog] = useDialog(DialogNames.OPEN_MOBILE_APP_DIALOG);

  const [currentUser] = useAuthState(getActiveAuth());
  const { data: tokenData } = useGetTokenDataQuery({}, { skip: !currentUser });

  const showValuation = isTestEnv || tokenData?.isAdmin;

  const handleNavigateIndicationValuation = () => showValuation
    ? navigate(getPathWithPropertyIdOrInit(getValuationOverviewPath, { propertyId }))
    : openMobileAppDialog({ unavailableFeature: EntityType.VALUATION });

  const handleAdd = () => showValuation
    ? navigate(getPathWithPropertyIdOrInit(getValuationOverviewPath, { propertyId, showIndicationValuationDialog: true }))
    : openMobileAppDialog({ unavailableFeature: EntityType.VALUATION });

  return (
    <PropertyProgressCategorySectionContainer
      Content={
        <PropertyProgressCategorySectionContent
          addButtonText={t('property:property_progress_property_valuation_section_add_valuation')}
          viewAllButtonText={t('property:property_progress_property_valuation_section_view_valuations')}
          onAddClick={handleAdd}
          onViewAllClick={handleNavigateIndicationValuation}
        >
          <ValuationWidget />
        </PropertyProgressCategorySectionContent>
      }
      key={section.id}
      section={section}
    />
  );
};

export default PropertyFinancesValuationSection;
