import { PropertyType } from '@house-id/houseid-types/dist/property';
import {
  FinanceInformation,
  FinanceSaleInformation,
} from '@house-id/houseid-types/dist/finances/finances';

import { HIDApiTags } from '../../../../../api/HIDApiTags';
import { PropertyId } from '../../../types/types.property';
import { propertyApi } from '../../../api/api.property';

export type PropertyFinanceSaleDeclarationFormData = {
  propertyType: PropertyType;
  year: number;
  salesPrice: number;
  salesExpenses: number;
  purchasePrice: number;
  otherDeductions: number;
  improvementExpenditures: number;
  winOrLose: number;
  profit: number;
  loss: number;
  taxToPay: number;
  deduction: number;
};

export const propertyFinanceApi = propertyApi.injectEndpoints({
  endpoints: (builder) => ({
    getPropertyFinanceInformation: builder.query<FinanceInformation, PropertyId>({
      query: ({ propertyId }) => `/properties/${propertyId}/finance/information`,
      providesTags: [HIDApiTags.PROPERTY_FINANCE_INFORMATION],
    }),
    getPropertyFinanceSaleSummary: builder.query<FinanceSaleInformation, PropertyId>({
      query: ({ propertyId }) => `/properties/${propertyId}/finance/sale/summary`,
      providesTags: [HIDApiTags.PROPERTY_FINANCE_SALE_INFORMATION],
    }),
  }),
});

export const {
  useGetPropertyFinanceInformationQuery,
  useGetPropertyFinanceSaleSummaryQuery,
} = propertyFinanceApi;
