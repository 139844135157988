import React, { FC } from 'react';
import {
  Stack,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import HIDSection from '../../../../../../../../../components/HIDSection';
import HomePrizes from '../../../../../../../../../components/icons/HomePrizes';
import HIDTypography from '../../../../../../../../../components/HIDTypography';
import HIDButton from '../../../../../../../../../components/buttons/HIDButton';
import useDialog from '../../../../../../../../../hooks/useDialog';
import DialogNames from '../../../../../../../../../hooks/useDialog/DialogNames';
import { RealtorValuationModalMode } from '../../../components/BrokerValuationDialog';
import { getPathWithPropertyIdOrInit } from '../../../../../../../../Auth/navigation/navigation.auth';
import { getBookValuationMeetingPath } from '../../../navigation.valuation';
import useGetCurrentPropertyId from '../../../../../../../hooks/useGetCurrentPropertyId';

const ValuationEmptyStateCard: FC = () => {
  const navigate = useNavigate();

  const theme = useTheme();
  const { t } = useTranslation(['finances']);

  const { data: propertyId } = useGetCurrentPropertyId();

  const handleSfBook = () => navigate(getPathWithPropertyIdOrInit(getBookValuationMeetingPath, { propertyId }));

  const [openBrokerVariationDialog] = useDialog(DialogNames.BROKER_VALUATION_DIALOG);

  const handleBookNew = () => openBrokerVariationDialog({
    mode: RealtorValuationModalMode.ACCURATE,
    onBook: handleSfBook,
  });

  return (
    <HIDSection>
      <Stack
        alignItems="center"
        direction="column"
        gap={2.5}
      >
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{
            p: 2,
            borderRadius: '50%',
            backgroundColor: theme.palette.primary.lighter,
          }}
        >
          <HomePrizes iconColor={theme.palette.primary.main} size={32} />
        </Stack>
        <HIDTypography sx={{ textAlign: 'center' }} variant="h5">
          {t('finances:do_you_want_to_make_a_more_accurate_valuation')}
        </HIDTypography>
        <HIDButton onClick={handleBookNew}>
          {t('finances:be_contacted_by_brokers')}
        </HIDButton>
      </Stack>
    </HIDSection>
  );
};

export default ValuationEmptyStateCard;
