import React, {
  FC,
  useEffect,
  useState,
} from 'react';
import {
  Card,
} from '@mui/material';
import {
  MailOutline,
  DraftsOutlined,
  CopyAllOutlined,
} from '@mui/icons-material';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import * as R from 'ramda';
import { enqueueSnackbar } from 'notistack';
import { EntityType } from '@house-id/houseid-types/dist/entityType';

import HomeLayout from '../../../../../pages/Home/components/HomeLayout';
import { useNavigateBackOr } from '../../../../../../../utils/routes';
import { getHomePath } from '../../../../../navigation/navigation.property';
import useGetCurrentPropertyId from '../../../../../hooks/useGetCurrentPropertyId';
import {
  useDeleteMessagesMutation,
  useLazyGetMessagesQuery,
  useMarkAsReadMutation,
  useMarkAsUnreadMutation,
} from '../api/api.message';
import MessageListItem from '../components/MessageListItem';
import ListEntitiesActions from '../../../components/actions/ListEntitiesActions';
import FiltersSection from '../../../components/sections/FiltersSection';
import ListEntitiesToolbarActions, { SelectionModeType } from '../../../components/actions/ListEntitiesToolbarActions';
import InfiniteScroll from '../../../../../../../components/InfiniteScroll';
import useLock from '../../../../../../../hooks/useLock';
import {
  Message,
  MessageReadStatus,
} from '../types.message';
import { getMessagePath } from '../navigation.message';
import { createGuid } from '../../../../../../../utils/uuid';
import { getPathWithPropertyIdOrInit } from '../../../../../../Auth/navigation/navigation.auth';
import MessagesLoaderSkeleton from '../components/MessagesLoaderSkeleton';
import useSearch from '../../../../../../../hooks/useSearch';
import { copyToClipboard } from '../../../../../../../utils/clipboard';
import { useLazyGetPropertyInboundEmailQuery } from '../../../../../api/api.property';
import { ContentFilters } from '../../../types/types.content';

const PAGE_SIZE = 20;

const Messages: FC = () => {
  const navigate = useNavigate();
  const navigateBackOr = useNavigateBackOr();
  const { t } = useTranslation(['messages', 'common', 'entities']);

  const { displaySearch } = useSearch(EntityType.MESSAGE);

  const { data: propertyId } = useGetCurrentPropertyId();

  const [selectionModeType, setSelectionModeType] = useState<SelectionModeType | undefined>();
  const [selectedMessageIds, setSelectedMessageIds] = useState<Array<string>>([]);

  const isSelectionMode = Boolean(selectionModeType);

  const handleGoBack = () => navigateBackOr(getPathWithPropertyIdOrInit(getHomePath, { propertyId }));

  const [getMessages, { isLoading, isFetching }] = useLazyGetMessagesQuery();

  const [deleteMessages, { isLoading: isDeleting }] = useDeleteMessagesMutation();
  const [markAsRead, { isLoading: isMarkingAsRead }] = useMarkAsReadMutation();
  const [markAsUnread, { isLoading: isMarkingAsUnread }] = useMarkAsUnreadMutation();

  const isItemsLoading = isLoading || isFetching || isDeleting || isMarkingAsRead || isMarkingAsUnread;

  const [filters, setFilters] = useState<ContentFilters>({
    from: undefined,
    to: undefined,
    columnsVisibilityMap: {
      readStatus: { label: t('messages:messages_unread_message'), visible: false },
    },
  });

  const showUnreadOnly = Boolean(filters.columnsVisibilityMap?.readStatus?.visible);

  const defaultMessagesState = {
    messages: [],
    fetchedCount: 0,
    totalCount: 0,
    unreadCount: 0,
  };

  const [messagesState, setMessagesState] = useState<{
    messages: Array<Message>;
    fetchedCount: number;
    totalCount?: number;
    unreadCount?: number;
  }>(defaultMessagesState);

  const { locked: fetchLocked, lock: lockFetch } = useLock(messagesState.fetchedCount);

  const handleFetch = ({ resetState = false, offset: offsetParam = 0 }) => {
    const offset = offsetParam;

    if (isItemsLoading || (fetchLocked && !resetState) || !propertyId) {
      return;
    }

    lockFetch();

    getMessages({
      propertyId,
      from: filters.from,
      to: filters.to,
      readStatus: showUnreadOnly ? MessageReadStatus.UNREAD : undefined,
      pageSize: PAGE_SIZE,
      offset,
      key: resetState ? createGuid() : undefined,
    })
      .unwrap()
      .then((response) => {
        setMessagesState(
          (prevState) => {
            const messages = resetState ? [] : prevState.messages || [];
            const totalCount = resetState ? 0 : prevState.totalCount;
            const fetchedCount = resetState ? 0 : prevState.fetchedCount;
            const unreadCount = resetState ? 0 : prevState.unreadCount;

            return {
              messages: R.concat(messages, response.messages),
              fetchedCount: fetchedCount + response.messages.length,
              totalCount: totalCount || response.totalCount || 0,
              unreadCount: unreadCount || response.unreadCount || 0,
            };
          },
        );
      });
  };

  const hasMore = showUnreadOnly
    ? messagesState.unreadCount === undefined || messagesState.messages.length < messagesState.unreadCount
    : messagesState.totalCount === undefined || messagesState.messages.length < messagesState.totalCount;

  useEffect(
    () => {
      setMessagesState(defaultMessagesState);
      handleFetch({ offset: 0 });
    },
    [
      filters.from,
      filters.to,
      filters.columnsVisibilityMap?.readStatus?.visible,
    ],
  );

  const count = messagesState.totalCount || 0;
  const countLabel = isFetching
    ? ' '
    : count > 1
      ? `${count} ${t(`entities:${EntityType.MESSAGE}_plural`)}, ${messagesState.unreadCount} ${t('messages:messages_unread')}`
      : `${count} ${t(`entities:${EntityType.MESSAGE}`)}, ${messagesState.unreadCount} ${t('messages:messages_unread')}`;

  const handleEnterDeleteMode = () => {
    setSelectionModeType(SelectionModeType.DELETE);
    setSelectedMessageIds([]);
  };

  const handleEnterReadMode = () => {
    setSelectionModeType(SelectionModeType.READ);
    setSelectedMessageIds([]);
  };

  const handleEnterUnreadMode = () => {
    setSelectionModeType(SelectionModeType.UNREAD);
    setSelectedMessageIds([]);
  };

  const [getPropertyInboundEmail] = useLazyGetPropertyInboundEmailQuery();
  const handleCopyEmail = () => {
    if (propertyId) {
      getPropertyInboundEmail({ propertyId }, true)
        .unwrap()
        .then((inboundAddress) => {
          const { inboundEmail } = inboundAddress;
          if (inboundEmail) {
            copyToClipboard(inboundEmail);
            enqueueSnackbar(t('messages:messages_copied_email_address_to_clipboard'), {
              variant: 'info',
            });
          }
        });
    }
  };

  const handleClearSelectionMode = () => {
    setSelectionModeType(undefined);
    setSelectedMessageIds([]);
  };

  const handleDelete = () => {
    handleClearSelectionMode();
    deleteMessages({ ids: selectedMessageIds, skipInvalidate: true })
      .then(() => handleFetch({ resetState: true }));
  };

  const handleClick = (id: string, isSelected: boolean) => {
    if (isSelectionMode) {
      if (isSelected) {
        setSelectedMessageIds(R.without([id], selectedMessageIds));
      } else {
        setSelectedMessageIds([...selectedMessageIds, id]);
      }
    } else {
      navigate(getPathWithPropertyIdOrInit(getMessagePath, { propertyId, id }));
    }
  };

  const handleMarkAsRead = (ids: Array<string>) => {
    handleClearSelectionMode();
    markAsRead({ ids, skipInvalidate: true })
      .then(() => handleFetch({ resetState: true }));
  };

  const handleMarkAsUnread = (ids: Array<string>) => {
    handleClearSelectionMode();
    markAsUnread({ ids, skipInvalidate: true })
      .then(() => handleFetch({ resetState: true }));
  };

  const ReadActions = [{
    id: 'markAsRead',
    mode: SelectionModeType.READ,
    label: t('messages:messages_mark_as_read'),
    onAction: () => handleMarkAsRead(selectedMessageIds),
  }];

  const UnreadActions = [{
    id: 'markAsUnread',
    mode: SelectionModeType.READ,
    label: t('messages:messages_mark_as_unread'),
    onAction: () => handleMarkAsUnread(selectedMessageIds),
  }];

  const customActions = [
    {
      id: 'markAsRead',
      label: t('messages:messages_mark_as_read'),
      Icon: DraftsOutlined,
      disabled: isSelectionMode,
      onClick: handleEnterReadMode,
    },
    {
      id: 'markAsUnread',
      label: t('messages:messages_mark_as_unread'),
      Icon: MailOutline,
      disabled: isSelectionMode,
      onClick: handleEnterUnreadMode,
    },
    {
      id: 'copyEmail',
      label: t('messages:messages_copy_email_address'),
      Icon: CopyAllOutlined,
      disabled: isSelectionMode,
      onClick: handleCopyEmail,
    },
  ];

  return (
    <HomeLayout
      SideColumn={
        <>
          <Card sx={{ padding: 2 }}>
            <ListEntitiesActions
              customActions={customActions}
              disabled={isSelectionMode}
              onDelete={handleEnterDeleteMode}
              onSearch={displaySearch}
            />
          </Card>
          {!isSelectionMode && (
            <Card sx={{ padding: 2 }}>
              <FiltersSection
                showColumnsVisibilityFilters
                showDateFilers
                filters={filters}
                showColumnsSectionLabel={false}
                onChange={setFilters}
              />
            </Card>
          )}
        </>
      }
      Toolbar={
        <ListEntitiesToolbarActions
          count={messagesState.totalCount}
          countLabel={countLabel}
          customActions={{
            [SelectionModeType.READ]: ReadActions,
            [SelectionModeType.UNREAD]: UnreadActions,
          }}
          entity={EntityType.MESSAGE}
          isFetching={isFetching}
          isLoading={isItemsLoading}
          selectedCount={selectedMessageIds.length}
          selectionModeType={selectionModeType}
          sx={{ marginBottom: 2 }}
          onCancel={handleClearSelectionMode}
          onDelete={handleDelete}
        />
      }
      sideDrawerElements={[
        <ListEntitiesActions
          customActions={customActions}
          disabled={isSelectionMode}
          key={ListEntitiesActions.name}
          onDelete={handleEnterDeleteMode}
          onSearch={displaySearch}
        />,
        !isSelectionMode && (
          <FiltersSection
            showColumnsVisibilityFilters
            showDateFilers
            filters={filters}
            key={FiltersSection.name}
            showColumnsSectionLabel={false}
            onChange={setFilters}
          />
        ),
      ].filter(Boolean)}
      title={t('messages:messages_my_messages')}
      onBack={handleGoBack}
    >
      <InfiniteScroll
        hasMore={hasMore}
        isFetching={isFetching}
        onLoadMore={() => handleFetch({ offset: messagesState.messages.length })}
      >
        <MessagesLoaderSkeleton isLoading={isLoading}>
          {
            messagesState.messages.map((message) => (
              <MessageListItem
                isSelectable={Boolean(selectionModeType)}
                isSelected={selectedMessageIds.includes(message.id)}
                key={message.id}
                message={message}
                onClick={(id, isSelected) => handleClick(id, isSelected)}
              />
            ))
          }
        </MessagesLoaderSkeleton>
      </InfiniteScroll>
    </HomeLayout>
  );
};

export default React.memo(Messages);
