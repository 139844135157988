import React, {
  useEffect,
  useState,
} from 'react';
import {
  Collapse,
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  useTheme,
} from '@mui/material';
import {
  CheckBox,
  CheckBoxOutlineBlank,
  ExpandLess,
  ExpandMore,
} from '@mui/icons-material';
import { skipToken } from '@reduxjs/toolkit/query';
import { useTranslation } from 'react-i18next';
import * as R from 'ramda';

import useGetCurrentPropertyId from '../../../../hooks/useGetCurrentPropertyId';
import HIDTypography from '../../../../../../components/HIDTypography';
import ContactsIcon from '../../../../../../components/icons/ContactsIcon';
import { FCC } from '../../../../../../types/common';
import { useGetBoughtByQuery } from '../../api/api.content';

const LIST_ITEM_HEIGHT = 48;
const LIST_MAX_HEIGHT = 8 * LIST_ITEM_HEIGHT + 2;

const EntitiesLoaderItem = (
  <ListItemButton>
    <ListItemText>
      <HIDTypography isLoading skeletonWidth="100%" />
    </ListItemText>
  </ListItemButton>
);

const EntitiesLoader = (
  <>
    {EntitiesLoaderItem}
    {EntitiesLoaderItem}
    {EntitiesLoaderItem}
  </>
);

type UserFilterSectionProps = {
  selectedNames: Array<string>;
  isNoneSelected?: boolean;
  onChange: (selectedUsers: Array<string>, isNoneSelected: boolean) => void;
};

const PurchasedByUserFilterSection: FCC<UserFilterSectionProps> = ({
  selectedNames: selectedNamesProp,
  isNoneSelected: isNoneSelectedProp = false,
  style,
  onChange,
}) => {
  const { t } = useTranslation(['common', 'receipts']);
  const theme = useTheme();

  const [isExpanded, setIsExpanded] = useState(false);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [isNoneSelected, setIsNoneSelected] = useState(isNoneSelectedProp);
  const [selectedNames, setSelectedNames] = useState<Array<string>>(selectedNamesProp);

  useEffect(() => {
    onChange(selectedNames, isNoneSelected);
  }, [isNoneSelected, selectedNames]);

  const { data: propertyId } = useGetCurrentPropertyId();
  const { data: boughtBy = [], isLoading } = useGetBoughtByQuery(propertyId ? { propertyId } : skipToken);

  const namesList = boughtBy;

  const handleUserSelected = (name: string) => {
    const newSelectedNames = selectedNames.includes(name)
      ? R.without([name], selectedNames)
      : [...selectedNames, name];

    setSelectedNames(newSelectedNames);
    setIsAllSelected(newSelectedNames.length === namesList.length && isNoneSelected);
  };

  const handleAllUserSelected = () => {
    setIsAllSelected(!isAllSelected);
    setIsNoneSelected(!isAllSelected);
    setSelectedNames(isAllSelected ? [] : namesList);
  };

  const handleOtherSelected = () => {
    const newIsNoneSelected = !isNoneSelected;
    setIsNoneSelected(newIsNoneSelected);
    setIsAllSelected(selectedNames.length === namesList.length && newIsNoneSelected);
  };

  return (
    <List style={style}>
      <>
        <ListItemButton onClick={() => setIsExpanded(!isExpanded)}>
          <ListItemIcon>
            <ContactsIcon />
          </ListItemIcon>
          <ListItemText primary={t('receipts:receipts_purchased_by')} />
          {isExpanded ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse unmountOnExit in={isExpanded} timeout="auto">
          <Divider />
          <List
            disablePadding
            component="div"
            style={{
              maxHeight: LIST_MAX_HEIGHT,
              overflowY: 'scroll',
            }}
          >
            {
              isLoading
                ? EntitiesLoader
                : (
                  <>
                    <ListItemButton sx={{ pl: 4 }} onClick={() => handleAllUserSelected()}>
                      <ListItemText primary={t('common:all_label')} />
                      {
                        isAllSelected
                          ? <CheckBox sx={{ color: theme.palette.primary.main }} />
                          : <CheckBoxOutlineBlank />
                      }
                    </ListItemButton>
                    <ListItemButton sx={{ pl: 4 }} onClick={handleOtherSelected}>
                      <ListItemText primary={t('receipts:receipts_bought_by_optional')} />
                      {
                        isNoneSelected
                          ? <CheckBox sx={{ color: theme.palette.primary.main }} />
                          : <CheckBoxOutlineBlank />
                      }
                    </ListItemButton>
                    {(namesList || [])
                      .map((name) => (
                        <ListItemButton
                          key={name}
                          sx={{ pl: 4 }}
                          onClick={() => handleUserSelected(name)}
                        >
                          <ListItemText
                            primary={name}
                            primaryTypographyProps={{ noWrap: true, paddingRight: 2 }}
                          />
                          {
                            selectedNames.includes(name)
                              ? <CheckBox sx={{ color: theme.palette.primary.main }} />
                              : <CheckBoxOutlineBlank />
                          }
                        </ListItemButton>
                      ))}
                  </>
                )
            }
          </List>
          <Divider />
        </Collapse>
      </>
    </List>
  );
};

export default PurchasedByUserFilterSection;
