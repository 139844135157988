import { HIDEntityId } from '@house-id/houseid-types/dist/common';

import RouteNames from '../../../../../../routes/RouteNames';
import { generateRoutePath } from '../../../../../../utils/routes';
import { PropertyIdNavigation } from '../../../../types/types.property';

export const getTimelinePath = ({ propertyId }: PropertyIdNavigation) => generateRoutePath(
  RouteNames.TIMELINE,
  { propertyId },
);

type GetTimelineEventPath = PropertyIdNavigation & HIDEntityId;
export const getTimelineEventPath = ({ propertyId, id }: GetTimelineEventPath) => generateRoutePath(
  RouteNames.TIMELINE_EVENT,
  { propertyId, id },
);

export const getCreateTimelineEventPath = ({ propertyId }: PropertyIdNavigation) => generateRoutePath(
  RouteNames.TIMELINE_CREATE_EVENT,
  { propertyId },
);

type GetUpdateTimelineEventPath = PropertyIdNavigation & HIDEntityId;
export const getUpdateTimelineEventPath = ({ propertyId, id }: GetUpdateTimelineEventPath) => generateRoutePath(
  RouteNames.TIMELINE_UPDATE_EVENT,
  { propertyId, id },
);
