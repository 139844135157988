import React from 'react';
import {
  Link,
  LinkProps,
  Stack,
  useTheme,
} from '@mui/material';
import { match } from 'ts-pattern';
import { Link as RouterLink } from 'react-router-dom';

import { FCC } from '../types/common';

type HIDLinkProps = {
  color?: 'primary' | 'secondary' | 'grey';
  disabled?: boolean;
  href?: LinkProps['href'];
  replace?: boolean;
  linkColor?: LinkProps['color'];
  target?: LinkProps['target'];
  label: string;
  noWrap?: LinkProps['noWrap'];
  Icon?: React.ElementType;
  underline?: LinkProps['underline'];
  variant?: LinkProps['variant'];
  labelStyle?: React.CSSProperties;
  onClick?: LinkProps['onClick']
};

const HIDLink: FCC<HIDLinkProps> = ({
  color = 'primary',
  disabled = false,
  href,
  replace,
  linkColor,
  target,
  label,
  noWrap = true,
  Icon,
  underline,
  variant,
  labelStyle,
  sx,
  style,
  onClick,
}) => {
  const theme = useTheme();

  const primarySx = { color: theme.palette.primary.main };

  const secondarySx = {
    color: theme.palette.common.black,
    '&:hover': {
      backgroundColor: theme.palette.grey[300],
    },
    '&:active': {
      backgroundColor: theme.palette.grey[200],
    },
  };

  const greySx = {
    color: theme.palette.common.black,
    underline: 'none',
    '&:hover': {
      color: theme.palette.grey[500],
    },
    ':before, :after': {
      borderBottomColor: theme.palette.common.black,
    },
  };

  const colorSx = match(color)
    .with('primary', () => primarySx)
    .with('secondary', () => secondarySx)
    .with('grey', () => greySx)
    .exhaustive();

  return (
    <Link
      color={linkColor}
      component={href ? RouterLink : 'button'}
      disabled={disabled}
      noWrap={noWrap}
      replace={replace}
      style={style}
      sx={{
        ...colorSx,
        ...sx,
      }}
      target={target}
      to={href as string}
      underline={underline}
      variant={variant}
      onClick={onClick}
    >
      {
        Icon
          ? (
            <Stack direction="row" spacing={0.5}>
              {label}
              <Icon />
            </Stack>
          )
          : labelStyle
            ? <span style={labelStyle}>{label}</span>
            : label
      }
    </Link>
  );
};

export default HIDLink;
