import React, { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import {
  createBrowserRouter,
  RouterProvider,
} from 'react-router-dom';

import ROUTES_CONFIG from './routes/RoutesConfig';
import HIDFullScreenLoader from './components/HIDFullScreenLoader';
import HIDSnackBarProvider from './components/snackbar/HIDSnackbarProvider';
import useFirebaseSyncActionsInitializer from './hooks/useFirebaseSyncActionsInitializer';
import { useGetActiveAuthUser } from './external-services/firebase';
import {
  useGetTokenDataQuery,
  useLazyGetCurrentUserQuery,
} from './modules/Auth/api/api.user';
import { setExternalServicesUserData } from './external-services';

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);
const router = sentryCreateBrowserRouter(ROUTES_CONFIG);

const App = () => {
  useFirebaseSyncActionsInitializer();

  const { user: currentUser, isLoading: currentUserIsLoading } = useGetActiveAuthUser();
  const { data: tokenData } = useGetTokenDataQuery({}, { skip: currentUserIsLoading || !currentUser });
  const [getCurrentUser] = useLazyGetCurrentUserQuery();

  useEffect(() => {
    if (currentUser && tokenData) {
      if (tokenData.userId) {
        getCurrentUser()
          .unwrap()
          .then((user) => {
            if (user) {
              setExternalServicesUserData(tokenData, user);
            }
          });
      }
    }
  }, [currentUser, tokenData?.userId]);

  return (
    <HIDSnackBarProvider>
      <RouterProvider
        fallbackElement={<HIDFullScreenLoader />}
        router={router}
      />
    </HIDSnackBarProvider>
  );
};
export default App;
