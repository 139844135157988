import React, { FC } from 'react';

import { TrendChartProps } from '../../types.valuation';
import ScalableSvgChartContainer from './ScalableSvgChartContainer';

const TrendChartMaxPositive: FC<TrendChartProps> = ({
  width = 608,
  height = 128,
  strokeWidth = 2,
  stopOpacity = 0.25,
}) => (
  <ScalableSvgChartContainer
    Svg={({ x, y }) => (
      <>
        <defs>
          <linearGradient
            id="linear_gradient_max_positive"
            x1="1"
            x2="1"
            y1="0.26965"
            y2="0.92453"
          >
            <stop stopColor="#DBF5ED" />
            <stop offset="1" stopColor="#E3F7F1" stopOpacity={stopOpacity} />
          </linearGradient>
        </defs>
        <path
          d={`
            m ${4.9 * x} ${76.1 * y}
            l ${-5.3 * x} ${4.1 * y}
            l 0 ${47.3 * y}
            l ${608.8 * x} 0
            l 0 ${-126.5 * y}
            l ${-5.3 * x} ${1.2 * y}
            c ${-5.3 * x} ${1.2 * y} ${-16.0 * x} ${3.6 * y} ${-26.7 * x} ${4.2 * y}
            c ${-10.7 * x} ${0.7 * y} ${-21.4 * x} ${-0.4 * y} ${-32.0 * x} ${2.3 * y}
            c ${-10.7 * x} ${2.7 * y} ${-21.4 * x} ${9.0 * y} ${-32.0 * x} ${10.0 * y}
            c ${-10.7 * x} ${1.0 * y} ${-21.4 * x} ${-3.5 * y} ${-32.0 * x} ${-4.7 * y}
            c ${-10.7 * x} ${-1.2 * y} ${-21.4 * x} ${0.8 * y} ${-32.0 * x} ${2.5 * y}
            c ${-10.7 * x} ${1.8 * y} ${-21.4 * x} ${3.4 * y} ${-32.0 * x} ${4.9 * y}
            c ${-10.7 * x} ${1.5 * y} ${-21.4 * x} ${2.9 * y} ${-32.0 * x} ${6.9 * y}
            c ${-10.7 * x} ${4.0 * y} ${-21.4 * x} ${10.5 * y} ${-32.0 * x} ${8.8 * y}
            c ${-10.7 * x} ${-1.7 * y} ${-21.4 * x} ${-11.8 * y} ${-32.0 * x} ${-11.5 * y}
            c ${-10.7 * x} ${0.3 * y} ${-21.4 * x} ${11.0 * y} ${-32.0 * x} ${19.2 * y}
            c ${-10.7 * x} ${8.2 * y} ${-21.4 * x} ${13.8 * y} ${-32.0 * x} ${12.8 * y}
            c ${-10.7 * x} ${-0.9 * y} ${-21.4 * x} ${-8.4 * y} ${-32.0 * x} ${-9.6 * y}
            c ${-10.7 * x} ${-1.2 * y} ${-21.4 * x} ${3.9 * y} ${-32.0 * x} ${4.4 * y}
            c ${-10.7 * x} ${0.6 * y} ${-21.4 * x} ${-3.3 * y} ${-32.0 * x} ${-6.0 * y}
            c ${-10.7 * x} ${-2.7 * y} ${-21.4 * x} ${-4.0 * y} ${-32.0 * x} ${2.1 * y}
            c ${-10.7 * x} ${6.1 * y} ${-21.4 * x} ${19.7 * y} ${-32.0 * x} ${22.5 * y}
            c ${-10.7 * x} ${2.8 * y} ${-21.4 * x} ${-5.3 * y} ${-32.0 * x} ${-9.6 * y}
            c ${-10.7 * x} ${-4.4 * y} ${-21.4 * x} ${-5.1 * y} ${-32.0 * x} ${-1.3 * y}
            c ${-10.7 * x} ${3.8 * y} ${-21.4 * x} ${12.0 * y} ${-26.7 * x} ${16.1 * y}
          `}
          fill="url(#linear_gradient_max_positive)"
        />
        <path
          d={`
            m ${-0.5 * x} ${80.2 * y}
            l ${5.3 * x} ${-4.1 * y}
            c ${5.3 * x} ${-4.1 * y} ${16.0 * x} ${-12.4 * y} ${26.7 * x} ${-16.1 * y}
            c ${10.7 * x} ${-3.8 * y} ${21.4 * x} ${-3.1 * y} ${32.0 * x} ${1.3 * y}
            c ${10.7 * x} ${4.4 * y} ${21.4 * x} ${12.4 * y} ${32.0 * x} ${9.6 * y}
            c ${10.7 * x} ${-2.8 * y} ${21.4 * x} ${-16.4 * y} ${32.0 * x} ${-22.5 * y}
            c ${10.7 * x} ${-6.1 * y} ${21.4 * x} ${-4.7 * y} ${32.0 * x} ${-2.1 * y}
            c ${10.7 * x} ${2.7 * y} ${21.4 * x} ${6.6 * y} ${32.0 * x} ${6.0 * y}
            c ${10.7 * x} ${-0.6 * y} ${21.4 * x} ${-5.6 * y} ${32.0 * x} ${-4.4 * y}
            c ${10.7 * x} ${1.2 * y} ${21.4 * x} ${8.7 * y} ${32.0 * x} ${9.6 * y}
            c ${10.7 * x} ${0.9 * y} ${21.4 * x} ${-4.7 * y} ${32.0 * x} ${-12.8 * y}
            c ${10.7 * x} ${-8.2 * y} ${21.4 * x} ${-18.9 * y} ${32.0 * x} ${-19.2 * y}
            c ${10.7 * x} ${-0.3 * y} ${21.4 * x} ${9.7 * y} ${32.0 * x} ${11.5 * y}
            c ${10.7 * x} ${1.7 * y} ${21.4 * x} ${-4.8 * y} ${32.0 * x} ${-8.8 * y}
            c ${10.7 * x} ${-4.0 * y} ${21.4 * x} ${-5.4 * y} ${32.0 * x} ${-6.9 * y}
            c ${10.7 * x} ${-1.5 * y} ${21.4 * x} ${-3.1 * y} ${32.0 * x} ${-4.9 * y}
            c ${10.7 * x} ${-1.8 * y} ${21.4 * x} ${-3.8 * y} ${32.0 * x} ${-2.5 * y}
            c ${10.7 * x} ${1.2 * y} ${21.4 * x} ${5.7 * y} ${32.0 * x} ${4.7 * y}
            c ${10.7 * x} ${-1.0 * y} ${21.4 * x} ${-7.3 * y} ${32.0 * x} ${-10.0 * y}
            c ${10.7 * x} ${-2.7 * y} ${21.4 * x} ${-1.6 * y} ${32.0 * x} ${-2.3 * y}
            c ${10.7 * x} ${-0.7 * y} ${21.4 * x} ${-3.0 * y} ${26.7 * x} ${-4.2 * y}
            l ${5.3 * x} ${-1.2 * y}
          `}
          stroke="#289595"
          strokeWidth={strokeWidth}
        />
      </>
    )}
    height={height}
    width={width}
  />
);

export default TrendChartMaxPositive;
