import React, {
  FC,
} from 'react';
import {
  Card,
  Stack,
  useTheme,
} from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/query';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import { EntityType } from '@house-id/houseid-types/dist/entityType';
import {
  AnalyticsEvent,
} from '@house-id/houseid-types/dist/analytics';

import {
  useNavigateBackOr,
  useRouteParams,
} from '../../../../../../utils/routes';
import useGetCurrentPropertyId from '../../../../hooks/useGetCurrentPropertyId';
import { getHomePath } from '../../../../navigation/navigation.property';
import HomeLayout from '../../../../pages/Home/components/HomeLayout';
import { getPathWithPropertyIdOrInit } from '../../../../../Auth/navigation/navigation.auth';
import BonusOffersQuickNavigation from '../../components/BonusOffersQuickNavigation';
import { useGetBonusOfferQuery } from '../../api/api.bonusOffers';
import BonusOfferCardBadge from '../../components/BonusOfferCardBadge';
import MyBonusPoints from '../../components/MyBonusPoints';
import {
  getBonusOffersPath,
  getMoveInBonusOffersPath,
} from '../../navigation.bonusOffers';
import {
  BonusOffersCategoryId,
} from '../../types.bonusOffers';
import { HIDBreadcrumbLink } from '../../../../../../components/HIDBreadcrumbs';
import BonusOfferActivationBox from './components/BonusOfferActivationBox';
import HIDTag from '../../../../../../components/HIDTag';
import HIDLink from '../../../../../../components/HIDLink';
import { useGetPropertyStatusQuery } from '../../../../api/api.property';
import BonusOfferCardImage from '../../components/BonusOfferCardImage';
import HIDTypography from '../../../../../../components/HIDTypography';
import BonusOfferDiscountCardBadge from '../../components/BonusOfferDiscountCardBadge';
import BonusOffersPageHeader from '../../components/BonusOffersPageHeader';
import useGetOpenUrlWithAnalytics from '../../../../../Analytics/hooks/useGetOpenUrlWithAnalytics';
import { PartnersSource } from '@house-id/houseid-types/dist/partners';

const BonusOfferDetails: FC = () => {
  const theme = useTheme();
  const { t } = useTranslation(['bonus_offers', 'common']);

  const { bonusOfferId, categoryId } = useRouteParams<{ bonusOfferId?: string; categoryId?: BonusOffersCategoryId; }>();

  const navigateBackOr = useNavigateBackOr();

  const { data: propertyId } = useGetCurrentPropertyId();

  const {
    data: bonusOffer,
    isLoading: isLoadingAvailableBonusOffer,
  } = useGetBonusOfferQuery(propertyId && bonusOfferId ? { propertyId, bonusOfferId } : skipToken);

  const {
    id,
    imageUrl,
    points,
    discount,
    name,
    description,
    tags,
    links,
    action,
  } = bonusOffer || {};

  const {
    data: propertyStatus,
    isLoading: isLoadingPropertyStatus,
  } = useGetPropertyStatusQuery(propertyId ? { propertyId } : skipToken);
  const availablePoints = propertyStatus?.bonusPoints?.available || 0;

  const BreadcrumbsLinksMap: Record<BonusOffersCategoryId, HIDBreadcrumbLink> = {
    [BonusOffersCategoryId.MOVE_IN_SERVICES]: {
      link: getPathWithPropertyIdOrInit(getMoveInBonusOffersPath, { propertyId }),
      name: t('bonus_offers:bonus_offers_offers_from_move_in'),
    },
    [BonusOffersCategoryId.HOUSE_ID]: {
      link: getPathWithPropertyIdOrInit(getBonusOffersPath, { propertyId }),
      name: t('bonus_offers:bonus_offers_bonus_offers'),
    },
  };

  const currentBreadcrumbsLink = categoryId ? BreadcrumbsLinksMap[categoryId] : undefined;

  const isLoading = isLoadingAvailableBonusOffer || isLoadingPropertyStatus;

  const openUrlWithAnalytics = useGetOpenUrlWithAnalytics();
  const handleOpenUrl = (url: string) => {
    openUrlWithAnalytics({
      url,
      event: AnalyticsEvent.OPEN_OFFER_LINK,
      source: action?.source || PartnersSource.HOUSE_ID,
      hidCategory: EntityType.BONUS_OFFER,
      params: { bonusOfferId: id },
    });
  };

  return (
    <HomeLayout
      Header={({ ManageButton }) => (
        <BonusOffersPageHeader
          ManageButton={ManageButton}
          title={name}
        >
          {categoryId === BonusOffersCategoryId.MOVE_IN_SERVICES ? null : <MyBonusPoints />}
        </BonusOffersPageHeader>
      )}
      SideColumn={
        <Card sx={{ padding: 2 }}>
          <BonusOffersQuickNavigation
            key={BonusOffersQuickNavigation.name}
          />
        </Card>
      }
      breadcrumbsLinks={currentBreadcrumbsLink ? [currentBreadcrumbsLink] : undefined}
      isLoading={isLoading}
      sideDrawerElements={[
        <BonusOffersQuickNavigation
          key={BonusOffersQuickNavigation.name}
        />,
      ]}
      title={name}
      onBack={() => navigateBackOr(getPathWithPropertyIdOrInit(getHomePath, { propertyId }))}
    >
      <Stack spacing={2}>
        <BonusOfferCardImage
          imageUrl={imageUrl}
          maxHeight="364px"
        />
        {Boolean(points || discount) && (
          <Stack direction="row" spacing={1}>
            {Boolean(points) && (
              <BonusOfferCardBadge variant="green">
                {t('common:points', { points })}
              </BonusOfferCardBadge>
            )}
            {Boolean(discount) && (
              <BonusOfferDiscountCardBadge discount={discount} />
            )}
          </Stack>
        )}
        <HIDTypography fontWeight="bold" isLoading={isLoading} variant="body1">
          {name}
        </HIDTypography>
        <HIDTypography
          component="span"
          isLoading={isLoading}
          numberOfSkeletonLines={10}
          variant="body1"
        >
          <ReactMarkdown skipHtml>
            {description}
          </ReactMarkdown>
        </HIDTypography>
        {tags && Boolean(tags.length) && (
          <Stack direction="row" spacing={1.5}>
            {tags.map((tag: { key: string, label: string }) => (
              <HIDTag color={theme.palette.warning.light} key={tag.key} label={tag.label.trim()} />
            ))}
          </Stack>
        )}
        {links && Boolean(links.length) && (
          <Stack spacing={1.5}>
            {links.map((link) => (
              <HIDLink
                key={link.url}
                label={link.label}
                onClick={() => handleOpenUrl(link.url)}
              />
            ))}
          </Stack>
        )}
        {bonusOffer && Boolean(bonusOfferId && propertyId) && (
          <BonusOfferActivationBox
            availablePoints={availablePoints}
            isLoading={isLoading}
            offer={bonusOffer}
            propertyId={propertyId as string}
          />
        )}
      </Stack>
    </HomeLayout>
  );
};

export default BonusOfferDetails;
