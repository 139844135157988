import React, {
  FC,
  useEffect,
  useState,
} from 'react';
import {
  Stack,
  debounce,
  styled,
  useTheme,
} from '@mui/material';
import {
  DataGrid,
  DataGridProps,
  GridColDef,
  GridEventListener,
  GridInputRowSelectionModel,
  GridRowParams,
  GridRowSelectionModel,
  GridSlotsComponentsProps,
  GridValidRowModel,
} from '@mui/x-data-grid';
import { GridInitialStateCommunity } from '@mui/x-data-grid/models/gridStateCommunity';
import { useTranslation } from 'react-i18next';
import { HIDEntityId } from '@house-id/houseid-types/dist/common';

import { FCC } from '../../../../../types/common';
import DataGridLoadingSkeleton from './DataGridLoadingSkeleton';

const ITEM_ROW_HEIGHT = 72;
const MIN_ROWS = 10;

const NoRowsOverlay: FC = () => {
  const { t } = useTranslation(['common']);

  return (
    <Stack alignItems="center" justifyContent="center" sx={{ height: '100%' }}>
      {t('common:no_data')}
    </Stack>
  );
};

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  border: 'none',
  '& .MuiDataGrid': {
    border: 'none',
  },
  '& .MuiDataGrid-row.Mui-hovered': {
    backgroundColor: 'transparent',
  },
  '& .MuiDataGrid-row:hover': {
    backgroundColor: theme.palette.common.white,
  },
  '& .MuiDataGrid-row:active': {
    backgroundColor: theme.palette.common.white,
  },
  '.MuiDataGrid-virtualScroller': {
    marginLeft: '-1px',
  },
  '& .MuiDataGrid-columnSeparator': {
    display: 'none',
  },
  '& .MuiDataGrid-cell:focus': {
    outline: 'none',
  },
  '& .MuiDataGrid-cell:focus-within': {
    outline: 'none',
  },
  '& .MuiDataGrid-columnHeader:focus': {
    outline: 'none',
  },
  '& .MuiDataGrid-columnHeader:focus-within': {
    outline: 'none',
  },
  '& .MuiDataGrid-columnHeaderTitle': {
    lineHeight: 1.2,
  },
  '& .MuiDataGrid-selectedRowCount': {
    visibility: 'hidden',
  },
  '& .MuiDataGrid-virtualScroller': {
    overflow: 'hidden',
  },
}));

const ClickableStyledDataGrid = styled(StyledDataGrid)(({ theme }) => ({
  '& .MuiDataGrid-row:hover': {
    backgroundColor: theme.palette.grey[100],
    cursor: 'pointer',
  },
  '& .MuiDataGrid-row:active': {
    backgroundColor: theme.palette.grey[200],
  },
}));

const defaultGetRowId = (row: HIDEntityId) => row.id;

export type HIDDataGridProps = {
  paginationModel?: DataGridProps['paginationModel']
  columnVisibilityModel?: DataGridProps['columnVisibilityModel']
  paginationMode?: DataGridProps['paginationMode']
  columns: Array<GridColDef>;
  rows: Array<GridValidRowModel>;
  rowCount?: number;
  initialState?: GridInitialStateCommunity;
  isLoading?: boolean;
  isSelectionMode?: boolean;
  hasPagination?: boolean;
  rowSelectionModel?: GridInputRowSelectionModel;
  disableColumnFilter?: boolean;
  disableColumnMenu?: boolean;
  isRowSelectable?: (params: GridRowParams) => boolean;
  getRowId?: (row: any) => string;
  onRowClick?: GridEventListener<'rowClick'>;
  onCellClick?: GridEventListener<'cellClick'>;
  onRowSelectionModelChange?: (rowSelectionModel: GridRowSelectionModel) => void;
  onPaginationModelChange?: DataGridProps['onPaginationModelChange']
};

const HIDDataGrid: FCC<HIDDataGridProps> = ({
  sx,
  columns,
  rows,
  rowCount,
  paginationModel,
  columnVisibilityModel,
  paginationMode = 'client',
  initialState,
  isLoading = false,
  isSelectionMode = false,
  rowSelectionModel,
  disableColumnFilter = true,
  hasPagination = true,
  disableColumnMenu = true,
  isRowSelectable,
  getRowId = defaultGetRowId,
  onRowClick,
  onCellClick,
  onRowSelectionModelChange,
  onPaginationModelChange,
}) => {
  const theme = useTheme();

  const minHeight = MIN_ROWS * ITEM_ROW_HEIGHT;

  const [key, setKey] = useState(1);

  // NOTE: very bad solution, find better if possible
  useEffect(
    () => {
      const resizeHandler = () => {
        if (document.body.scrollWidth > document.body.clientWidth) {
          setTimeout(() => setKey((key) => key + 1));
        }
      };

      const handler = debounce(resizeHandler, 200);
      window.addEventListener('resize', handler);
      return () => window.removeEventListener('resize', handler);
    },
    [],
  );

  const Grid = onRowClick ? ClickableStyledDataGrid : StyledDataGrid;

  return (
    <Grid
      autoHeight
      disableVirtualization
      checkboxSelection={isSelectionMode}
      columnVisibilityModel={columnVisibilityModel}
      columns={columns}
      disableColumnFilter={disableColumnFilter}
      disableColumnMenu={disableColumnMenu}
      disableRowSelectionOnClick={!isSelectionMode}
      getRowId={(row) => getRowId(row as HIDEntityId)}
      hideFooter={isLoading}
      hideFooterPagination={isLoading}
      hideFooterSelectedRowCount={!isSelectionMode}
      initialState={{
        pagination: {
          paginationModel: {
            page: 0,
            pageSize: 20,
          },
        },
        ...initialState,
      }}
      isRowSelectable={isRowSelectable}
      key={key}
      loading={isLoading}
      pageSizeOptions={[20]}
      paginationMode={paginationMode}
      paginationModel={paginationModel}
      rowCount={rowCount}
      rowHeight={ITEM_ROW_HEIGHT}
      rowSelectionModel={rowSelectionModel}
      rows={isLoading ? [] : rows}
      slotProps={{
        loadingOverlay: { minRows: MIN_ROWS, minRowHeight: ITEM_ROW_HEIGHT },
      } as GridSlotsComponentsProps}
      slots={{
        loadingOverlay: DataGridLoadingSkeleton,
        noRowsOverlay: NoRowsOverlay,
      }}
      sx={{
        marginTop: theme.spacing(2),
        minHeight: hasPagination ? minHeight : 'unset',
        width: '100%',
        height: isLoading || !rows?.length ? minHeight : 'unset',
        overflow: isLoading ? 'hidden' : 'unset',
        '--DataGrid-overlayHeight': `${minHeight}px`,
        ...sx,
      }}
      onCellClick={onCellClick}
      onPaginationModelChange={onPaginationModelChange}
      onRowClick={onRowClick}
      onRowSelectionModelChange={onRowSelectionModelChange}
    />
  );
};

export default HIDDataGrid;
