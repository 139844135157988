import React, { FC } from 'react';

import { TrendChartProps } from '../../types.valuation';
import ScalableSvgChartContainer from './ScalableSvgChartContainer';

const TrendChartMiddlePositive: FC<TrendChartProps> = ({
  width = 608,
  height = 128,
  strokeWidth = 2,
  stopOpacity = 0.25,
}) => (
  <ScalableSvgChartContainer
    Svg={({ x, y }) => (
      <>
        <defs>
          <linearGradient
            id="linear_gradient_middle_positive"
            x1="1"
            x2="1"
            y1="0.26965"
            y2="0.92453"
          >
            <stop stopColor="#DBF5ED" />
            <stop offset="1" stopColor="#E3F7F1" stopOpacity={stopOpacity} />
          </linearGradient>
        </defs>
        <path
          d={`
            m ${204.9 * x} ${38.7 * y}
            c ${-11.3 * x} ${2.2 * y} ${-12.8 * x} ${14.9 * y} ${-36.2 * x} ${14.7 * y}
            c ${-33.4 * x} ${-0.2 * y} ${-42.8 * x} ${-8.6 * y} ${-67.6 * x} ${-5 * y}
            c ${-25.9 * x} ${3.8 * y} ${-39.3 * x} ${28.7 * y} ${-69.6 * x} ${28.8 * y}
            c ${-9.1 * x} ${0 * y} ${-21.7 * x} ${-2.5 * y} ${-31.9 * x} ${-6.7 * y}
            l 0 ${57.5 * y}
            l ${608.7 * x} 0
            l 0 ${-127 * y}
            l ${-5.6 * x} ${2.1 * y}
            l 0 0
            c ${-5.6 * x} ${2.1 * y} ${-16.9 * x} ${6.3 * y} ${-28.2 * x} ${10.4 * y}
            c ${-11.3 * x} ${4.1 * y} ${-22.5 * x} ${8.1 * y} ${-33.8 * x} ${9.9 * y}
            c ${-11.3 * x} ${1.8 * y} ${-22.5 * x} ${1.4 * y} ${-33.8 * x} ${1.8 * y}
            c ${-11.3 * x} ${0.4 * y} ${-22.5 * x} ${1.7 * y} ${-33.8 * x} ${0.1 * y}
            c ${-11.3 * x} ${-1.6 * y} ${-22.5 * x} ${-6.1 * y} ${-33.8 * x} ${-6.9 * y}
            c ${-11.3 * x} ${-0.8 * y} ${-22.5 * x} ${4.2 * y} ${-33.8 * x} ${4.6 * y}
            c ${-11.3 * x} ${0.4 * y} ${-22.5 * x} ${-1.7 * y} ${-33.8 * x} ${-0.9 * y}
            c ${-11.3 * x} ${0.7 * y} ${-22.5 * x} ${4.3 * y} ${-33.8 * x} ${8.5 * y}
            c ${-11.3 * x} ${4.3 * y} ${-19 * x} ${8.2 * y} ${-30.3 * x} ${11.3 * y}
            c ${-11.3 * x} ${3.1 * y} ${-20.6 * x} ${5.9 * y} ${-31.9 * x} ${5.4 * y}
            c ${-11.3 * x} ${-0.5 * y} ${-31.2 * x} ${-3 * y} ${-42.5 * x} ${-5.4 * y}
            c ${-11.3 * x} ${-2.4 * y} ${-17 * x} ${-5.5 * y} ${-28.3 * x} ${-3.2 * y}
            z
          `}
          fill="url(#linear_gradient_middle_positive)"
        />
        <path
          d={`
            m ${-0.4 * x} ${70.5 * y}
            c ${10.2 * x} ${4.2 * y} ${22.8 * x} ${6.7 * y} ${31.9 * x} ${6.7 * y}
            c ${30.3 * x} ${-0.1 * y} ${43.7 * x} ${-25 * y} ${69.6 * x} ${-28.8 * y}
            c ${24.8 * x} ${-3.6 * y} ${34.3 * x} ${4.8 * y} ${67.6 * x} ${5 * y}
            c ${23.3 * x} ${0.2 * y} ${24.9 * x} ${-12.5 * y} ${36.2 * x} ${-14.7 * y}
            c ${11.3 * x} ${-2.2 * y} ${17 * x} ${0.8 * y} ${28.3 * x} ${3.2 * y}
            c ${11.3 * x} ${2.4 * y} ${31.2 * x} ${4.9 * y} ${42.5 * x} ${5.4 * y}
            c ${11.3 * x} ${0.5 * y} ${20.6 * x} ${-2.3 * y} ${31.8 * x} ${-5.4 * y}
            c ${11.3 * x} ${-3.1 * y} ${19 * x} ${-7.1 * y} ${30.3 * x} ${-11.3 * y}
            c ${11.3 * x} ${-4.3 * y} ${22.5 * x} ${-7.8 * y} ${33.8 * x} ${-8.5 * y}
            c ${11.3 * x} ${-0.7 * y} ${22.5 * x} ${1.3 * y} ${33.8 * x} ${0.9 * y}
            c ${11.3 * x} ${-0.4 * y} ${22.5 * x} ${-5.4 * y} ${33.8 * x} ${-4.6 * y}
            c ${11.3 * x} ${0.8 * y} ${22.5 * x} ${5.3 * y} ${33.8 * x} ${6.9 * y}
            c ${11.3 * x} ${1.6 * y} ${22.5 * x} ${0.4 * y} ${33.8 * x} ${-0.1 * y}
            c ${11.3 * x} ${-0.4 * y} ${22.5 * x} 0 ${33.8 * x} ${-1.8 * y}
            c ${11.3 * x} ${-1.8 * y} ${22.5 * x} ${-5.8 * y} ${33.8 * x} ${-9.9 * y}
            c ${11.3 * x} ${-4.1 * y} ${22.5 * x} ${-8.3 * y} ${28.2 * x} ${-10.4 * y}
            l ${5.6 * x} ${-2.1 * y}
          `}
          stroke="#289595"
          strokeWidth={strokeWidth}
        />
      </>
    )}
    height={height}
    width={width}
  />
);

export default TrendChartMiddlePositive;
