import React, { FC } from 'react';
import { skipToken } from '@reduxjs/toolkit/query';

import useGetCurrentPropertyId from '../../../../../hooks/useGetCurrentPropertyId';
import { useGetPropertyFinanceInformationQuery } from '../../../api/api.propertyFinance';
import ValuationOverviewLatestValuation from './ValuationOverviewLatestValuation';
import ValuationOverviewEmptyStateCard from './ValuationOverviewEmptyStateCard';

const ValuationOverviewCard: FC = () => {
  const { data: propertyId } = useGetCurrentPropertyId();

  const {
    data: financeInformation,
    isLoading,
  } = useGetPropertyFinanceInformationQuery(propertyId ? { propertyId } : skipToken);

  const latestMovestaValuation = financeInformation?.latestMovestaValuation;

  return latestMovestaValuation?.expectation || isLoading
    ? <ValuationOverviewLatestValuation isLoading={isLoading} />
    : <ValuationOverviewEmptyStateCard isLoading={isLoading} />;
};

export default ValuationOverviewCard;
