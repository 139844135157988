import React from 'react';
import { useSelector } from 'react-redux';
import {
  skipToken,
} from '@reduxjs/toolkit/query/react';
import qs from 'query-string';
import * as R from 'ramda';
import {
  Product,
  ProductCategory,
  ProductExternals,
  ProductSummaryItem,
} from '@house-id/houseid-types/dist/content/product';
import { HIDEntityId } from '@house-id/houseid-types/dist/common';

import { HIDApiTags } from '../../../../../../../api/HIDApiTags';
import { PropertyId } from '../../../../../types/types.property';
import {
  ProductExternalMediaSummary,
  ProductSearchResults,
  ProductState,
  ProductTypeGuide,
} from '../types.product';
import {
  TimeIntervalsGroupingType,
  DeleteContentParams,
} from '../../../types/types.content';
import { getUrlSearchParams } from '../../../../../../../utils/url';
import { removeEmptyParams } from '../../../../../../../utils/object';
import {
  CreateEntity,
  PaginationParams,
} from '../../../../../../../types/common';
import { propertyApi } from '../../../../../api/api.property';
import { getMutationFixedCacheKey } from '../../../utils/cacheKeys';
import { provideArrayTags } from '../../../../../../../api/HIDBaseQuery';

type ProductWithData = Product & { data: object };

const mapProductData = (productWithData: ProductWithData) => ({ ...R.omit(['data'], productWithData), ...productWithData.data });

type ProductClassification = {
  type: string;
  name: string;
  children?: Array<{ type: string, name: string }>;
};

type ProductsSummaryParams = {
  propertyId: string;
  from?: string;
  to?: string;
  groupingType?: string;
  categoryId?: string,
  state?: ProductState;
};

type GetAllProductsParams = PropertyId & Partial<PaginationParams> & { state?: ProductState };

type GetAllProductsResult = { products: Array<ProductWithData>; totalCount: number; };

type UpdateProduct = PropertyId & Partial<Product> & HIDEntityId & { externals?: Array<ProductExternals> };

const mapProductType = ({ type, name }: ProductClassification) => ({ id: type, name });

export const productApi = propertyApi.injectEndpoints({
  endpoints: (builder) => ({
    getProduct: builder.query<Product, PropertyId & HIDEntityId>({
      query: ({ propertyId, id }) => `v2/properties/${propertyId}/products/${id}`,
      providesTags: (_result, _error, arg) => [{ type: HIDApiTags.PRODUCT, id: arg.id }],
    }),
    getAllProducts: builder.query<{ products: Array<Product>; totalCount: number; }, GetAllProductsParams>({
      query: ({
        propertyId,
        pageSize,
        offset,
        state = ProductState.ALL,
      }) => {
        const queryParams = removeEmptyParams({
          pageSize,
          offset,
          state,
        });
        return `v2/properties/${propertyId}/products?${getUrlSearchParams(queryParams)}`;
      },
      transformResponse: (response: GetAllProductsResult) => ({ ...response, products: R.map(mapProductData, response.products) }),
      providesTags: (result) => provideArrayTags(HIDApiTags.PRODUCT, result?.products),
    }),
    getProductCategories: builder.query<Array<ProductCategory>, PropertyId>({
      query: ({ propertyId }) => `/properties/${propertyId}/classifications/products`,
      transformResponse: (response: Array<{ type: string, name: string }>) => R.pipe(
        R.map(
          (classification: ProductClassification) => ({
            ...mapProductType(classification),
            children: classification.children?.map(mapProductType),
          }),
        ),
        R.sortBy(R.prop('name')),
      )(response),
      providesTags: [HIDApiTags.PRODUCT_CATEGORY],
    }),
    getProductsSummary: builder.query<Array<ProductSummaryItem>, ProductsSummaryParams>({
      query: ({
        propertyId,
        from,
        to,
        groupingType = TimeIntervalsGroupingType.Yearly,
        state = ProductState.ALL,
      }) => {
        const queryParams = removeEmptyParams({
          from,
          to,
          groupingType,
          state,
        });
        return `v2/properties/${propertyId}/products/summary?${getUrlSearchParams(queryParams)}`;
      },
      providesTags: [HIDApiTags.PRODUCT_SUMMARY],
    }),
    searchProducts: builder.query<ProductSearchResults, string>({
      query: (query) => ({
        url: `v2/products/search?query=${encodeURI(query)}`,
        method: 'GET',
      }),
    }),
    createProduct: builder.mutation<Product, CreateEntity<Product> & PropertyId>({
      query: ({ propertyId, ...product }) => ({
        url: `v2/properties/${propertyId}/products`,
        method: 'POST',
        body: product,
      }),
      invalidatesTags: () => [
        HIDApiTags.PRODUCT,
        HIDApiTags.PRODUCT_SUMMARY,
        HIDApiTags.PROPERTY_PRODUCTS,
        HIDApiTags.PROPERTY_PROGRESS,
        HIDApiTags.PLACE,
        HIDApiTags.SELLING_PROPERTY_ARCHIVE,
      ],
    }),
    updateProduct: builder.mutation<Product, UpdateProduct>({
      query: (product) => ({
        url: `v2/properties/${product.propertyId}/products/${product.id}`,
        method: 'PATCH',
        body: product,
      }),
      transformErrorResponse: (response) => response.data,
      invalidatesTags: (_result, _error, arg) => [
        HIDApiTags.PRODUCT_SUMMARY,
        HIDApiTags.PROPERTY_PRODUCTS,
        HIDApiTags.PROPERTY_PROGRESS,
        HIDApiTags.PLACE,
        HIDApiTags.SELLING_PROPERTY_ARCHIVE,
        { type: HIDApiTags.PRODUCT as const, id: arg.id },
      ],
    }),
    deleteProducts: builder.mutation<Array<string>, DeleteContentParams>({
      query: ({ propertyId, ids }) => ({
        url: `v2/properties/${propertyId}/products?${qs.stringify({ ids })}`,
        method: 'DELETE',
      }),
      transformErrorResponse: (response) => response.data,
      invalidatesTags: (_result, _error, arg) => [
        HIDApiTags.PRODUCT_SUMMARY,
        HIDApiTags.PROPERTY_PRODUCTS,
        HIDApiTags.PROPERTY_PROGRESS,
        HIDApiTags.PLACE,
        HIDApiTags.SELLING_PROPERTY_ARCHIVE,
        ...arg.ids.map((id) => ({ type: HIDApiTags.PRODUCT as const, id })),
      ],
    }),
    deleteExternalMedial: builder.mutation<void, PropertyId & { id: string, url: string }>({
      query: ({ propertyId, id, url }) => ({
        url: `v2/properties/${propertyId}/products/${id}/external-media?url=${encodeURI(url)}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_result, _error, arg) => [{ type: HIDApiTags.PRODUCT as const, id: arg.id }],
    }),
    getAllProductTypeGuides: builder.query<Array<ProductTypeGuide>, PropertyId>({
      query: ({ propertyId }) => `properties/${propertyId}/product-type-guides`,
      providesTags: (result) => provideArrayTags(HIDApiTags.PRODUCT_TYPE_GUIDE, result?.map((item) => ({ id: item.type }))),
    }),
    getLastAddedProducts: builder.query<Array<Product>, void>({
      query: () => '/products/last-added',
    }),
    getProductExternalMediaSummary: builder.query<ProductExternalMediaSummary, Pick<Product, 'externals'>>({
      query: ({ externals }) => ({
        url: '/products/summary',
        method: 'POST',
        body: { externals },
      }),
    }),
  }),
});

export const {
  useGetAllProductsQuery,
  useGetProductCategoriesQuery,
  useGetProductsSummaryQuery,
  useGetAllProductTypeGuidesQuery,
  useLazySearchProductsQuery,
  useCreateProductMutation,
  useUpdateProductMutation,
  useDeleteProductsMutation,
  useDeleteExternalMedialMutation,
  useGetLastAddedProductsQuery,
  useGetProductExternalMediaSummaryQuery,
} = productApi;

export const useGetProductWithCache = ({ propertyId, productId }: { propertyId?: string, productId?: string }) => {
  const selectProducts = React.useMemo(
    () => productApi.endpoints.getAllProducts.select(propertyId ? { propertyId } : skipToken),
    [propertyId],
  );

  const [
    _deleteProducts,
    { isLoading: isDeleting, isSuccess: isDeleted },
  ] = useDeleteProductsMutation({
    fixedCacheKey: getMutationFixedCacheKey(productId),
  });
  const isDeletingOrDeleted = isDeleting || isDeleted;

  const { data: cachedProducts } = useSelector(selectProducts);
  const cachedProduct = cachedProducts?.products?.find((product) => product.id === productId);

  const {
    data: fetchedProduct,
    isLoading,
    isUninitialized,
    error,
    refetch,
  } = productApi.useGetProductQuery(
    propertyId && productId && !cachedProduct && !isDeletingOrDeleted
      ? { propertyId, id: productId }
      : skipToken,
  );

  return {
    product: fetchedProduct || cachedProduct,
    isLoading,
    isUninitialized,
    error,
    refetch,
  };
};
