/* eslint-disable max-len */
export const SF_PRIVACY_POLICY = 'https://www.svenskfast.se/contentassets/770b3e3dd07c47b9bff0887f0fa2bd35/integritetspolicy-sverige-svenska-svensk-fastighetsformedling.pdf';
export const SF_READ_MORE_URL = 'https://www.svenskfast.se/?utm_source=houseid&utm_medium=referral&utm_campaign=partner&utm_content=app';

export const SF_READ_MORE_INSURANCE_URL = 'https://www.svenskfast.se/tjanster/varudeklarerat/?utm_source=houseid&utm_medium=referral&utm_campaign=partner&utm_content=app';
export const ANTICIMEX_REPORT_URL = 'https://www2.anticimexforsakringar.se/app/anmal-forsakringsskada/varudeklarerat/kopare';

export const IF_REPORT_URL = 'https://www.if.se/privat/vid-skada';
export const IF_READ_MORE_HOUSE_INSURANCE_URL = 'https://www.if.se/privat/samarbeten/svenskfast/kopare/villaforsakring';
export const IF_READ_MORE_CONDOMINIUM_INSURANCE_URL = 'https://www.if.se/privat/samarbeten/svenskfast/kopare/bostadsrattsforsakring';
export const IF_READ_MORE_VACATION_HOUSE_INSURANCE_URL = 'https://www.if.se/privat/samarbeten/svenskfast/kopare/fritidshusforsakring';
