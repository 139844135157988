import React, { forwardRef } from 'react';
import {
  TextFieldProps,
  useTheme,
} from '@mui/material';
import { isValid } from 'date-fns';

import HIDDatePicker, { HIDDatePickerProps } from './HIDDatePicker';
import {
  FCC,
  FCCProps,
  HelperTextFormProps,
} from '../../types/common';
import { DateTimeFormats } from '../../utils/date';

type HIDFormDatePickerProps = HelperTextFormProps & {
  label: string;
  value: Date | undefined;
  dateTimeFormat?: DateTimeFormats;
  views?: HIDDatePickerProps['views'],
  maxDate?: Date;
  minDate?: Date;
  fullWidth?: boolean;
  required?: TextFieldProps['required'],
  error?: TextFieldProps['error'],
  disabled?: TextFieldProps['disabled'],
  onBlur?: TextFieldProps['onBlur'],
  onChange: (date?: Date | undefined) => void;
};

type HIDFormDatePickerPropsWithRef = FCCProps<HIDFormDatePickerProps, HTMLInputElement>;

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
const HIDFormDatePicker: FCC<HIDFormDatePickerProps> = forwardRef<HTMLInputElement, HIDFormDatePickerPropsWithRef>((
  {
    label,
    value,
    maxDate,
    minDate,
    views,
    dateTimeFormat,
    fullWidth = true,
    required,
    error,
    disabled,
    helperText,
    helperTextWrap = false,
    showHelperText = true,
    onBlur,
    onChange,
  },
  ref,
) => {
  const theme = useTheme();

  return (
    <HIDDatePicker
      dateTimeFormat={dateTimeFormat}
      disabled={disabled}
      fullWidth={fullWidth}
      label={label}
      maxDate={maxDate}
      minDate={minDate}
      ref={ref}
      slotProps={{
        textField: {
          fullWidth,
          required,
          variant: 'standard',
          error,
          helperText: showHelperText ? helperText || ' ' : undefined,
          InputProps: {
            sx: {
              ...(!value && { backgroundColor: theme.palette.primary.lightest }),
            },
          },
          InputLabelProps: {
            sx: {
              zIndex: 1,
              pointerEvents: 'none',
            },
          },
          FormHelperTextProps: {
            sx: { textWrap: helperTextWrap ? 'wrap' : 'nowrap' },
          },
          onBlur,
        },
        inputAdornment: {
          sx: {
            marginBottom: 1,
          },
        },
      }}
      value={value}
      views={views}
      onChange={(date?: Date) => date === undefined || isValid(date) ? onChange(date) : undefined}
    />
  );
});

HIDFormDatePicker.displayName = 'HIDFormDatePicker';

export default HIDFormDatePicker;
