import React, { FC } from 'react';
import { match } from 'ts-pattern';

import TrendChartMinNegative from './charts/TrendChartMinNegative';
import TrendChartMiddleNegative from './charts/TrendChartMiddleNegative';
import TrendChartLowNegative from './charts/TrendChartLowNegative';
import TrendChartLowPositive from './charts/TrendChartLowPositive';
import TrendChartMiddlePositive from './charts/TrendChartMiddlePositive';
import TrendChartMaxPositive from './charts/TrendChartMaxPositive';

type ValuationTrendChartProps = {
  percent: number;
  strokeWidth?: number;
  width?: number;
  height?: number;
};

const ValuationTrendChart: FC<ValuationTrendChartProps> = ({
  percent,
  strokeWidth,
  width,
  height,
}) => match(percent)
  .when((value) => value <= -30, () => <TrendChartMinNegative height={height} strokeWidth={strokeWidth} width={width} />)
  .when((value) => value > -30 && value < -5, () => <TrendChartMiddleNegative height={height} strokeWidth={strokeWidth} width={width} />)
  .when((value) => value >= -5 && value < 0, () => <TrendChartLowNegative height={height} strokeWidth={strokeWidth} width={width} />)
  .when((value) => value >= 0 && value <= 5, () => <TrendChartLowPositive height={height} strokeWidth={strokeWidth} width={width} />)
  .when((value) => value > 5 && value < 30, () => <TrendChartMiddlePositive height={height} strokeWidth={strokeWidth} width={width} />)
  .when((value) => value >= 30, () => <TrendChartMaxPositive height={height} strokeWidth={strokeWidth} width={width} />)
  .otherwise(() => undefined);

export default ValuationTrendChart;
