import React, { FC } from 'react';
import {
  Add,
  DeleteOutline,
  EditOutlined,
} from '@mui/icons-material';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import {
  BudgetCategory,
  BudgetShortModel,
} from '@house-id/houseid-types/dist/finances/budgets';
import { CircularProgress } from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/query';
import { ModifyActionType } from '@house-id/houseid-types/dist/common';

import HIDActionsSection from '../../../../../../../components/HIDActionsSection';
import useGetPropertyPermissions from '../../../../../hooks/useGetPropertyPermissions';
import useGetHandleCreateBudget from '../hooks/useGetHandleCreateBudget';
import useGetCurrentPropertyId from '../../../../../hooks/useGetCurrentPropertyId';
import useDialog from '../../../../../../../hooks/useDialog';
import DialogNames from '../../../../../../../hooks/useDialog/DialogNames';
import {
  useDeleteBudgetMutation,
  useGetBudgetsQuery,
  usePatchBudgetMutation,
} from '../api/api.budgets';
import {
  getBudgetsOverviewPath,
  getUpdateBudgetPath,
} from '../navigation.budgets';
import { SelectionModeType } from '../../../../Content/components/actions/ListEntitiesToolbarActions';
import { getBudgetName } from '../utils/utils.budget';
import { ICON_SIZE } from '../../../../../../../constants/layout';
import { getCurrentBudget } from '../hooks/useGetCurrentBudget';

const BudgetActions: FC<{ selectedBudget?: BudgetShortModel; selectedBudgetCategory?: BudgetCategory }> = ({
  selectedBudget,
  selectedBudgetCategory,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation(['common', 'finances']);

  const { data: propertyId } = useGetCurrentPropertyId();

  const { data: { canCreate, canUpdate, canDelete } = {} } = useGetPropertyPermissions();

  const {
    data: budgets = [],
  } = useGetBudgetsQuery(propertyId ? { propertyId } : skipToken);

  const [
    deleteBudget,
    { isLoading: isDeleting },
  ] = useDeleteBudgetMutation();

  const [openConfirmDelete] = useDialog(DialogNames.YES_NO_CANCEL_DIALOG);

  const budgetId = selectedBudget?.id;
  const budgeName = getBudgetName(selectedBudget);

  const handleDeleteCurrentBudget = () => openConfirmDelete({
    fullScreen: false,
    title: t('common:are_you_sure_you_want_to_delete'),
    loading: isDeleting,
    description: budgeName,
    onYes: () => {
      if (propertyId && budgetId) {
        deleteBudget({ propertyId, budgetId })
          .then(() => {
            const nextBudgetId = getCurrentBudget(budgets.filter(({ id }) => id !== selectedBudget.id))?.id;
            navigate(getBudgetsOverviewPath({ propertyId, id: nextBudgetId }), { replace: true });
          });
      }
    },
    onNo: () => { },
  });

  const [patchBudget, { isLoading: isPatchingBudget }] = usePatchBudgetMutation();

  const handleDeleteCurrentBudgetCategory = () => openConfirmDelete({
    title: t('common:are_you_sure_you_want_to_delete'),
    loading: isDeleting,
    description: selectedBudgetCategory?.label,
    onYes: () => {
      if (propertyId && budgetId && selectedBudgetCategory?.subcategories?.length) {
        const categoryPostsIds = selectedBudgetCategory.subcategories.map(({ id }) => id);
        patchBudget({
          propertyId,
          budgetId,
          posts: categoryPostsIds.map((id) => ({ id, action: ModifyActionType.DELETE })),
        })
          .then(() => navigate(getBudgetsOverviewPath({ propertyId, id: budgetId }), { replace: true }));
      }
    },
    onNo: () => { },
  });

  const handleDelete = () => {
    if (selectedBudgetCategory) {
      handleDeleteCurrentBudgetCategory();
    } else {
      handleDeleteCurrentBudget();
    }
  };

  const [handleCreateBudget, isLoading] = useGetHandleCreateBudget();

  const handleUpdateBudget = () => {
    if (propertyId && budgetId) {
      navigate(getUpdateBudgetPath({ propertyId, id: budgetId, categoryId: selectedBudgetCategory?.categoryId }));
    }
  };

  const actions = [
    canCreate && {
      id: 'add',
      Icon: Add,
      RightIcon: () => isLoading ? <CircularProgress size={ICON_SIZE} /> : undefined,
      label: t('common:add_label'),
      disabled: isDeleting || isLoading,
      onClick: handleCreateBudget,
    },
    Boolean(budgetId && canUpdate) && {
      id: SelectionModeType.UPDATE,
      Icon: EditOutlined,
      label: t('common:update_label'),
      disabled: isDeleting,
      onClick: handleUpdateBudget,
    },
    Boolean(budgetId && canDelete) && {
      id: 'delete',
      Icon: DeleteOutline,
      label: t('common:delete_label'),
      disabled: isDeleting || isPatchingBudget,
      onClick: handleDelete,
    },
  ].filter(Boolean);

  return actions.length
    ? (
      <HIDActionsSection
        items={actions}
        title={t('common:manage')}
      />
    )
    : null;
};

export default BudgetActions;
