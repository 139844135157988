import React from 'react';
import {
  Stack,
  useTheme,
} from '@mui/material';

import { FCC } from '../types/common';
import HIDInfo from './HIDInfo';
import useBreakPointsSizes from '../hooks/useBreakpointsSizes';
import { BorderRadius } from '../constants/borderRadius';

type HIDSectionProps = {
  title?: string;
  infoDescription?: string;
};

const HIDSection: FCC<HIDSectionProps> = ({
  title,
  infoDescription,
  children,
  sx,
}) => {
  const theme = useTheme();

  const { isDownSm } = useBreakPointsSizes();

  return (
    <Stack
      direction="column"
      sx={{
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: theme.palette.grey[300],
        borderRadius: `${BorderRadius.sm}px`,
        padding: isDownSm ? 2.5 : 3,
        ...sx,
      }}
    >
      {title && (
        <HIDInfo
          description={infoDescription}
          label={title}
          labelVariant="h6"
          sx={{ mb: 2.5 }}
        />
      )}
      {children}
    </Stack>
  );
};

export default HIDSection;
