import React, {
  FC,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Card,
  Divider,
  FormControlLabel,
  Stack,
  Switch,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { skipToken } from '@reduxjs/toolkit/query';
import {
  FeatureStatus,
  SubscriptionFeature,
} from '@house-id/houseid-types/dist/subscriptionPlans';

import HomeLayout from '../../../../../../pages/Home/components/HomeLayout';
import { getPathWithPropertyIdOrInit } from '../../../../../../../Auth/navigation/navigation.auth';
import { useNavigateBackOr } from '../../../../../../../../utils/routes';
import { getHomePath } from '../../../../../../navigation/navigation.property';
import useGetCurrentPropertyId from '../../../../../../hooks/useGetCurrentPropertyId';
import { getFinancesPath } from '../../../../navigation.finances';
import FinancesQuickNavigation from '../../../../components/FinancesQuickNavigation';
import { FinancePageNames } from '../../../../types.finances';
import LatestIndicationValuationSection from './components/LatestIndicationValuationSection';
import ValuationHistoryKeyFigures from './components/ValuationHistoryKeyFigures';
import ValuationEmptyStateCard from './components/ValuationEmptyStateCard';
import ValuationSubscriptionPlanBanner from './components/ValuationSubscriptionPlanBanner';
import {
  useDeleteIndicationValuationMutation,
  useGetIndicationValuationHistoryQuery,
  useGetIndicationValuationSettingsQuery,
  usePatchIndicationValuationSettingsMutation,
} from '../../api/api.valuation';
import IndicationValuationHistoryItem from './components/IndicationValuationHistoryItem';
import HIDTypography from '../../../../../../../../components/HIDTypography';
import useBreakPointsSizes from '../../../../../../../../hooks/useBreakpointsSizes';
import ViewEntityActions from '../../../../../Content/components/actions/ViewEntityActions';
import useDialog from '../../../../../../../../hooks/useDialog';
import DialogNames from '../../../../../../../../hooks/useDialog/DialogNames';
import { getValuationOverviewPath } from '../../navigation.valuation';
import ValuationOverviewEmptyStateCard from '../../components/ValuationOverviewEmptyStateCard';
import { useGetPropertyFinanceInformationQuery } from '../../../../api/api.propertyFinance';
import { useGetCurrentSubscriptionPlanQuery } from '../../../../../../../SubscriptionPlans/api/api.subscriptionPlans';

const ValuationHistory: FC = () => {
  const navigateBackOr = useNavigateBackOr();
  const { t } = useTranslation(['common', 'finances']);

  const { isDownSm } = useBreakPointsSizes();

  const { data: propertyId } = useGetCurrentPropertyId();

  const {
    data: historyValuation = [],
  } = useGetIndicationValuationHistoryQuery(propertyId ? { propertyId } : skipToken);

  const {
    data: financeInformation,
    isLoading: financeInformationIsLoading,
  } = useGetPropertyFinanceInformationQuery(propertyId ? { propertyId } : skipToken);

  const { data, isLoading: featuresIsLoading } = useGetCurrentSubscriptionPlanQuery();
  const featureStatus = data?.me?.[SubscriptionFeature.VALUATION_INDICATIONS]?.status || FeatureStatus.DISABLED;

  const latestMovestaValuation = financeInformation?.latestMovestaValuation;

  const historyItems = useMemo(
    () => historyValuation
      .map((item, index) => ({
        createdAt: item.createdAt,
        difference: index + 1 < historyValuation.length
          ? item.valuation.expectation - historyValuation[index + 1].valuation.expectation
          : 0,
        expectation: item.valuation?.expectation,
      })),
    [historyValuation],
  );

  const handleGoBack = () => navigateBackOr(propertyId ? getHomePath({ propertyId }) : undefined);

  const [openIndicationValuationDialog] = useDialog(DialogNames.VALUATION_INDICATION_WIZARD_DIALOG);

  const handleUpdate = () => openIndicationValuationDialog();

  const [deleteIndicationValuation] = useDeleteIndicationValuationMutation();

  const handleDelete = () => {
    if (propertyId) {
      deleteIndicationValuation({ propertyId });
    }
  };

  const [isEnabled, setIsEnabled] = useState<boolean>();

  const {
    data: indicationValuationSettings,
  } = useGetIndicationValuationSettingsQuery(propertyId ? { propertyId } : skipToken);

  const [
    patchIndicationValuationSettings,
  ] = usePatchIndicationValuationSettingsMutation();

  useEffect(() => {
    if (indicationValuationSettings && isEnabled === undefined) {
      setIsEnabled(indicationValuationSettings.syncEnabled);
    }
  }, [indicationValuationSettings]);

  const handleUpdateSettings = (isEnabled: boolean) => {
    setIsEnabled(isEnabled);
    if (propertyId) {
      patchIndicationValuationSettings({ propertyId, syncEnabled: isEnabled });
    }
  };

  return (
    <HomeLayout
      SideColumn={
        <>
          <Card sx={{ padding: 2 }}>
            <ViewEntityActions
              onDelete={handleDelete}
              onUpdate={handleUpdate}
            >
              <Stack sx={{ px: 1 }}>
                <FormControlLabel
                  control={<Switch checked={isEnabled} />}
                  label={t('finances:notices_of_new_value_indication')}
                  labelPlacement="start"
                  sx={{ justifyContent: 'space-between', marginLeft: 0 }}
                  onChange={(_event, checked) => handleUpdateSettings(checked)}
                />
              </Stack>
            </ViewEntityActions>
          </Card>
          <Card sx={{ padding: 2 }}>
            <FinancesQuickNavigation currentPage={FinancePageNames.VALUATION} />
          </Card>
        </>
      }
      breadcrumbsLinks={[
        {
          link: getPathWithPropertyIdOrInit(getFinancesPath, { propertyId }),
          name: t('finances:finances'),
        },
        {
          link: getPathWithPropertyIdOrInit(getValuationOverviewPath, { propertyId }),
          name: t('finances:valuation'),
        },
      ]}
      sideDrawerElements={[
        <ViewEntityActions
          key={ViewEntityActions.name}
          onDelete={handleDelete}
          onUpdate={handleUpdate}
        />,
        <FinancesQuickNavigation
          currentPage={FinancePageNames.VALUATION}
          key={FinancesQuickNavigation.name}
        />,
      ]}
      title={t('finances:value_development')}
      onBack={handleGoBack}
    >
      <Stack direction="column" gap={isDownSm ? 3 : 4}>
        {(!latestMovestaValuation?.expectation || financeInformationIsLoading) && (
          <ValuationOverviewEmptyStateCard isLoading={financeInformationIsLoading} />
        )}
        <LatestIndicationValuationSection />
        {!featuresIsLoading && featureStatus === FeatureStatus.DISABLED && (
          <ValuationSubscriptionPlanBanner />
        )}
        <ValuationHistoryKeyFigures />
        <ValuationEmptyStateCard />
        {Boolean(historyItems.length) && (
          <Stack gap={3}>
            <HIDTypography variant="h5">
              {t('finances:weather_history')}
            </HIDTypography>
            {
              historyItems
                .map((item, index) => (
                  <Stack key={index}>
                    <IndicationValuationHistoryItem {...item} />
                    {index + 1 < historyItems.length && <Divider />}
                  </Stack>
                ))
            }
          </Stack>
        )}
      </Stack>
    </HomeLayout>
  );
};

export default ValuationHistory;
