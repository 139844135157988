import { NavigationAction } from '@house-id/houseid-types/dist/navigationAction';

/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { useAuthState } from 'react-firebase-hooks/auth';

import { getPathWithPropertyIdOrInit } from '../../Auth/navigation/navigation.auth';
import {
  getCreateDocumentPath,
  getDocumentPath,
} from '../modules/Content/modules/Document/navigation.document';
import { getHomePath } from '../navigation/navigation.property';
import useGetCurrentPropertyId from './useGetCurrentPropertyId';
import { getSubscriptionPlansPath } from '../../SubscriptionPlans/navigation/navigation.subscriptionPlans';
import { getRecurringExpensesCategoriesPath } from '../modules/Finances/modules/RecurringExpenses/navigation.recurringExpenses';
import { getFinancesPath } from '../modules/Finances/navigation.finances';
import { getValuationHistoryPath } from '../modules/Finances/modules/Valuation/navigation.valuation';
import { getActiveAuth } from '../../../external-services/firebase';
import { isTestEnv } from '../../../utils/env';
import { useGetTokenDataQuery } from '../../Auth/api/api.user';

const useGetNavigationAction = () => {
  const { data: propertyId } = useGetCurrentPropertyId();
  const [currentUser] = useAuthState(getActiveAuth());
  const { data: tokenData } = useGetTokenDataQuery({}, { skip: !currentUser });

  const showValuation = isTestEnv || tokenData?.isAdmin;

  const navigationActions: Record<string, (params: any) => string> = {
    [NavigationAction.VIEW_DOCUMENT]: (params: any) => getPathWithPropertyIdOrInit(getDocumentPath, { propertyId, ...params }),
    [NavigationAction.CREATE_DOCUMENT]: (params: any) => getPathWithPropertyIdOrInit(getCreateDocumentPath, { propertyId, ...params }),
    [NavigationAction.VIEW_HOME]: (params: any) => getPathWithPropertyIdOrInit(getHomePath, { propertyId, ...params }),
    [NavigationAction.OPEN_SUBSCRIPTION_PLANS]: (params: any) =>
      getPathWithPropertyIdOrInit(getSubscriptionPlansPath, { propertyId, ...params }),
    [NavigationAction.VIEW_ACCOMMODATION_COST]: (params: any) =>
      getPathWithPropertyIdOrInit(getRecurringExpensesCategoriesPath, { propertyId, ...params }),
    [NavigationAction.VIEW_FINANCE_OVERVIEW]: (params: any) => getPathWithPropertyIdOrInit(getFinancesPath, { propertyId, ...params }),
  };

  if (showValuation) {
    navigationActions[NavigationAction.VIEW_MOVESTA_VALUATION_HISTORY] = (params: any) =>
      getPathWithPropertyIdOrInit(getValuationHistoryPath, { propertyId, ...params });
  }

  return (action: NavigationAction) => navigationActions[action];
};

export default useGetNavigationAction;
