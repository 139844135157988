import React, {
  FC,
  useEffect,
} from 'react';
import {
  Card,
  Stack,
  Divider,
  Typography,
  Skeleton,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import sanitizeHtml from 'sanitize-html';
import {
  MailOutline,
  ArrowForward,
  ArrowBack,
} from '@mui/icons-material';
import { enqueueSnackbar } from 'notistack';
import { match } from 'ts-pattern';
import * as R from 'ramda';
import { EntityType } from '@house-id/houseid-types/dist/entityType';
import {
  HIDBlob,
  HIDEntityId,
} from '@house-id/houseid-types/dist/common';

import HomeLayout from '../../../../../../pages/Home/components/HomeLayout';
import {
  useNavigateBackOr,
  useRouteParams,
} from '../../../../../../../../utils/routes';
import { getMessagesPath } from '../../navigation.message';
import useGetCurrentPropertyId from '../../../../../../hooks/useGetCurrentPropertyId';
import { FCC } from '../../../../../../../../types/common';
import {
  useDeleteMessageAttachmentsMutation,
  useDeleteMessagesMutation,
  useGetMessageQuery,
  useMarkAsReadMutation,
  useMarkAsUnreadMutation,
} from '../../api/api.message';
import { APP_NAME } from '../../../../../../../../constants/links';
import HIDAvatar from '../../../../../../../../components/HIDAvatar';
import FilesList from '../../../../components/lists/FilesList';
import ViewEntityActions from '../../../../components/actions/ViewEntityActions';
import {
  DateTimeFormats,
  formatDate,
} from '../../../../../../../../utils/date';
import useBreakPointsSizes from '../../../../../../../../hooks/useBreakpointsSizes';
import { useCreateContentFilesMutation } from '../../../ContentFile/api/api.contentFile';
import useNavigateFromLink from '../../../../../../hooks/useNavigateFromLink';
import HIDButton from '../../../../../../../../components/buttons/HIDButton';
import UserRemovalRequestMessageTypeCard from './components/UserRemovalRequestMessageTypeCard';
import {
  MessageInAppDataNavigation,
  MessageInAppForumDataNavigation,
  MessageType,
} from '../../types.message';
import useEntitySuggestionSection from '../../../Suggestion/hooks/useEntitySuggestionSection';
import { getTypographyHeight } from '../../../../../../../../utils/style';
import { getPathWithPropertyIdOrInit } from '../../../../../../../Auth/navigation/navigation.auth';
import { isTypeOf } from '../../../../../../../../utils/object';
import useTryNavigateToThread from '../../../../../../../Forum/hooks/useTryNavigateToThread';

// TODO: remove and move loading logic into render
const MessagesLoaderSkeleton: FCC<{ isLoading: boolean }> = ({
  isLoading,
  children,
}) => {
  const theme = useTheme();

  return isLoading
    ? (
      <Stack spacing={1} sx={{ padding: theme.spacing(0.5, 1) }}>
        <Stack alignItems="center" direction="row" spacing={1}>
          <Skeleton
            height={40}
            variant="circular"
            width={40}
          />
          <Skeleton
            height={getTypographyHeight(theme.typography.body2)}
            variant="rounded"
            width="30%"
          />
        </Stack>
        <Divider />
        <Skeleton variant="text" width="50%" />
        <Skeleton variant="text" width="80%" />
        <Skeleton variant="text" width="60%" />
        <Stack direction="row-reverse" sx={{ padding: theme.spacing(2) }}>
          <Skeleton
            height={40}
            sx={{ borderRadius: 20 }}
            variant="rounded"
            width="120px"
          />
        </Stack>
      </Stack>
    )
    : children;
};

const ViewMessage: FC = () => {
  const navigateGoBackOr = useNavigateBackOr();
  const theme = useTheme();
  const { t } = useTranslation(['messages']);

  const { isDownMd, isDownSm } = useBreakPointsSizes();

  const { id: messageId, suggestionKey } = useRouteParams<HIDEntityId & { suggestionKey?: string }>();

  const [deleteMessages, { isLoading: isDeleting, isSuccess: isDeleted }] = useDeleteMessagesMutation();
  const [deleteMessageAttachments, { isLoading: isDeletingAttachment }] = useDeleteMessageAttachmentsMutation();

  const { data: propertyId } = useGetCurrentPropertyId();
  const { data: message, isLoading: isMessageLoading } = useGetMessageQuery({ id: messageId }, { skip: isDeleting || isDeleted });

  const messageData = message?.data;

  const [markAsRead] = useMarkAsReadMutation();
  const [markAsUnread, { isLoading: isMarkingAsUnread }] = useMarkAsUnreadMutation();
  const [createContentFiles, { isLoading: isCreatingContentFile }] = useCreateContentFilesMutation();

  useEffect(() => {
    if (messageId) {
      markAsRead({ ids: [messageId] });
    }
  }, [messageId]);

  const handleGoBack = () => navigateGoBackOr(getPathWithPropertyIdOrInit(getMessagesPath, { propertyId }));

  const handleMarkAsUnread = () => {
    if (messageId) {
      markAsUnread({ ids: [messageId] });
    }
  };

  const handleDelete = () => {
    if (messageId) {
      deleteMessages({ ids: [messageId] })
        .then(handleGoBack);
    }
  };

  const hasNavigation = Boolean(messageData?.navigation);
  const hasInAppNavigation = Boolean(messageData?.inAppNavigation);
  const hasBanner = Boolean((messageData?.banner));

  const handleTryNavigateToThread = useTryNavigateToThread();

  const handleInAppNavigation = (inAppNavigationData?: MessageInAppDataNavigation) => {
    if (isTypeOf<MessageInAppForumDataNavigation>(inAppNavigationData, 'threadId')) {
      handleTryNavigateToThread({ ...inAppNavigationData, id: inAppNavigationData.threadId });
    }
  };

  const navigateToScreenFromLink = useNavigateFromLink(messageData?.navigation);

  const sender = message ? message?.sender?.name || APP_NAME : '';

  const handleMoveAttachment = (blob?: HIDBlob) => {
    if (blob && propertyId) {
      createContentFiles({
        propertyId,
        files: [{
          fileName: blob.name,
          mime: blob.mime,
          blobId: blob.id,
        }],
      })
        .then(() => {
          enqueueSnackbar(t('messages:messages_file_saved'), {
            variant: 'success',
          });
        })
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        .then(() => refetchSuggestions());
    }
  };

  const messageBlobs = message?.blobs || [];

  const customFieldSuggestionAction: Record<string, () => void> = {
    not_saved_attachments: () => setTimeout(() => handleMoveAttachment(R.head(messageBlobs)), 300),
  };

  const {
    SuggestionSection,
    refetchSuggestions,
  } = useEntitySuggestionSection({
    entityId: messageId,
    entityType: EntityType.MESSAGE,
    customFieldSuggestionAction,
    initialSuggestion: suggestionKey,
  });

  const customActions = [
    messageId && {
      id: 'markAsUnread',
      label: t('messages:messages_mark_as_unread'),
      Icon: MailOutline,
      onClick: handleMarkAsUnread,
    },
  ].filter(Boolean);

  const handleDeleteAttachment = (id: string) => deleteMessageAttachments({ messageId, ids: [id] });

  const MessageTypeContent = message?.type
    ? match(message.type)
      .with(MessageType.USER_REMOVAL_REQUEST, () => <UserRemovalRequestMessageTypeCard message={message} />)
      .otherwise(() => <div />)
    : null;

  const isLoading = isMessageLoading
    || isDeleting
    || isMarkingAsUnread
    || isDeletingAttachment
    || isCreatingContentFile;

  return (
    <HomeLayout
      BodyLoaderSkeleton={MessagesLoaderSkeleton}
      SideColumn={
        <>
          <Card sx={{ padding: 2 }}>
            <ViewEntityActions
              customActions={customActions}
              onDelete={handleDelete}
            />
          </Card>
          {Boolean(messageBlobs.length) && (
            <Card sx={{ padding: 2 }}>
              <FilesList
                condense
                blobs={messageBlobs}
                isLoading={isLoading}
                onDeleteBlob={handleDeleteAttachment}
                onMoveBlob={handleMoveAttachment}
              />
            </Card>
          )}
          {SuggestionSection}
        </>
      }
      isLoading={isMessageLoading}
      sideDrawerElements={[
        <ViewEntityActions
          customActions={customActions}
          key={ViewEntityActions.name}
          onDelete={handleDelete}
        />,
        !isDownMd && Boolean(messageBlobs.length) && (
          <FilesList
            condense
            blobs={messageBlobs}
            isLoading={isLoading}
            key={FilesList.name}
            onDeleteBlob={handleDeleteAttachment}
            onMoveBlob={handleMoveAttachment}
          />
        ),
        SuggestionSection,
      ].filter(Boolean)}
      title={t('messages:messages_message')}
      onBack={handleGoBack}
    >
      <Stack spacing={1.5}>
        <Stack
          alignItems="center"
          direction="row"
          spacing={1}
          sx={{
            paddingBottom: 2,
            borderStyle: 'solid',
            borderWidth: 0,
            borderBottomWidth: 1,
            borderColor: theme.palette.grey[300],
          }}
        >
          <HIDAvatar givenName={sender} size="medium" />
          <Typography variant="subtitle1">{sender}</Typography>
          {message?.createdAt !== undefined && (
            <Typography
              style={{ marginLeft: 'auto' }}
              sx={{ color: theme.palette.grey[500] }}
              variant="body2"
            >
              {formatDate(new Date(message.createdAt), DateTimeFormats.DATE_ONLY_TEXT)}
            </Typography>
          )}
        </Stack>
        <Stack
          direction="row-reverse"
          flexWrap={isDownSm ? 'wrap' : 'nowrap'}
          justifyContent="space-between"
        >
          {hasInAppNavigation && (
            <HIDButton
              Icon={ArrowBack}
              color="secondary"
              iconPosition="left"
              size="small"
              sx={{ marginLeft: 2, marginBottom: 1 }}
              onClick={() => handleInAppNavigation(messageData?.inAppNavigation?.data)}
            >
              {messageData?.inAppNavigation?.label}
            </HIDButton>
          )}
          <Typography sx={{ marginRight: 'auto', marginBottom: 1 }} variant="subtitle1">
            {message?.subject}
          </Typography>
        </Stack>
        <Typography>
          {/* eslint-disable-next-line react/no-danger */}
          <span dangerouslySetInnerHTML={{ __html: sanitizeHtml(message?.htmlBody || '') }} />
        </Typography>
        {!hasBanner && hasNavigation && (
          <Stack direction="row" justifyContent="flex-end">
            <HIDButton
              Icon={ArrowForward}
              fullWidth={isDownSm}
              onClick={navigateToScreenFromLink}
            >
              {t('messages:messages_go_there')}
            </HIDButton>
          </Stack>
        )}
        {hasBanner && (
          <Stack
            alignItems="center"
            spacing={2}
            style={{ marginTop: theme.spacing(3) }}
            sx={{
              padding: 3,
              backgroundColor: theme.palette.primary.lightest,
            }}
          >
            <img
              src={messageData?.banner?.imageUrl}
              style={{
                width: 80,
                height: 80,
                borderRadius: '50%',
              }}
            />
            <Typography variant="h6">
              {messageData?.banner?.title}
            </Typography>
            <Typography>
              {messageData?.banner?.body}
            </Typography>
            <HIDButton
              Icon={messageData?.navigation?.label ? undefined : ArrowForward}
              fullWidth={isDownSm}
              onClick={navigateToScreenFromLink}
            >
              {messageData?.navigation?.label || t('messages:messages_go_there')}
            </HIDButton>
          </Stack>
        )}
        {MessageTypeContent}
        {isDownMd && (
          <FilesList
            blobs={messageBlobs}
            isLoading={isLoading}
            sx={{ maxWidth: 400 }}
            onDeleteBlob={handleDeleteAttachment}
            onMoveBlob={handleMoveAttachment}
          />
        )}
      </Stack>
    </HomeLayout>
  );
};

export default ViewMessage;
