import React, { FC } from 'react';

import { TrendChartProps } from '../../types.valuation';
import ScalableSvgChartContainer from './ScalableSvgChartContainer';

const TrendChartMinNegative: FC<TrendChartProps> = ({
  width = 608,
  height = 128,
  strokeWidth = 2,
  stopOpacity = 0.25,
}) => (
  <ScalableSvgChartContainer
    Svg={({ x, y }) => (
      <>
        <defs>
          <linearGradient
            id="linear_gradient_min_negative"
            x1="1"
            x2="1"
            y1="0.27493"
            y2="0.94262"
          >
            <stop stopColor="#FDCBCB" />
            <stop offset="1" stopColor="#FFE9E9" stopOpacity={stopOpacity} />
          </linearGradient>
        </defs>
        <path
          d={`
            m ${5.3 * x} ${5.6 * y}
            l ${-6 * x} ${-4.6 * y}
            l 0 ${126.4 * y}
            l ${608.7 * x} 0
            l 0 ${-46.8 * y}
            c ${-24.8 * x} ${4.3 * y} ${-34.1 * x} ${-2.2 * y} ${-46 * x} ${-1.9 * y}
            c ${-11.9 * x} ${0.3 * y} ${-13.7 * x} ${-3.7 * y} ${-25.6 * x} ${-5.4 * y}
            c ${-11.9 * x} ${-1.7 * y} ${-23.9 * x} ${6.1 * y} ${-35.8 * x} ${1.4 * y}
            c ${-11.9 * x} ${-4.7 * y} ${-23.9 * x} ${-9.4 * y} ${-35.8 * x} ${-13 * y}
            c ${-11.9 * x} ${-3.5 * y} ${-23.9 * x} ${-1.7 * y} ${-35.8 * x} ${-3.5 * y}
            c ${-11.9 * x} ${-1.8 * y} ${-23.9 * x} ${-11.2 * y} ${-35.8 * x} ${-12.5 * y}
            c ${-11.9 * x} ${-1.3 * y} ${-23.9 * x} ${-0.5 * y} ${-35.8 * x} ${3.4 * y}
            c ${-11.9 * x} ${3.9 * y} ${-16.8 * x} ${4.6 * y} ${-28.7 * x} ${6.8 * y}
            c ${-11.9 * x} ${2.2 * y} ${-23.9 * x} ${1.7 * y} ${-35.8 * x} ${-1.4 * y}
            c ${-11.9 * x} ${-3.1 * y} ${-23.9 * x} ${-6.7 * y} ${-35.8 * x} ${-12.5 * y}
            c ${-11.9 * x} ${-5.8 * y} ${-23.9 * x} ${-11.8 * y} ${-35.8 * x} ${-10.8 * y}
            c ${-11.9 * x} ${0.9 * y} ${-23.9 * x} ${8.8 * y} ${-35.8 * x} ${6.8 * y}
            c ${-11.9 * x} ${-1.9 * y} ${-30.9 * x} ${-11.6 * y} ${-42.9 * x} ${-18 * y}
            c ${-11.9 * x} ${-6.4 * y} ${-23.9 * x} ${-7.5 * y} ${-35.8 * x} ${-1.3 * y}
            c ${-11.9 * x} ${6.2 * y} ${-23.9 * x} ${13.4 * y} ${-35.8 * x} ${16.6 * y}
            c ${-11.9 * x} ${3.2 * y} ${-23.9 * x} ${-1.9 * y} ${-35.8 * x} ${-10 * y}
            c ${-11.9 * x} ${-8.1 * y} ${-23.9 * x} ${-15.2 * y} ${-29.8 * x} ${-19.7 * y}
            z
          `}
          fill="url(#linear_gradient_min_negative)"
        />
        <path
          d={`
            m ${-0.6 * x} ${1 * y}
            l ${6 * x} ${4.6 * y}
            c ${6 * x} ${4.6 * y} ${17.9 * x} ${11.6 * y} ${29.8 * x} ${19.7 * y}
            c ${11.9 * x} ${8.1 * y} ${23.9 * x} ${13.2 * y} ${35.8 * x} ${10 * y}
            c ${11.9 * x} ${-3.2 * y} ${23.9 * x} ${-10.4 * y} ${35.8 * x} ${-16.6 * y}
            c ${11.9 * x} ${-6.2 * y} ${23.9 * x} ${-5.1 * y} ${35.8 * x} ${1.3 * y}
            c ${11.9 * x} ${6.4 * y} ${30.9 * x} ${16 * y} ${42.9 * x} ${18 * y}
            c ${11.9 * x} ${1.9 * y} ${23.9 * x} ${-5.9 * y} ${35.8 * x} ${-6.8 * y}
            c ${11.9 * x} ${-0.9 * y} ${23.9 * x} ${5 * y} ${35.8 * x} ${10.8 * y}
            c ${11.9 * x} ${5.8 * y} ${23.9 * x} ${9.4 * y} ${35.8 * x} ${12.5 * y}
            c ${11.9 * x} ${3.1 * y} ${23.9 * x} ${3.6 * y} ${35.8 * x} ${1.4 * y}
            c ${11.9 * x} ${-2.2 * y} ${16.8 * x} ${-3 * y} ${28.7 * x} ${-6.8 * y}
            c ${11.9 * x} ${-3.9 * y} ${23.9 * x} ${-4.6 * y} ${35.8 * x} ${-3.4 * y}
            c ${11.9 * x} ${1.3 * y} ${23.9 * x} ${10.7 * y} ${35.8 * x} ${12.5 * y}
            c ${11.9 * x} ${1.8 * y} ${23.9 * x} ${-0.1 * y} ${35.8 * x} ${3.5 * y}
            c ${11.9 * x} ${3.5 * y} ${23.9 * x} ${8.3 * y} ${35.8 * x} ${13 * y}
            c ${11.9 * x} ${4.7 * y} ${23.9 * x} ${-3.1 * y} ${35.8 * x} ${-1.4 * y}
            c ${11.9 * x} ${1.7 * y} ${13.7 * x} ${5.7 * y} ${25.6 * x} ${5.4 * y}
            c ${11.9 * x} ${-0.3 * y} ${21.2 * x} ${6.2 * y} ${46 * x} ${1.9 * y}
          `}
          stroke="#DC1828"
          strokeWidth={strokeWidth}
        />
      </>
    )}
    height={height}
    width={width}
  />
);

export default TrendChartMinNegative;
