import React from 'react';
import { useSelector } from 'react-redux';
import {
  skipToken,
} from '@reduxjs/toolkit/query/react';
import * as R from 'ramda';
import qs from 'query-string';
import { HIDEntityId } from '@house-id/houseid-types/dist/common';

import { HIDApiTags } from '../../../../../../../api/HIDApiTags';
import { PropertyId } from '../../../../../types/types.property';
import {
  HIDDocument,
  DocumentCategory,
} from '../types.document';
import {
  DeleteContentParams,
} from '../../../types/types.content';
import { CreateEntity } from '../../../../../../../types/common';
import { propertyApi } from '../../../../../api/api.property';
import { getMutationFixedCacheKey } from '../../../utils/cacheKeys';
import { provideArrayTags } from '../../../../../../../api/HIDBaseQuery';

export const documentApi = propertyApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllDocumentCategories: builder.query<Array<DocumentCategory>, PropertyId>({
      query: ({ propertyId }) => `/properties/${propertyId}/documents/categories`,
      transformResponse: (response: Array<DocumentCategory & { categoryId: string }>) => {
        const mapped = R.map((item) => ({
          id: item.categoryId,
          count: item.count,
          name: item.name,
          types: item.types,
        }), response);
        return R.sortBy(({ name }) => name, mapped);
      },
      providesTags: [HIDApiTags.DOCUMENT_CATEGORY],
    }),
    getAllDocuments: builder.query<Array<HIDDocument>, PropertyId>({
      query: ({ propertyId }) => `/properties/${propertyId}/documents`,
      transformResponse: (response: Array<HIDDocument>) => R.sortBy(({ name }) => name, response),
      providesTags: (result) => provideArrayTags(HIDApiTags.DOCUMENT, result),
    }),
    getDocument: builder.query<HIDDocument, { propertyId: string, id: string }>({
      query: ({ propertyId, id }) => `/properties/${propertyId}/documents/${id}`,
      providesTags: (_result, _error, arg) => [{ type: HIDApiTags.DOCUMENT, id: arg.id }],
    }),
    deleteDocuments: builder.mutation<Array<string>, DeleteContentParams>({
      query: ({ propertyId, ids }) => ({
        url: `/properties/${propertyId}/documents?${qs.stringify({ ids })}`,
        method: 'DELETE',
      }),
      transformErrorResponse: (response) => response.data,
      invalidatesTags: (_result, _error, arg) => [
        ...arg.ids.map((id) => ({ type: HIDApiTags.DOCUMENT as const, id })),
        HIDApiTags.DOCUMENT_CATEGORY,
        HIDApiTags.PROPERTY_DOCUMENTATION,
        HIDApiTags.PROPERTY_BLUEPRINTS,
        HIDApiTags.PROPERTY_PURCHASE,
        HIDApiTags.PROPERTY_PROGRESS,
        HIDApiTags.SELLING_PROPERTY_ARCHIVE,
        HIDApiTags.SELLING_PROPERTY_TRANSFER,
      ],
    }),
    createDocument: builder.mutation<HIDDocument, CreateEntity<HIDDocument> & PropertyId>({
      query: (document) => ({
        url: `/properties/${document.propertyId}/documents`,
        method: 'POST',
        body: document,
      }),
      invalidatesTags: () => [
        HIDApiTags.DOCUMENT_CATEGORY,
        HIDApiTags.DOCUMENT,
        HIDApiTags.CONTENT_FILE,
        HIDApiTags.PROPERTY_STATUS,
        HIDApiTags.DOCUMENT_CATEGORY,
        HIDApiTags.PROPERTY_DOCUMENTATION,
        HIDApiTags.PROPERTY_BLUEPRINTS,
        HIDApiTags.PROPERTY_PURCHASE,
        HIDApiTags.PROPERTY_PROGRESS,
        HIDApiTags.SELLING_PROPERTY_ARCHIVE,
        HIDApiTags.SELLING_PROPERTY_TRANSFER,
      ],
    }),
    updateDocument: builder.mutation<HIDDocument, Partial<HIDDocument> & PropertyId & HIDEntityId>({
      query: (document) => ({
        url: `/properties/${document.propertyId}/documents/${document.id}`,
        method: 'PATCH',
        body: document,
      }),
      transformErrorResponse: (response) => response.data,
      invalidatesTags: (_result, _error, arg) => [
        HIDApiTags.DOCUMENT_CATEGORY,
        { type: HIDApiTags.DOCUMENT as const, id: arg.id },
        HIDApiTags.CONTENT_FILE,
        HIDApiTags.PROPERTY_STATUS,
        HIDApiTags.PROPERTY_DOCUMENTATION,
        HIDApiTags.PROPERTY_BLUEPRINTS,
        HIDApiTags.PROPERTY_PURCHASE,
        HIDApiTags.PROPERTY_PROGRESS,
        HIDApiTags.SELLING_PROPERTY_ARCHIVE,
        HIDApiTags.SELLING_PROPERTY_TRANSFER,
      ],
    }),
  }),
});

export const {
  useGetAllDocumentCategoriesQuery,
  useGetAllDocumentsQuery,
  useDeleteDocumentsMutation,
  useCreateDocumentMutation,
  useUpdateDocumentMutation,
} = documentApi;

export const useGetDocumentWithCache = ({
  propertyId,
  documentId,
}: {
  propertyId?: string,
  documentId?: string,
}) => {
  const selectDocuments = React.useMemo(
    () => documentApi.endpoints.getAllDocuments.select(propertyId ? { propertyId } : skipToken),
    [propertyId],
  );

  const [
    _deleteDocuments,
    { isLoading: isDeleting, isSuccess: isDeleted },
  ] = useDeleteDocumentsMutation({
    fixedCacheKey: getMutationFixedCacheKey(documentId),
  });
  const isDeletingOrDeleted = isDeleting || isDeleted;

  const { data: cachedDocuments } = useSelector(selectDocuments);
  const cachedDocument = cachedDocuments?.find((document) => document.id === documentId);

  const {
    data: fetchedDocument,
    isLoading,
    isFetching,
    error,
  } = documentApi.useGetDocumentQuery(
    propertyId && documentId && !cachedDocument && !isDeletingOrDeleted
      ? { propertyId, id: documentId }
      : skipToken,
  );

  return {
    document: fetchedDocument || cachedDocument,
    isLoading,
    isFetching,
    error,
  };
};
