import React, {
  FC,
} from 'react';
import {
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import * as R from 'ramda';
import { ChartDataItem } from '@house-id/houseid-types/dist/finances/finances';

import { formatMoney } from '../../../../../../../utils/string';
import FinancesChart from '../../../components/FinancesChart';
import { FinancesFilterSectionMode } from '../../../types.finances';
import { TimeIntervalsGroupingType } from '../../../../Content/types/types.content';
import FinanceIcon from '../../../components/FinanceIcon';
import PiggyBank from '../../../../../../../components/icons/PiggyBank';
import { ChartDateLength } from '../../../utils.finances';

type BudgetComparisonChartProps = {
  data: Array<ChartDataItem>;
  groupingType: TimeIntervalsGroupingType;
  mode?: FinancesFilterSectionMode;
  isLoading: boolean;
  primaryLabel?: string;
  secondaryLabel?: string;
  footerInfo?: React.ReactNode,
  minHeight?: number;
  showIcons?: boolean;
  dateLength?: ChartDateLength;
};

const BudgetComparisonChart: FC<BudgetComparisonChartProps> = ({
  data,
  groupingType,
  mode = FinancesFilterSectionMode.Filter,
  isLoading,
  primaryLabel,
  secondaryLabel,
  footerInfo,
  minHeight,
  showIcons,
  dateLength,
}) => {
  const theme = useTheme();
  const { t } = useTranslation(['finances', 'forms_common']);

  const primaryTotal = R.sum(data.map((dataItem) => dataItem.primary.value));
  const primaryAverage = data.length ? primaryTotal / data.length : 0;

  const secondaryTotal = R.sum(data.map((dataItem) => dataItem.secondary?.value || 0));
  const secondaryAverage = data.length ? secondaryTotal / data.length : 0;

  const summaryIcon = (
    <FinanceIcon
      Icon={PiggyBank}
      iconBackgroundColor={theme.palette.primary.lighter}
      iconColor={theme.palette.primary.dark}
    />
  );

  const chartData = data.map((item) => ({
    primary: item.primary.value,
    secondary: mode === FinancesFilterSectionMode.Compare ? item.secondary?.value : undefined,
    line: mode === FinancesFilterSectionMode.Filter ? primaryAverage : item.line?.value,
    date: item.date,
  }));

  const getInfoLabel = (averageCost: number) => t('forms_common:per_month', { value: formatMoney(averageCost) });

  const infoDescription = groupingType === TimeIntervalsGroupingType.Yearly
    ? t('finances:the_average_calculation_is_made_on_complete_year')
    : groupingType === TimeIntervalsGroupingType.Monthly
      ? t('finances:the_average_calculation_is_made_on_complete_month')
      : t('finances:the_average_calculation_is_made_on_complete_quarter');

  return (
    <FinancesChart
      bars={[
        primaryLabel && {
          dataKey: 'primary',
          color: theme.palette.primary.main,
          name: primaryLabel,
        },
        mode === FinancesFilterSectionMode.Compare && secondaryLabel && {
          dataKey: 'secondary',
          color: theme.palette.warning.main,
          name: secondaryLabel,
        },
      ].filter(Boolean)}
      chartData={chartData}
      dateLength={dateLength}
      footerInfo={footerInfo}
      groupingType={groupingType}
      isLoading={isLoading}
      lineData={
        mode === FinancesFilterSectionMode.Filter
          ? { dataKey: 'line', name: t('forms_common:average_cost') }
          : undefined
      }
      minHeight={minHeight}
      summaryInfo={[
        primaryLabel && {
          Icon: showIcons ? summaryIcon : undefined,
          infoSubLabel: t('forms_common:average_cost'),
          infoLabel: getInfoLabel(primaryAverage),
          infoDescription,
          label: primaryLabel,
          value: formatMoney(primaryTotal),
        },
        mode === FinancesFilterSectionMode.Compare && secondaryLabel && {
          infoSubLabel: t('forms_common:average_cost'),
          infoLabel: getInfoLabel(secondaryAverage),
          infoDescription,
          label: secondaryLabel,
          value: formatMoney(secondaryTotal),
        },
      ].filter(Boolean)}
    />
  );
};

export default BudgetComparisonChart;
