import { skipToken } from '@reduxjs/toolkit/query';
import { PropertyValuationSource } from '@house-id/houseid-types/dist/finances/valuation';
import {
  PropertyCommonValuation,
  PropertyRealtorValuation,
} from '@house-id/houseid-types/dist/finances/finances';

import { QueryHook } from '../../../../../types/common';
import useGetCurrentPropertyId from '../../../hooks/useGetCurrentPropertyId';
import { useGetPropertyFinanceInformationQuery } from '../api/api.propertyFinance';

export type PreferredPropertyValuation = {
  type: PropertyValuationSource;
  valuation: PropertyCommonValuation | PropertyRealtorValuation;
};

type UseGetPropertyPreferredValuation = QueryHook<PreferredPropertyValuation | undefined>;

const useGetPropertyPreferredValuation: UseGetPropertyPreferredValuation = () => {
  const { data: propertyId } = useGetCurrentPropertyId();
  const {
    data: financeInformation,
    ...rest
  } = useGetPropertyFinanceInformationQuery(propertyId ? { propertyId } : skipToken);

  const preferredPropertyValuation = financeInformation && financeInformation.valuations
    ? financeInformation.valuations[financeInformation.preferredValuationSource]
    : undefined;

  return {
    ...rest,
    data: financeInformation && preferredPropertyValuation
      ? {
        type: financeInformation.preferredValuationSource,
        valuation: preferredPropertyValuation,
      }
      : undefined,
  };
};

export default useGetPropertyPreferredValuation;
