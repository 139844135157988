import React, {
  FC,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  Stack,
  SvgIcon,
} from '@mui/material';
import { throttle } from 'throttle-debounce';

import { fpsToMs } from '../../../../../../../../utils/time';

type ScalableSvgChartContainerProps = {
  Svg: FC<{ x: number, y: number }>;
  width: number;
  height: number;
};

const ScalableSvgChartContainer: FC<ScalableSvgChartContainerProps> = ({
  Svg,
  width: widthProp,
  height: heightProp,
}) => {
  const throttleTime = fpsToMs(24);

  const containerRef = useRef<HTMLDivElement>(null);

  const [size, setSize] = useState<{ width?: number, height?: number } | undefined>({ width: widthProp, height: heightProp });

  const updateWidth = () => setSize({
    width: containerRef.current?.clientWidth,
    height: containerRef.current?.clientHeight,
  });

  useEffect(
    () => {
      const handleResize = throttle(throttleTime, updateWidth);
      handleResize();

      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    },
    [],
  );

  const x = size?.width ? size.width / widthProp : 1;
  const y = size?.height ? size.height / heightProp : 1;

  const width = size?.width || widthProp;
  const height = size?.height || heightProp;

  return (
    <Stack ref={containerRef} sx={{ flex: 1 }}>
      <SvgIcon
        sx={{
          fill: 'none',
          width,
          height,
        }}
        viewBox={`0 0 ${width} ${height}`}
      >
        <Svg
          x={x}
          y={y}
        />
      </SvgIcon>
    </Stack>
  );
};

export default ScalableSvgChartContainer;
