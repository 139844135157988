import React from 'react';
import {
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Product } from '@house-id/houseid-types/dist/content/product';
import { EntityType } from '@house-id/houseid-types/dist/entityType';
import { PartnersSource } from '@house-id/houseid-types/dist/partners';
import {
  AnalyticsEvent,
  EventReportSource,
} from '@house-id/houseid-types/dist/analytics';

import { FCC } from '../../../../../../../../../types/common';
import Prisjakt from '../../../../../../../../../components/icons/Prisjakt';
import HIDLink from '../../../../../../../../../components/HIDLink';
import useGetLogAnalyticsEvent from '../../../../../../../../Analytics/hooks/useGetLogAnalyticsEvent';

type PrisjaktSectionProps = {
  product: Product,
};

const PrisjaktSection: FCC<PrisjaktSectionProps> = ({
  sx,
  product,
}) => {
  const { t } = useTranslation(['products']);
  const theme = useTheme();

  const logAnalyticsEvent = useGetLogAnalyticsEvent();

  const resellerBrandLink = product.links?.resellerBrandLink;
  const resellerCompanyLink = product.links?.resellerCompanyLink;

  const categoryProductLink = product.links?.categoryProductLink;
  const similarProductsLink = product.links?.similarProductsLink;
  const brandProductBrandLink = product.links?.brandProductBrandLink;

  const handleProductLinkClick = (source?: PartnersSource) => {
    logAnalyticsEvent({
      event: AnalyticsEvent.OPEN_PRODUCT_LINK,
      source,
      hidCategory: EntityType.PRODUCT,
      eventReportSource: EventReportSource.ALL,
    });
  };

  return (
    <Stack spacing={2.5} sx={sx}>
      <Stack direction="row" spacing={1}>
        <Prisjakt />
        <Typography variant="h6">
          {t('products:product_prisjakt')}
        </Typography>
      </Stack>
      <Stack
        spacing={1.5}
        style={{ marginLeft: theme.spacing(4) }}
        sx={{ minWidth: 0 }}
        onClick={() => { }}
      >
        {resellerBrandLink && (
          <HIDLink
            href={resellerBrandLink.url}
            label={t('products:buy_product_from_retailers')}
            target="_blank"
            onClick={(event) => {
              event.stopPropagation();
              handleProductLinkClick(resellerBrandLink.source);
            }}
          />
        )}
        {resellerCompanyLink && !resellerBrandLink && (
          <HIDLink
            href={resellerCompanyLink.url}
            label={t('products:compare_prices_on_prisjakt')}
            target="_blank"
            onClick={(event) => {
              event.stopPropagation();
              handleProductLinkClick(resellerCompanyLink.source);
            }}
          />
        )}
        {categoryProductLink && (
          <HIDLink
            href={categoryProductLink.url}
            label={t('products:compare_the_product')}
            target="_blank"
            onClick={(event) => {
              event.stopPropagation();
              handleProductLinkClick(categoryProductLink.source);
            }}
          />
        )}
        {similarProductsLink && (
          <HIDLink
            href={similarProductsLink.url}
            label={t('products:more_similar_products')}
            target="_blank"
            onClick={(event) => {
              event.stopPropagation();
              handleProductLinkClick(similarProductsLink.source);
            }}
          />
        )}
        {brandProductBrandLink && (
          <HIDLink
            href={brandProductBrandLink.url}
            label={t('products:all_brand_products', { brand: product.brand || t('products:brand') })}
            target="_blank"
            onClick={(event) => {
              event.stopPropagation();
              handleProductLinkClick(brandProductBrandLink.source);
            }}
          />
        )}
      </Stack>
    </Stack>
  );
};

export default PrisjaktSection;
