import React, { FC } from 'react';
import * as R from 'ramda';
import {
  Card,
  Stack,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import { getColorGradient } from '../../../../../../../../../utils/style';
import { BorderRadius } from '../../../../../../../../../constants/borderRadius';
import HIDTypography from '../../../../../../../../../components/HIDTypography';
import HIDButton from '../../../../../../../../../components/buttons/HIDButton';
import PurpleRocketImage from '../../../../../../../../../assets/images/purple_rocket.png';
import PurpleCloudImage from '../../../../../../../../../assets/images/purple_cloud.png';
import useBreakPointsSizes from '../../../../../../../../../hooks/useBreakpointsSizes';
import RouteNames from '../../../../../../../../../routes/RouteNames';

const ValuationSubscriptionPlanBanner: FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const {
    isDownXl,
    isDownLg,
    isDownMd,
    isDownSm,
  } = useBreakPointsSizes();

  const { t } = useTranslation(['subscriptions']);

  const imageHeight = R.cond([
    [() => isDownSm, R.always(0)],
    [() => isDownMd, R.always(160)],
    [() => isDownLg, R.always(200)],
    [R.T, R.always(183)],
  ])();

  return (
    <Card
      style={{ borderRadius: BorderRadius.sm }}
      sx={{
        background: getColorGradient(theme.palette.common.premiumDark, theme.palette.common.premium),
      }}
    >
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        sx={{
          gap: 2,
          position: 'relative',
        }}
      >
        {!isDownXl && (
          <img
            src={PurpleCloudImage}
            style={{
              height: imageHeight,
            }}
          />
        )}
        <Stack sx={{ gap: 2, padding: theme.spacing(3), paddingRight: 0 }}>
          <HIDTypography
            sx={{
              textAlign: 'center',
              color: theme.palette.common.white,
            }}
            variant="h5"
          >
            {t('subscriptions:subscription_get_your_value_indication_upgrade_to_ubercontrol')}
          </HIDTypography>
          <HIDButton
            color="yellow"
            sx={{ alignSelf: 'center' }}
            onClick={() => navigate(RouteNames.SUBSCRIPTION_PLANS)}
          >
            {t('subscriptions:subscription_upgrade_to_ubercontrol')}
          </HIDButton>
        </Stack>
        <img
          src={PurpleRocketImage}
          style={{ height: imageHeight }}
        />
      </Stack>
    </Card>
  );
};

export default ValuationSubscriptionPlanBanner;
