import React, { FC } from 'react';

import { TrendChartProps } from '../../types.valuation';
import ScalableSvgChartContainer from './ScalableSvgChartContainer';

const TrendChartMiddleNegative: FC<TrendChartProps> = ({
  width = 608,
  height = 128,
  strokeWidth = 2,
  stopOpacity = 0.25,
}) => (
  <ScalableSvgChartContainer
    Svg={({ x, y }) => (
      <>
        <defs>
          <linearGradient
            id="linear_gradient_middle_negative"
            x1="1"
            x2="1"
            y1="0.26965"
            y2="0.92453"
          >
            <stop stopColor="#FDCBCB" />
            <stop offset="1" stopColor="#FFE9E9" stopOpacity={stopOpacity} />
          </linearGradient>
        </defs>
        <path
          d={`
            m ${5.9 * x} ${1.4 * y}
            l ${-6 * x} ${-0.6 * y}
            l 0 ${126.8 * y}
            l ${608.4 * x} 0
            l 0 ${-64.3 * y}
            l ${-6 * x} ${0.9 * y}
            c ${-6 * x} ${0.9 * y} ${-17.9 * x} ${-3.8 * y} ${-29.8 * x} ${-0.2 * y}
            c ${-11.9 * x} ${-0.7 * y} ${-23.9 * x} ${-1.6 * y} ${-35.8 * x} ${-3.8 * y}
            c ${-11.9 * x} ${-2.2 * y} ${-23.9 * x} ${0.9 * y} ${-35.8 * x} ${-3.5 * y}
            c ${-11.9 * x} ${-4.4 * y} ${-23.9 * x} ${-7.6 * y} ${-35.8 * x} ${-11.2 * y}
            c ${-11.9 * x} ${-3.5 * y} ${-23.9 * x} ${-0.9 * y} ${-35.8 * x} ${-3.1 * y}
            c ${-11.9 * x} ${-2.2 * y} ${-23.9 * x} ${-2.8 * y} ${-35.8 * x} ${-4.6 * y}
            c ${-11.9 * x} ${-1.8 * y} ${-23.9 * x} ${-2.7 * y} ${-35.8 * x} ${-0.7 * y}
            c ${-11.9 * x} ${2 * y} ${-23.9 * x} ${2.7 * y} ${-35.8 * x} ${3.4 * y}
            c ${-11.9 * x} ${0.8 * y} ${-23.9 * x} ${1.6 * y} ${-35.8 * x} ${-1.6 * y}
            c ${-11.9 * x} ${-3.2 * y} ${-23.9 * x} ${-4 * y} ${-35.8 * x} ${-9.3 * y}
            c ${-11.9 * x} ${-0.9 * y} ${-23.9 * x} ${-6.3 * y} ${-35.8 * x} ${-6.5 * y}
            c ${-11.9 * x} ${-0.2 * y} ${-23.9 * x} ${4.8 * y} ${-35.8 * x} ${2.5 * y}
            c ${-11.9 * x} ${-2.3 * y} ${-23.9 * x} ${-5.5 * y} ${-35.8 * x} ${-11.2 * y}
            c ${-11.9 * x} ${-5.7 * y} ${-23.9 * x} ${-5.2 * y} ${-35.8 * x} ${-1.5 * y}
            c ${-11.9 * x} ${3.7 * y} ${-23.9 * x} ${6.5 * y} ${-35.8 * x} ${8 * y}
            c ${-11.9 * x} ${1.5 * y} ${-23.9 * x} ${-0.4 * y} ${-35.8 * x} ${-7.4 * y}
            c ${-11.9 * x} ${-7 * y} ${-23.9 * x} ${-8.2 * y} ${-29.8 * x} ${-12.1 * y}
          `}
          fill="url(#linear_gradient_middle_negative)"
        />
        <path
          d={`
            m ${-0.1 * x} ${0.8 * y}
            l ${5.9 * x} ${0.6 * y}
            c ${5.9 * x} ${3.9 * y} ${17.9 * x} ${5.1 * y} ${29.8 * x} ${12.1 * y}
            c ${11.9 * x} ${7 * y} ${23.9 * x} ${8.9 * y} ${35.8 * x} ${7.4 * y}
            c ${11.9 * x} ${-1.5 * y} ${23.9 * x} ${-4.2 * y} ${35.8 * x} ${-8 * y}
            c ${11.9 * x} ${-3.7 * y} ${23.9 * x} ${-4.2 * y} ${35.8 * x} ${1.5 * y}
            c ${11.9 * x} ${5.7 * y} ${23.9 * x} ${8.9 * y} ${35.8 * x} ${11.2 * y}
            c ${11.9 * x} ${2.3 * y} ${23.9 * x} ${-2.6 * y} ${35.8 * x} ${-2.5 * y}
            c ${11.9 * x} ${0.2 * y} ${23.9 * x} ${5.5 * y} ${35.8 * x} ${6.5 * y}
            c ${11.9 * x} ${5.2 * y} ${23.9 * x} ${6.1 * y} ${35.8 * x} ${9.3 * y}
            c ${11.9 * x} ${3.2 * y} ${23.9 * x} ${2.3 * y} ${35.8 * x} ${1.6 * y}
            c ${11.9 * x} ${-0.8 * y} ${23.9 * x} ${-1.4 * y} ${35.8 * x} ${-3.4 * y}
            c ${11.9 * x} ${-2 * y} ${23.9 * x} ${-1.1 * y} ${35.8 * x} ${0.7 * y}
            c ${11.9 * x} ${1.8 * y} ${23.9 * x} ${2.4 * y} ${35.8 * x} ${4.6 * y}
            c ${11.9 * x} ${2.2 * y} ${23.9 * x} ${-0.4 * y} ${35.8 * x} ${3.1 * y}
            c ${11.9 * x} ${3.5 * y} ${23.9 * x} ${6.8 * y} ${35.8 * x} ${11.2 * y}
            c ${11.9 * x} ${4.4 * y} ${23.9 * x} ${1.4 * y} ${35.8 * x} ${3.5 * y}
            c ${11.9 * x} ${2.2 * y} ${23.9 * x} ${3.1 * y} ${35.8 * x} ${3.8 * y}
            c ${11.9 * x} ${-3.6 * y} ${23.9 * x} ${1 * y} ${29.8 * x} ${0.2 * y}
            l ${5.9 * x} ${-0.9 * y}
          `}
          stroke="#DC1828"
          strokeWidth={strokeWidth}
        />
      </>
    )}
    height={height}
    width={width}
  />
);

export default TrendChartMiddleNegative;
