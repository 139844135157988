import React, { FC } from 'react';

import { TrendChartProps } from '../../types.valuation';
import ScalableSvgChartContainer from './ScalableSvgChartContainer';

const TrendChartLowNegative: FC<TrendChartProps> = ({
  width = 608,
  height = 128,
  strokeWidth = 2,
  stopOpacity = 0.25,
}) => (
  <ScalableSvgChartContainer
    Svg={({ x, y }) => (
      <>
        <defs>
          <linearGradient
            id="linear_gradient_low_negative"
            x1="1"
            x2="1"
            y1="0.26965"
            y2="0.92453"
          >
            <stop stopColor="#FDCBCB" />
            <stop offset="1" stopColor="#FFE9E9" stopOpacity={stopOpacity} />
          </linearGradient>
        </defs>
        <path
          d={`
            m ${547.6 * x} ${38.8 * y}
            c ${11.2 * x} ${3.9 * y} ${34.3 * x} ${-1.7 * y} ${60.5 * x} ${0.7 * y}
            l 0 ${88.2 * y}
            l ${-608.2 * x} 0
            l 0 ${-126.8 * y}
            c ${41.4 * x} 0 ${53.6 * x} ${8.8 * y} ${64.8 * x} ${10.7 * y}
            c ${21.6 * x} ${3.6 * y} ${61.8 * x} ${3.1 * y} ${85.4 * x} ${-0.4 * y}
            c ${11.2 * x} ${-1.7 * y} ${22.5 * x} ${-6.3 * y} ${33.7 * x} ${-7.1 * y}
            c ${28.8 * x} ${-2.2 * y} ${35.3 * x} ${12.3 * y} ${69.0 * x} ${9.3 * y}
            c ${46.7 * x} ${-4.2 * y} ${55.5 * x} ${19.4 * y} ${92.5 * x} ${17.7 * y}
            c ${20.9 * x} ${-1.0 * y} ${53.7 * x} ${-12.3 * y} ${74.5 * x} ${-8.1 * y}
            c ${11.2 * x} ${2.3 * y} ${22.5 * x} ${9.5 * y} ${33.7 * x} ${9.8 * y}
            c ${11.2 * x} ${0.3 * y} ${28.0 * x} ${-4.5 * y} ${39.2 * x} ${-7.3 * y}
            c ${24.9 * x} ${-6.4 * y} ${38.2 * x} ${7.6 * y} ${54.8 * x} ${13.3 * y}
          `}
          fill="url(#linear_gradient_low_negative)"
        />
        <path
          d={`
            m ${608.2 * x} ${39.6 * y}
            c ${-26.2 * x} ${-2.4 * y} ${-49.3 * x} ${3.1 * y} ${-60.5 * x} ${-0.7 * y}
            c ${-16.6 * x} ${-5.7 * y} ${-29.9 * x} ${-19.7 * y} ${-54.8 * x} ${-13.3 * y}
            c ${-11.2 * x} ${2.9 * y} ${-27.0 * x} ${7.6 * y} ${-39.2 * x} ${7.3 * y}
            c ${-11.2 * x} ${-0.3 * y} ${-22.5 * x} ${-7.5 * y} ${-33.7 * x} ${-9.8 * y}
            c ${-20.8 * x} ${-4.3 * y} ${-53.6 * x} ${7.1 * y} ${-74.5 * x} ${8.1 * y}
            c ${-37.0 * x} ${1.8 * y} ${-45.8 * x} ${-21.8 * y} ${-92.5 * x} ${-17.7 * y}
            c ${-33.7 * x} ${3.0 * y} ${-40.2 * x} ${-11.5 * y} ${-69.0 * x} ${-9.3 * y}
            c ${-11.2 * x} ${0.8 * y} ${-22.5 * x} ${5.5 * y} ${-33.7 * x} ${7.1 * y}
            c ${-23.6 * x} ${3.5 * y} ${-63.7 * x} ${4.0 * y} ${-85.4 * x} ${0.4 * y}
            c ${-11.2 * x} ${-1.9 * y} ${-23.4 * x} ${-10.7 * y} ${-64.8 * x} ${-10.7 * y}
          `}
          stroke="#DC1828"
          strokeWidth={strokeWidth}
        />
      </>
    )}
    height={height}
    width={width}
  />
);

export default TrendChartLowNegative;
