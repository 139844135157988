import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import PropertyProgressCategorySectionContainer from '../../../../PropertyProgress/components/PropertyProgressCategorySectionContainer';
import PropertyProgressCategorySectionContent from '../../../../PropertyProgress/components/PropertyProgressCategorySectionContent';
import {
  PropertyProgressCategorySectionProps,
  PropertyFinancesOperatingCostSectionData,
} from '../../../../PropertyProgress/types.propertyProgress';
import BudgetsOverview from '../../../../Finances/pages/financeOverview/components/BudgetsOverviewWidget';
import { getBudgetsOverviewPath } from '../../../../Finances/modules/Budgets/navigation.budgets';
import useGetCurrentPropertyId from '../../../../../hooks/useGetCurrentPropertyId';

const SellingPropertyInfoOperatingCosts: FC<PropertyProgressCategorySectionProps<PropertyFinancesOperatingCostSectionData>> = ({
  section,
}) => {
  const { t } = useTranslation(['forms_common', 'property']);
  const navigate = useNavigate();

  const { data: propertyId } = useGetCurrentPropertyId();

  const handleSeeMore = () => {
    if (propertyId) {
      navigate(getBudgetsOverviewPath({ propertyId }));
    }
  };

  return (
    <PropertyProgressCategorySectionContainer
      Content={
        <PropertyProgressCategorySectionContent
          viewAllButtonText={t('property:property_progress_property_operating_cost_section_add_operating_see_more')}
          onViewAllClick={handleSeeMore}
        >
          <BudgetsOverview />
        </PropertyProgressCategorySectionContent>
      }
      key={section.id}
      section={section}
    />
  );
};

export default SellingPropertyInfoOperatingCosts;
