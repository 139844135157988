import { SelectChangeEvent } from '@mui/material';
import { FormikProps } from 'formik';
import * as R from 'ramda';

type ParseFunc = (value: string) => string | number | boolean | undefined;

export const getHandleSetField = <T>(formik: FormikProps<T>) => (fieldId: string, parse: ParseFunc | undefined = R.identity) =>
  (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<string>) =>
    formik.setFieldValue(fieldId, parse(event.target.value));

export const removeUndefinedValues = <T extends Record<string, any>>(values: T) => R.pickBy((v: any) => v !== undefined, values);
