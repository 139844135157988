import React, {
  FC,
  useEffect,
  useState,
} from 'react';
import { PropertyValuationSource } from '@house-id/houseid-types/dist/finances/valuation';
import { useTranslation } from 'react-i18next';
import { skipToken } from '@reduxjs/toolkit/query';
import {
  Divider,
  Grid,
  Slider,
} from '@mui/material';
import * as R from 'ramda';
import { PropertyRealtorValuation } from '@house-id/houseid-types/dist/finances/finances';

import HIDSection from '../../../../../../../components/HIDSection';
import HIDTextField from '../../../../../../../components/HIDTextField';
import { VALUATION_MAX_VALUE } from '../../../constants.finance';
import useGetCurrentPropertyId from '../../../../../hooks/useGetCurrentPropertyId';
import {
  useGetPropertyFinanceInformationQuery,
  useGetPropertyFinanceSaleSummaryQuery,
} from '../../../api/api.propertyFinance';
import useGetPropertyPreferredValuation from '../../../hooks/useGetPropertyPreferredValuation';
import HIDTypography from '../../../../../../../components/HIDTypography';
import HIDFormSelect from '../../../../../../../components/HIDFormSelect';
import ValuationResultCard from './ValuationResultCard';
import { getValuationBrokerNameAndCompany } from '../utils.valuation';
import { usePatchPropertyFinanceInformationMutation } from '../../../api/api.finance';
import { Currency } from '../../../../../../../utils/string';
import { useDebounce } from 'usehooks-ts';

const ValuationCalculationSection: FC = () => {
  const { t } = useTranslation(['finances']);

  const { data: propertyId } = useGetCurrentPropertyId();

  const {
    data: preferredValuation,
    isLoading: preferredValuationIsLoading,
    isFetching: preferredValuationIsFetching,
    refetch: refetchPreferredValuation,
  } = useGetPropertyPreferredValuation();

  const {
    data: saleSummary,
    isLoading: saleSummaryIsLoading,
  } = useGetPropertyFinanceSaleSummaryQuery(propertyId ? { propertyId } : skipToken);

  const {
    data: financeInformation,
    isLoading: financeInformationIsLoading,
  } = useGetPropertyFinanceInformationQuery(propertyId ? { propertyId } : skipToken);

  const [
    patchPropertyFinanceInformation,
    { isLoading: propertyFinanceInformationIsPatching },
  ] = usePatchPropertyFinanceInformationMutation();

  const isLoading = preferredValuationIsLoading
    || preferredValuationIsFetching
    || saleSummaryIsLoading
    || financeInformationIsLoading
    || propertyFinanceInformationIsPatching;

  const [currentPropertyValuationSource, setCurrentPropertyValuationSource] = useState<string>(PropertyValuationSource.REALTOR);
  const [currentPurchasePrice, setCurrentPurchasePrice] = useState(0);
  const [currentMarketPrice, setCurrentMarketPrice] = useState(0);

  const debouncedMarketPrice = useDebounce(currentMarketPrice, 300);

  const valuation = preferredValuation?.valuation;

  const valuations = financeInformation?.valuations;
  const purchasePrice = saleSummary?.purchasePrice;
  const salePrice = saleSummary?.salePrice;

  const showValuationSourcePicker = valuations ? Boolean(R.values(valuations).filter(Boolean).length) : false;

  const purchasePriceAmount = purchasePrice?.amount || 0;
  const marketPrice = valuation?.amount || 0;

  useEffect(() => {
    setCurrentPurchasePrice(purchasePriceAmount);
    setCurrentMarketPrice(marketPrice);
  }, [purchasePriceAmount, marketPrice]);

  useEffect(() => {
    if (preferredValuation?.type) {
      setCurrentPropertyValuationSource(preferredValuation?.type);
    }
  }, [preferredValuation]);

  const handlePropertyValuationChanged = (preferredValuationSource: PropertyValuationSource) => {
    setCurrentPropertyValuationSource(preferredValuationSource);

    if (propertyId) {
      patchPropertyFinanceInformation({
        propertyId,
        financeInformation: {
          preferredValuationSource,
        },
      })
        .unwrap()
        .then(() => refetchPreferredValuation());
    }
  };

  useEffect(() => {
    if (propertyId) {
      setCurrentMarketPrice(debouncedMarketPrice);
      if (currentPropertyValuationSource === PropertyValuationSource.ESTIMATED && !propertyFinanceInformationIsPatching) {
        patchPropertyFinanceInformation({
          propertyId,
          financeInformation: {
            estimatedMarketValue: {
              amount: Math.round(debouncedMarketPrice),
              currency: Currency.code,
            },
          },
        });
      }
    }
  }, [debouncedMarketPrice, propertyId]);

  const valuationSources = [
    salePrice?.amount && {
      id: PropertyValuationSource.SALE_PRICE,
      name: t('finances:valuation_source_selling_price'),
    },
    valuations?.realtor?.amount && {
      id: PropertyValuationSource.REALTOR,
      name: t('finances:valuation_source_broker_valuation'),
    },
    valuations?.movesta?.amount && {
      id: PropertyValuationSource.MOVESTA,
      name: t('finances:valuation_source_value_indication'),
    },
    {
      id: PropertyValuationSource.ESTIMATED,
      name: t('finances:valuation_source_own_hypothetical_valuation'),
    },
  ].filter(Boolean);

  return (
    <HIDSection
      infoDescription={t('finances:value_calculation_description')}
      title={t('finances:value_calculation')}
    >
      <Grid container gap={2.5}>
        <Grid item xxs={12}>
          <ValuationResultCard
            brokerText={
              currentPropertyValuationSource === PropertyValuationSource.REALTOR
                ? getValuationBrokerNameAndCompany(valuation as PropertyRealtorValuation)
                : undefined
            }
            isLoading={isLoading}
            marketPrice={currentMarketPrice}
            purchasePrice={currentPurchasePrice}
            updatedAt={valuation?.updatedAt}
          />
        </Grid>
        <Grid item xxs={12}>
          <Divider />
        </Grid>
        {showValuationSourcePicker && (
          <Grid item xl={6} xxs={12}>
            <HIDFormSelect
              items={valuationSources}
              label={t('finances:valuation_form')}
              showHelperText={false}
              value={currentPropertyValuationSource}
              onChange={({ target }) => handlePropertyValuationChanged(target.value as PropertyValuationSource)}
            />
          </Grid>
        )}
        <Grid
          container
          item
          spacing={2.5}
          xxs={12}
        >
          <Grid item xl={6} xxs={12}>
            <HIDTypography variant="subtitle2">
              {t('finances:purchase_price')}
            </HIDTypography>
            <HIDTextField
              showHelperText={false}
              value={currentPurchasePrice.toString()}
              onChange={({ target }) => setCurrentPurchasePrice(target.value ? Number.parseInt(target.value, 10) : 0)}
            />
            <Slider
              max={VALUATION_MAX_VALUE}
              min={0}
              value={currentPurchasePrice}
              valueLabelDisplay="auto"
              onChange={(_event, value) => setCurrentPurchasePrice(value as number)}
            />
          </Grid>
          <Grid item xl={6} xxs={12}>
            <HIDTypography variant="subtitle2">
              {t('finances:market_value')}
            </HIDTypography>
            <HIDTextField
              showHelperText={false}
              value={currentMarketPrice.toString()}
              onChange={({ target }) => setCurrentMarketPrice(target.value ? Number.parseInt(target.value, 10) : 0)}
            />
            <Slider
              max={VALUATION_MAX_VALUE}
              min={0}
              value={currentMarketPrice}
              valueLabelDisplay="auto"
              onChange={(_event, value) => setCurrentMarketPrice(value as number)}
            />
          </Grid>
        </Grid>
      </Grid>
    </HIDSection>
  );
};

export default ValuationCalculationSection;
