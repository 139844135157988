import {
  HIDBlob,
  ModifyActionType,
} from '@house-id/houseid-types/dist/common';
import { EntityType } from '@house-id/houseid-types/dist/entityType';
import { EntityContentConnection } from '@house-id/houseid-types/dist/content/entityContentConnection';

export type TimelineFilter = {
  id: string;
  displayName: string;
};

export type TimelineEventAuthor = {
  id: string;
  name: string;
};

export enum TimelineEventStatus {
  OK = 'ok',
  ERROR = 'error',
  PENDING = 'pending',
}

export type TimelineEvent = {
  type: string;
  key: string;
  title: string;
  annotation?: string;
  dateHeader?: string;
  start: string;
  end?: string;
  author?: TimelineEventAuthor,
  entity: {
    id: string;
    propertyId: string;
    type: EntityType;
  },
  status?: TimelineEventStatus;
  blobs?: Array<HIDBlob>;
  connections?: Array<EntityContentConnection>;
};

export type CustomTimelineEventCategory = {
  id: string;
  name: string;
};

export type CustomTimelineEvent = {
  id: string;
  key: string;
  propertyId: string;
  title: string;
  categoryId: string;
  annotation?: string;
  author?: TimelineEventAuthor,
  type?: string;
  start: string;
  end?: string;
};

export type RenovationType = {
  id: string;
  name: string;
};

export type MaintenanceType = {
  id: string;
  name: string;
};

export type Upkeep = {
  id: string;
  title: string;
  date: string;
  annotation?: string;
};

export type UpkeepModify = Upkeep & {
  action: ModifyActionType;
};

export type GetStartedWizardData = {
  constructionYear?: number;
  completionDate?: string;
  purchaseDate?: string;
  accessDate?: string;
  moveInDate?: string;
  hasDoneRenovations: boolean;
  renovations?: Array<UpkeepModify>;
  hasDoneMaintenance: boolean;
  maintenance?: Array<UpkeepModify>;
  status: 'not-started' | 'started' | 'finished';
  activeStep?: number;
};
