import React from 'react';
import {
  Stack,
  Typography,
} from '@mui/material';
import { Add } from '@mui/icons-material';

import HIDLink from '../../../../../components/HIDLink';
import HIDButton from '../../../../../components/buttons/HIDButton';
import { FCC } from '../../../../../types/common';
import { getNoWrapStyle } from '../../../../../utils/style';
import useGetPropertyPermissions from '../../../hooks/useGetPropertyPermissions';

type PropertyProgressCategorySectionContentProps = {
  showButtons?: boolean;
  description?: string;
  viewAllButtonText?: string;
  onViewAllClick?: () => void;
  addButtonText?: string;
  onAddClick?: () => void
};

const PropertyProgressCategorySectionContent: FCC<PropertyProgressCategorySectionContentProps> = ({
  showButtons = true,
  description,
  children,
  viewAllButtonText,
  onViewAllClick,
  addButtonText,
  onAddClick,
}) => {
  const { data: { canCreate } = {} } = useGetPropertyPermissions();

  return (
    <Stack spacing={2}>
      {Boolean(description) && (
        <Typography>
          {description}
        </Typography>
      )}
      {children}
      {showButtons && (
        <Stack
          direction="row"
          flexWrap="wrap-reverse"
          justifyContent="flex-end"
          rowGap={2}
          spacing={2}
        >
          {viewAllButtonText !== undefined && onViewAllClick && (
            <HIDLink
              label={viewAllButtonText}
              onClick={onViewAllClick}
            />
          )}
          {onAddClick && canCreate && (
            <HIDButton
              Icon={Add}
              childrenContainerSx={getNoWrapStyle(1)}
              onClick={onAddClick}
            >
              {addButtonText}
            </HIDButton>
          )}
        </Stack>
      )}
    </Stack>
  );
};

export default PropertyProgressCategorySectionContent;
