import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import {
  PropertyFinancesOperatingCostSectionData,
  PropertyProgressCategorySectionProps,
} from '../../../types.propertyProgress';
import PropertyProgressCategorySectionContainer from '../../../components/PropertyProgressCategorySectionContainer';
import PropertyProgressCategorySectionContent from '../../../components/PropertyProgressCategorySectionContent';
import BudgetsOverview from '../../../../Finances/pages/financeOverview/components/BudgetsOverviewWidget';
import {
  getBudgetsOverviewPath,
} from '../../../../Finances/modules/Budgets/navigation.budgets';
import useGetCurrentPropertyId from '../../../../../hooks/useGetCurrentPropertyId';
import useGetHandleCreateBudget from '../../../../Finances/modules/Budgets/hooks/useGetHandleCreateBudget';

const PropertyFinancesOperatingCostSection: FC<PropertyProgressCategorySectionProps<PropertyFinancesOperatingCostSectionData>> = ({
  section,
}) => {
  const { t } = useTranslation(['forms_common', 'property']);
  const navigate = useNavigate();
  const { data: propertyId } = useGetCurrentPropertyId();

  const { budget } = section.data;
  const hasBudget = Boolean(budget);

  const handleSeeMore = () => {
    if (propertyId) {
      navigate(getBudgetsOverviewPath({ propertyId }));
    }
  };

  const [handleCreateBudget] = useGetHandleCreateBudget();
  const handleAddOperatingCost = () => {
    handleCreateBudget();
  };

  return (
    <PropertyProgressCategorySectionContainer
      Content={
        <PropertyProgressCategorySectionContent
          addButtonText={t('property:property_progress_property_operating_cost_section_add_operating_cost')}
          description={hasBudget ? undefined : t('property:property_progress_property_operating_cost_section_description')}
          viewAllButtonText={t('property:property_progress_property_operating_cost_section_add_operating_see_more')}
          onAddClick={handleAddOperatingCost}
          onViewAllClick={handleSeeMore}
        >
          <BudgetsOverview />
        </PropertyProgressCategorySectionContent>

      }
      key={section.id}
      section={section}
    />
  );
};

export default PropertyFinancesOperatingCostSection;
