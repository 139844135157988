import React from 'react';
import { useTheme } from '@mui/material';

import { Icon } from '../../types/common';
import HIDSvgIcon from './HIDSvgIcon';

const TrendChart: Icon = ({
  iconColor: colorProp,
  size,
  strokeWidth = 1,
}) => {
  const theme = useTheme();
  const color = colorProp || theme.palette.common.black;

  return (
    <HIDSvgIcon size={size}>
      <path
        d="M21 22a1 1 0 1 0 0-2v2ZM4 3a1 1 0 0 0-2 0h2Zm.092 17.782-.454.891.454-.891Zm-.874-.874.891-.454-.891.454ZM6 14a1 1 0 1 0 0 2v-2Zm4.333 1v1a1 1 0 0 0 1-1h-1Zm0-6V8a1 1 0 0 0-1 1h1Zm5.334 0h1a1 1 0 0 0-1-1v1Zm0 2.5h-1a1 1 0 0 0 1 1v-1Zm5.333 0v1a1 1 0 0 0 1-1h-1ZM22 7a1 1 0 1 0-2 0h2Zm-1 13H6.2v2H21v-2ZM4 17.8V3H2v14.8h2ZM6.2 20c-.577 0-.949 0-1.232-.024-.272-.022-.373-.06-.422-.085l-.908 1.782c.378.193.772.264 1.167.296.384.032.851.031 1.395.031v-2ZM2 17.8c0 .544 0 1.011.03 1.395.033.395.104.789.297 1.167l1.782-.908c-.025-.05-.063-.15-.085-.422C4 18.75 4 18.377 4 17.8H2Zm2.546 2.091a1 1 0 0 1-.437-.437l-1.782.908a3 3 0 0 0 1.311 1.311l.908-1.782ZM6 16h4.333v-2H6v2Zm5.333-1V9h-2v6h2Zm-1-5h5.334V8h-5.334v2Zm4.334-1v2.5h2V9h-2Zm1 3.5H21v-2h-5.333v2Zm6.333-1V7h-2v4.5h2Z"
        fill={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
      />
    </HIDSvgIcon>
  );
};

export default TrendChart;
