import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Stack,
  useTheme,
} from '@mui/material';
import { addMonths } from 'date-fns';

import { PiggyBank } from '../../../../../../../components/icons/Icons';
import useGetHandleCreateBudget from '../hooks/useGetHandleCreateBudget';
import FinancesEmptyStateCard from '../../../components/FinancesEmptyStateCard';
import FinancesChart from '../../../components/FinancesChart';
import { range } from '../../../../../../../utils/array';

const now = new Date();

const primaryValues = range(0, 5).map((index) => 5000 + ((index % 5) * 300 - 200));

const chartData = primaryValues
  .map((value, index) => {
    const date = addMonths(now, -index);
    return {
      primary: value,
      date: { year: date.getFullYear(), month: date.getMonth() + 1 },
    };
  });

const BudgetsEmptyStateCard: FC = () => {
  const theme = useTheme();
  const { t } = useTranslation(['finances']);

  const [handleCreateBudget, isLoading] = useGetHandleCreateBudget();

  return (
    <FinancesEmptyStateCard
      Icon={PiggyBank}
      addTitle={t('finances:add_budget')}
      color={theme.palette.info}
      isLoading={isLoading}
      subTitle={t('finances:no_budgets_card_subtitle')}
      title={t('finances:no_budgets_card_title')}
      onAdd={handleCreateBudget}
    >
      <Stack
        justifyContent="stretch"
        maxWidth={600}
        width="100%"
      >
        <FinancesChart
          bars={[
            {
              dataKey: 'primary',
              color: theme.palette.primary.main,
              name: '2024',
            },
          ].filter(Boolean)}
          chartData={chartData}
          minHeight={200}
          showGrid={false}
          showLegend={false}
          showTooltip={false}
          showXAxis={false}
          showYAxis={false}
          sx={{
            padding: 0,
            border: 'none',
          }}
        />
      </Stack>
    </FinancesEmptyStateCard>
  );
};

export default BudgetsEmptyStateCard;
