import RouteNames from '../../../../../../routes/RouteNames';
import { generateRoutePath } from '../../../../../../utils/routes';
import { PropertyIdNavigation } from '../../../../types/types.property';

type GetValuationOverviewPathParams = PropertyIdNavigation & { showIndicationValuationDialog?: boolean };
export const getValuationOverviewPath = (params: GetValuationOverviewPathParams) => generateRoutePath(
  RouteNames.VALUATION_OVERVIEW,
  {
    propertyId: params.propertyId,
    ...(params.showIndicationValuationDialog
      ? { showIndicationValuationDialog: params.showIndicationValuationDialog.toString() }
      : {}
    ),
  },
);

export const getCreateBrokerValuationPath = ({ propertyId }: PropertyIdNavigation) => generateRoutePath(
  RouteNames.VALUATION_CREATE,
  { propertyId },
);

export const getUpdateBrokerValuationPath = ({ id, propertyId }: { id: string } & PropertyIdNavigation) => generateRoutePath(
  RouteNames.VALUATION_UPDATE,
  { id, propertyId },
);

export const getBrokerValuationsPath = ({ propertyId }: PropertyIdNavigation) => generateRoutePath(
  RouteNames.VALUATIONS,
  { propertyId },
);

export const getValuationHistoryPath = ({ propertyId }: PropertyIdNavigation) => generateRoutePath(
  RouteNames.VALUATION_HISTORY,
  { propertyId },
);

export const getBookValuationMeetingPath = ({ propertyId }: PropertyIdNavigation) => generateRoutePath(
  RouteNames.VALUATION_BOOK_MEETING,
  { propertyId },
);
