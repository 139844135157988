import React, {
  FC,
  useMemo,
} from 'react';
import {
  SxProps,
  Theme,
} from '@mui/material';
import { HIDEntityId } from '@house-id/houseid-types/dist/common';

import { HIDBreadcrumbLink } from '../../../../../components/HIDBreadcrumbs';
import HomeLayout from './HomeLayout';
import HIDDataGrid, { HIDDataGridProps } from './HIDDataGrid';

const defaultGetRowId = (row: HIDEntityId) => row.id;

type HomeListLayoutProps = Omit<HIDDataGridProps, 'sx'> & {
  title: string;
  hideDataGridIfNoData?: boolean;
  TitleRightComponent?: React.ReactElement;
  breadcrumbsLinks?: Array<HIDBreadcrumbLink>;
  SideColumn?: React.ReactElement;
  DataGridToolbar?: React.ReactElement;
  ListHeaderComponent?: React.ReactElement;
  sideDrawerElements?: Array<React.ReactElement>;
  dataGridSx?: SxProps<Theme>;
  onBack?: () => void;
};

const HomeListLayout: FC<HomeListLayoutProps> = ({
  title = ' ',
  TitleRightComponent,
  breadcrumbsLinks,
  columns,
  rows,
  rowCount,
  dataGridSx,
  paginationModel,
  paginationMode,
  initialState,
  isLoading = false,
  hideDataGridIfNoData = false,
  isSelectionMode = false,
  hasPagination = true,
  disableColumnFilter = true,
  disableColumnMenu = true,
  SideColumn,
  DataGridToolbar,
  ListHeaderComponent,
  sideDrawerElements,
  getRowId = defaultGetRowId,
  onRowClick,
  onCellClick,
  onRowSelectionModelChange,
  onPaginationModelChange,
  onBack,
}) => {
  const DataGrid = useMemo(() => (
    <HIDDataGrid
      columns={columns}
      disableColumnFilter={disableColumnFilter}
      disableColumnMenu={disableColumnMenu}
      getRowId={getRowId}
      hasPagination={hasPagination}
      initialState={initialState}
      isLoading={isLoading}
      isSelectionMode={isSelectionMode}
      paginationMode={paginationMode}
      paginationModel={paginationModel}
      rowCount={rowCount}
      rows={rows}
      sx={dataGridSx}
      onCellClick={onCellClick}
      onPaginationModelChange={onPaginationModelChange}
      onRowClick={onRowClick}
      onRowSelectionModelChange={onRowSelectionModelChange}
    />
  ), [
    disableColumnFilter,
    disableColumnMenu,
    columns,
    rows,
    initialState,
    isLoading,
    isSelectionMode,
    hasPagination,
    dataGridSx,
    getRowId,
    onRowClick,
    onCellClick,
    onRowSelectionModelChange,
    onPaginationModelChange,
  ]);

  return (
    <HomeLayout
      SideColumn={SideColumn}
      TitleRightComponent={TitleRightComponent}
      breadcrumbsLinks={breadcrumbsLinks}
      isLoading={isLoading}
      sideDrawerElements={sideDrawerElements}
      title={title}
      onBack={onBack}
    >
      {ListHeaderComponent}
      {DataGridToolbar}
      {hideDataGridIfNoData && !rows?.length && !isLoading ? null : DataGrid}
    </HomeLayout>
  );
};

export default HomeListLayout;
