import React, {
  FC,
  ReactElement,
  useMemo,
  useState,
} from 'react';
import {
  FormGroup,
  List,
  ListItemButton,
  ListItemText,
  Stack,
  useTheme,
} from '@mui/material';
import { isValid } from 'date-fns';
import {
  useDebounce,
  useUpdateEffect,
} from 'usehooks-ts';
import * as R from 'ramda';
import { useTranslation } from 'react-i18next';
import {
  CheckBox,
  CheckBoxOutlineBlank,
} from '@mui/icons-material';
import { DatePickerProps } from '@mui/x-date-pickers';
import { EntityType } from '@house-id/houseid-types/dist/entityType';

import {
  DateTimeFormats,
  setMidday,
} from '../../../../../../utils/date';
import { FILTER_DEBOUNCE_TIME } from '../../../../../../constants/layout';
import {
  ContentCustomFilter,
  ContentFilters,
} from '../../types/types.content';
import HIDTypography from '../../../../../../components/HIDTypography';
import EntityTypeFiltersSection from './EntityTypeFiltersSection';
import TimeIntervalsSection from './TimeIntervalsSection';
import HIDDateRangePicker from '../../../../../../components/datePicker/HIDDateRangePicker';
import PurchasedByUserFilterSection from './PurchasedByUserFilterSection';
import { hidSpacing } from '../../../../../../utils/number';
import CustomFilterSection from './CustomFilterSection';

export type FiltersSectionProps = {
  showTitle?: boolean;
  showDateFilers?: boolean;
  showChartGroupingTypeFilers?: boolean;
  showColumnsVisibilityFilters?: boolean;
  showCustomFilers?: boolean;
  showEntityTypeFilters?: boolean;
  showPurchasedByFilters?: boolean;
  showColumnsSectionLabel?: boolean;
  entityTypes?: Array<EntityType>;
  filters: ContentFilters;
  HeaderComponent?: ReactElement;
  dateTimeFormat?: DateTimeFormats;
  datePickerViews?: DatePickerProps<Date>['views'];
  onChange: ({
    from,
    to,
    groupingType,
    columnsVisibilityMap,
    customFilters,
    entityIdsMap,
  }: ContentFilters) => void;
};

const FiltersSection: FC<FiltersSectionProps> = ({
  showTitle = true,
  showDateFilers = false,
  showChartGroupingTypeFilers = false,
  showColumnsVisibilityFilters = false,
  showCustomFilers = false,
  showEntityTypeFilters = false,
  showPurchasedByFilters = false,
  showColumnsSectionLabel,
  entityTypes,
  filters,
  dateTimeFormat,
  HeaderComponent,
  datePickerViews,
  onChange,
}) => {
  const theme = useTheme();
  const { t } = useTranslation(['common']);

  const [fromDate, setFromDate] = useState<Date | undefined>(filters.from ? new Date(filters.from) : undefined);
  const [toDate, setToDate] = useState<Date | undefined>(filters.to ? new Date(filters.to) : undefined);
  const [chartGroupingType, setChartGroupingType] = useState(filters.groupingType);
  const [columnsVisibilityMap, setColumnsVisibilityMap] = useState(filters.columnsVisibilityMap || {});
  const [customFilters, setCustomFilters] = useState(filters.customFilters || {});
  const [entityIdsMap, setEntityIdsMap] = useState({} as Record<EntityType, Array<string>>);
  const [boughtByUsers, setBoughtByUsers] = useState<{ names: Array<string>, isNone: boolean }>({ names: [], isNone: false });

  const filtersObject = useMemo(
    () => ({
      from: fromDate,
      to: toDate,
      groupingType: chartGroupingType,
      columnsVisibilityMap,
      customFilters,
      entityIdsMap,
      boughtByUsers,
    }),
    [
      fromDate,
      toDate,
      chartGroupingType,
      columnsVisibilityMap,
      customFilters,
      entityIdsMap,
      boughtByUsers,
    ],
  );

  const debouncedFilters = useDebounce(
    filtersObject,
    FILTER_DEBOUNCE_TIME,
  );

  useUpdateEffect(() => {
    if ((!debouncedFilters.from || isValid(debouncedFilters.from))
      && (!debouncedFilters.to || isValid(debouncedFilters.to))
    ) {
      onChange({
        from: debouncedFilters.from ? setMidday(debouncedFilters.from).toISOString() : undefined,
        to: debouncedFilters.to ? setMidday(debouncedFilters.to).toISOString() : undefined,
        groupingType: debouncedFilters.groupingType,
        columnsVisibilityMap,
        customFilters,
        entityIdsMap,
        boughtByUsers,
      });
    }
  }, [debouncedFilters]);

  const columnsVisibilityList = R.toPairs(columnsVisibilityMap);

  const handleChangeColumnVisibility = (id: string, visible: boolean) => setColumnsVisibilityMap(
    {
      ...columnsVisibilityMap,
      [id]: {
        ...columnsVisibilityMap[id],
        visible,
      },
    },
  );

  return (
    <Stack>
      {showTitle && <HIDTypography variant="h6">{t('common:filter')}</HIDTypography>}
      {HeaderComponent}
      {showDateFilers && (
        <HIDDateRangePicker
          datePickerViews={datePickerViews}
          dateTimeFormat={dateTimeFormat}
          from={fromDate}
          to={toDate}
          onChange={({ from, to }) => {
            setFromDate(from);
            setToDate(to);
          }}
        />
      )}
      {showCustomFilers && (
        <CustomFilterSection
          customFilters={filters.customFilters || ({} as ContentCustomFilter)}
          sx={{ marginTop: 2 }}
          onChange={setCustomFilters}
        />
      )}
      {showEntityTypeFilters && (
        <EntityTypeFiltersSection
          entityTypes={entityTypes || []}
          onChange={setEntityIdsMap}
        />
      )}
      {showPurchasedByFilters && (
        <PurchasedByUserFilterSection
          isNoneSelected={boughtByUsers.isNone}
          selectedNames={boughtByUsers.names}
          style={{ paddingTop: 0, marginTop: hidSpacing(-1) }}
          onChange={(names, isNone) => setBoughtByUsers({ names, isNone })}
        />
      )}
      {showChartGroupingTypeFilers && (
        <TimeIntervalsSection
          sx={{ mt: 2 }}
          timeIntervalGroupingType={chartGroupingType}
          onChange={setChartGroupingType}
        />
      )}
      {(showColumnsVisibilityFilters && (
        <FormGroup sx={{ marginTop: 2 }}>
          {showColumnsSectionLabel && (
            <HIDTypography variant="subtitle1">
              {`${t('common:columns')}`}
            </HIDTypography>
          )}
          <List>
            {
              columnsVisibilityList
                .map(
                  ([id, { label, visible }]) => (
                    <ListItemButton
                      key={id}
                      onClick={() => handleChangeColumnVisibility(id, !visible)}
                    >
                      <ListItemText primary={label} />
                      {
                        visible
                          ? <CheckBox sx={{ color: theme.palette.primary.main }} />
                          : <CheckBoxOutlineBlank />
                      }
                    </ListItemButton>
                  ),
                )
            }
          </List>
        </FormGroup>
      ))}
    </Stack>
  );
};

export default FiltersSection;
