import { propertyApi } from './api.property';

export const onboardingApi = propertyApi.injectEndpoints({
  endpoints: (builder) => ({
    getPropertyRealtors: builder.query<Array<{ id: string, name: string }>, void>({
      query: () => 'enums/realtors',
    }),
  }),
});

export const {
  useGetPropertyRealtorsQuery,
} = onboardingApi;
