import qs from 'query-string';
import { HIDEntityId } from '@house-id/houseid-types/dist/common';

import { HIDApiTags } from '../../../../../../../api/HIDApiTags';
import { PropertyId } from '../../../../../types/types.property';
import {
  ExpertCommunicationFormData,
  Message,
  MessageReadStatus,
} from '../types.message';
import { propertyApi } from '../../../../../api/api.property';
import { PaginationParams } from '../../../../../../../types/common';
import { provideArrayTags } from '../../../../../../../api/HIDBaseQuery';

type GetMessagesRequestParams = PropertyId & Partial<PaginationParams> & {
  from?: string;
  to?: string;
  readStatus?: MessageReadStatus;
  key?: string;
};

type GetMessagesResponse = {
  messages: Array<Message>;
  counts: {
    read: string;
    unread: string;
  };
};

type GetMessages = {
  messages: Array<Message>;
  totalCount: number;
  unreadCount: number;
};

const getMessageBulkActions = (actionName: string, ids: Array<string>) =>
  ids.map((id) => ({ action: actionName, messageId: id }));

export const messageApi = propertyApi.injectEndpoints({
  endpoints: (builder) => ({
    getMessages: builder.query<GetMessages, GetMessagesRequestParams>({
      query: ({
        propertyId,
        pageSize,
        offset,
        from,
        to,
        readStatus,
      }) => {
        const queryParams = qs.stringify({
          propertyId,
          pageSize,
          offset,
          from,
          to,
          readStatus,
        });

        return `/messages/?${queryParams}`;
      },
      providesTags: (result) => provideArrayTags(HIDApiTags.MESSAGE, result?.messages),
      transformResponse: (response: GetMessagesResponse) => ({
        messages: response.messages,
        totalCount: Number.parseInt(response.counts.read, 10) + Number.parseInt(response.counts.unread, 10),
        unreadCount: Number.parseInt(response.counts.unread, 10),
      }),
    }),
    getMessage: builder.query<Message, HIDEntityId>({
      query: ({ id }) => `/messages/${id}`,
      providesTags: (_result, _error, arg) => [{ type: HIDApiTags.MESSAGE, id: arg.id }],
    }),
    markAsRead: builder.mutation<Array<string>, { ids: Array<string>, skipInvalidate?: boolean }>({
      query: ({ ids }) => ({
        url: '/messages',
        method: 'PATCH',
        body: {
          actions: getMessageBulkActions('read', ids),
        },
      }),
      invalidatesTags: (_result, _error, arg) => !arg.skipInvalidate
        ? arg.ids.map((id) => ({ type: HIDApiTags.MESSAGE as const, id }))
        : [],
    }),
    markAsUnread: builder.mutation<Array<string>, { ids: Array<string>, skipInvalidate?: boolean }>({
      query: ({ ids }) => ({
        url: '/messages',
        method: 'PATCH',
        body: {
          actions: getMessageBulkActions('unread', ids),
        },
      }),
      invalidatesTags: (_result, _error, arg) => !arg.skipInvalidate
        ? arg.ids.map((id) => ({ type: HIDApiTags.MESSAGE as const, id }))
        : [],
    }),
    deleteMessages: builder.mutation<Array<string>, { ids: Array<string>, skipInvalidate?: boolean }>({
      query: ({ ids }) => ({
        url: `/messages?${qs.stringify({ ids })}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_result, _error, arg) => !arg.skipInvalidate
        ? arg.ids.map((id) => ({ type: HIDApiTags.MESSAGE as const, id }))
        : [],
    }),
    deleteMessageAttachments: builder.mutation<Array<string>, { messageId: string, ids: Array<string> }>({
      query: ({ ids, messageId }) => ({
        url: `/messages/${messageId}/blobs?${qs.stringify({ ids })}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_result, _error, arg) => [{ type: HIDApiTags.MESSAGE as const, id: arg.messageId }],
    }),
    moveMessageAttachments: builder.mutation<Array<string>, { messageId: string, ids: Array<string> }>({
      query: ({ ids, messageId }) => ({
        url: `/messages/${messageId}/blobs?${qs.stringify({ ids })}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_result, _error, arg) => [{ type: HIDApiTags.MESSAGE as const, id: arg.messageId }],
    }),
    getExpertCommunicationFormDynamicContent: builder.query<ExpertCommunicationFormData, { id: string }>({
      query: ({ id }) => `/dynamic-content/${id}`,
    }),
    saveExpertCommunicationForm: builder.mutation<void, { id: string, formValues: object, formParams: object }>({
      query: ({ id, formValues, formParams }) => ({
        url: `/partner/${id}/contact-form`,
        method: 'POST',
        body: { ...formValues, ...formParams },
      }),
    }),
  }),
});

export const {
  useGetMessagesQuery,
  useGetMessageQuery,
  useLazyGetMessagesQuery,
  useMarkAsReadMutation,
  useMarkAsUnreadMutation,
  useDeleteMessagesMutation,
  useDeleteMessageAttachmentsMutation,
  useGetExpertCommunicationFormDynamicContentQuery,
  useSaveExpertCommunicationFormMutation,
} = messageApi;
