import React from 'react';
import { Skeleton } from '@mui/material';

import { ICON_SIZE } from '../../constants/layout';
import { FCC } from '../../types/common';

type LoadingIconProps = {
  size?: number;
};

const LoadingIcon: FCC<LoadingIconProps> = ({
  style,
  sx,
  size = ICON_SIZE,
}) => (
  <Skeleton
    height={size}
    style={style}
    sx={sx}
    variant="rounded"
    width={size}
  />
);

export default LoadingIcon;
