import React, {
  FC,
} from 'react';
import {
  Box,
  Dialog,
  Skeleton,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { QRCodeCanvas } from 'qrcode.react';
import { skipToken } from '@reduxjs/toolkit/query';
import {
  AnalyticsEvent,
  EventReportSource,
  HIDEventCustomCategory,
} from '@house-id/houseid-types/dist/analytics';

import useBreakPointsSizes from '../../../../hooks/useBreakpointsSizes';
import {
  HIDDialogProps,
} from '../../../../types/common';
import { useGetCustomTokenQuery } from '../../../Auth/api/api.user';
import { getPropertyHomeDeepLink } from '../../../../utils/links';
import HouseIdLogoUrl from '../../../../assets/images/logo.png';
import HIDButton from '../../../../components/buttons/HIDButton';
import { openUrlNewTab } from '../../../../utils/file';
import useGetCurrentPropertyId from '../../hooks/useGetCurrentPropertyId';
import { useGetDynamicLinkShortQuery } from '../../api/api.dynamicLinks';
import useGetLogAnalyticsEvent from '../../../Analytics/hooks/useGetLogAnalyticsEvent';
import HIDDialogTitle from '../../../../components/dialogs/HIDDialogTitle';
import HIDDialogContent from '../../../../components/dialogs/HIDDialogContent';
import { PartnersSource } from '@house-id/houseid-types/dist/partners';

export type OpenMobileAppBaseDialogProps = HIDDialogProps & {
  title: string;
  subtitle: string;
  description: string;
  linkExtractor?: (token: string, propertyId: string) => string;
};

const QR_CODE_BIG_SCREENS_SIZE = 200;
const QR_CODE_SIZE = 150;

const QRCodesLoadingState = () => {
  const { isDownLg } = useBreakPointsSizes();

  const size = isDownLg ? QR_CODE_SIZE : QR_CODE_BIG_SCREENS_SIZE;

  return (
    <Skeleton height={size} variant="rectangular" width={size} />
  );
};

const OpenMobileAppBaseDialog: FC<OpenMobileAppBaseDialogProps> = ({
  title,
  subtitle,
  description,
  linkExtractor = getPropertyHomeDeepLink,
  open,
  onClose,
}) => {
  const theme = useTheme();
  const { t } = useTranslation(['common', 'dialogs']);

  const logAnalyticsEvent = useGetLogAnalyticsEvent();
  const { data: propertyId } = useGetCurrentPropertyId();

  const { isDownSm, isDownLg } = useBreakPointsSizes();

  const { data: token, isLoading } = useGetCustomTokenQuery();

  const handleClose = onClose;

  const { data: onboardingLinkWithCustomToken } = useGetDynamicLinkShortQuery(
    token && propertyId ? { link: linkExtractor(token, propertyId) } : skipToken,
  );

  const handleGoToTheApp = () => {
    if (onboardingLinkWithCustomToken) {
      openUrlNewTab(onboardingLinkWithCustomToken?.link);
      logAnalyticsEvent(
        {
          event: AnalyticsEvent.OPEN_APP_FROM_WEB_APP,
          source: PartnersSource.HOUSE_ID,
          eventReportSource: EventReportSource.GA,
          hidCategory: HIDEventCustomCategory.DEFAULT,
        },
      );
    }
  };

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={handleClose}
    >
      <HIDDialogTitle
        title={t('common:app_name')}
        onClose={handleClose}
      />
      <HIDDialogContent>
        <Stack
          alignItems="center"
          justifyContent="center"
          spacing={2}
          width="100%"
        >
          <Typography textAlign="center" variant="h3">
            {title}
          </Typography>
          <Typography textAlign="center" variant="body1">
            {subtitle}
          </Typography>
          {isDownSm
            ? (
              <HIDButton onClick={handleGoToTheApp}>
                {t('dialogs:open_mobile_app_open_link_title')}
              </HIDButton>
            )
            : (
              <>
                <Box
                  sx={{
                    display: 'flex',
                    padding: theme.spacing(2),
                    border: 4,
                    borderColor: theme.palette.primary.main,
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: 5,
                  }}
                >
                  {
                    !isLoading && onboardingLinkWithCustomToken
                      ? (
                        <QRCodeCanvas
                          imageSettings={{
                            height: 24,
                            width: 24,
                            excavate: true,
                            src: HouseIdLogoUrl,
                          }}
                          size={isDownLg ? QR_CODE_SIZE : QR_CODE_BIG_SCREENS_SIZE}
                          value={onboardingLinkWithCustomToken.link}
                        />
                      )
                      : <QRCodesLoadingState />
                  }
                </Box>
                <Typography textAlign="center" variant="body1">
                  {description}
                </Typography>
              </>
            )}
        </Stack>
      </HIDDialogContent>
    </Dialog>
  );
};

export default OpenMobileAppBaseDialog;
