import React, {
  FC,
  useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import { PartnersSource } from '@house-id/houseid-types/dist/partners';
import { EntityType } from '@house-id/houseid-types/dist/entityType';
import {
  AnalyticsEvent,
  EventReportSource,
  HIDEventCustomCategory,
} from '@house-id/houseid-types/dist/analytics';

import { HIDDialogProps } from '../../../../types/common';
import OpenMobileAppBaseDialog, { OpenMobileAppBaseDialogProps } from './OpenMobileAppBaseDialog';
import useGetLogAnalyticsEvent from '../../../Analytics/hooks/useGetLogAnalyticsEvent';

export enum UnavailableFeature {
  HOME_PROFILE = 'home_profile',
  SYNC_PROPERTY = 'sync_property',
  VERIFY_PROPERTY = 'verify_property',
  VIEW_ENTITY_CONNECTION = 'view_entity_connection',
  ADD_PROPERTY = 'add_property',
  PROPERTY_TRANSFER = 'property_transfer',
  UNKNOWN = 'unknown',
}

export type OpenMobileAppDialogProps = HIDDialogProps & {
  unavailableFeature: UnavailableFeature | EntityType;
  linkExtractor?: OpenMobileAppBaseDialogProps['linkExtractor'];
};

const OpenMobileAppDialog: FC<OpenMobileAppDialogProps> = ({
  open,
  unavailableFeature,
  linkExtractor,
  onClose,
}) => {
  const { t } = useTranslation(['dialogs']);
  const logAnalyticsEvent = useGetLogAnalyticsEvent();

  useEffect(() => {
    if (open) {
      logAnalyticsEvent(
        {
          event: AnalyticsEvent.REQUEST_FOR_FEATURE,
          source: PartnersSource.HOUSE_ID,
          eventReportSource: EventReportSource.GA,
          hidCategory: HIDEventCustomCategory.FEATURE_REQUEST,
          params: {
            feature: unavailableFeature,
          },
        },
      );
    }
  }, [open]);

  return (
    <OpenMobileAppBaseDialog
      description={t('dialogs:open_mobile_app_description')}
      linkExtractor={linkExtractor}
      open={open}
      subtitle={t('dialogs:open_mobile_app_subtitle')}
      title={t('dialogs:open_mobile_app_title')}
      onClose={onClose}
    />
  );
};

export default OpenMobileAppDialog;
