import React, {
  FC,
  useEffect,
  useState,
} from 'react';
import {
  Card,
  Stack,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  GridAlignment,
  GridColDef,
  GridValueGetterParams,
} from '@mui/x-data-grid';
import { useNavigate } from 'react-router';
import { useDebounce } from 'usehooks-ts';
import { ModifyActionType } from '@house-id/houseid-types/dist/common';
import * as R from 'ramda';
import { ExpenseTransaction } from '@house-id/houseid-types/dist/finances/recurringExpenses';
import { ChartDataItem } from '@house-id/houseid-types/dist/finances/finances';
import { skipToken } from '@reduxjs/toolkit/query';
import { EntityType } from '@house-id/houseid-types/dist/entityType';

import useGetCurrentPropertyId from '../../../../../hooks/useGetCurrentPropertyId';
import { getHomePath } from '../../../../../navigation/navigation.property';
import {
  useNavigateBackOr,
  useNavigationParams,
} from '../../../../../../../utils/routes';
import HomeListLayout from '../../../../../pages/Home/components/HomeListLayout';
import { formatMoney } from '../../../../../../../utils/string';
import {
  DateTimeFormats,
  compareByDate,
  formatDate,
} from '../../../../../../../utils/date';
import {
  useDeleteRecurringExpenseMutation,
  useGetRecurringExpensesBankAccountsQuery,
  useGetRecurringExpensesComparisonQuery,
  useGetRecurringExpensesFiltrationQuery,
} from '../api/api.recurringExpenses';
import ExpensesComparisonChart from '../components/ExpensesComparisonChart';
import RecurringExpensesFilterSection, { RecurringExpensesFilters } from '../components/RecurringExpensesFilterSection';
import useGetBudgetsList from '../../Budgets/hooks/useGetBudgetsList';
import { TimeIntervalsGroupingType } from '../../../../Content/types/types.content';
import { getFinancesPath } from '../../../navigation.finances';
import {
  getRecurringExpensesCategoriesPath,
  getRecurringExpenseCategoryPath,
  getUpdateRecurringExpensePath,
} from '../navigation.recurringExpenses';
import { getPathWithPropertyIdOrInit } from '../../../../../../Auth/navigation/navigation.auth';
import useGetEnum from '../../../../../../../hooks/useGetEnum';
import { EnumType } from '../../../../../../../types/common';
import { ALL_RECURRING_EXPENSES_CATEGORY } from '../../../constants.finance';
import ViewEntityActions from '../../../../Content/components/actions/ViewEntityActions';
import { FILTER_DEBOUNCE_TIME } from '../../../../../../../constants/layout';
import { FinancesFilterSectionMode } from '../../../types.finances';
import ExpenseInformationSection from '../components/ExpenseInformationSection';
import ExpenseInsightsSection from '../components/ExpenseInsightsSection';
import ExpenseDetailedInformationSection from '../components/ExpenseDetailedInformationSection';
import useBreakPointsSizes from '../../../../../../../hooks/useBreakpointsSizes';
import useGetComparisonColumns from '../hooks/useGetComparisonColumns';
import {
  getComparisonList,
  getFromToFullMonthDateRange,
  getLastSyncedFromBankAccounts,
  getYearsListFromBankAccounts,
} from '../utils.recurringExpenses';
import useDialog from '../../../../../../../hooks/useDialog';
import DialogNames from '../../../../../../../hooks/useDialog/DialogNames';
import { useGetBudgetsQuery } from '../../Budgets/api/api.budgets';

type TransactionWithLast = {
  id: string;
  transaction: ExpenseTransaction;
  last: ExpenseTransaction;
};

const RecurringExpenseTransactions: FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation(['forms_common', 'common', 'finances']);
  const { isDownSm } = useBreakPointsSizes();

  const {
    routeParams: { id: expenseId },
    queryParams: { categoryId: routeCategoryId } = {},
  } = useNavigationParams<{ id: string, }, { categoryId?: string }>();

  const navigateBackOr = useNavigateBackOr();
  const { data: propertyId } = useGetCurrentPropertyId();

  const [deleteRecurringExpense, { isLoading: isDeleting }] = useDeleteRecurringExpenseMutation();

  const handleGoBack = () => navigateBackOr(propertyId ? getHomePath({ propertyId }) : undefined);

  const { data: bankAccounts = [] } = useGetRecurringExpensesBankAccountsQuery();
  const { data: budgetsList } = useGetBudgetsList();

  const yearsList = getYearsListFromBankAccounts(bankAccounts);

  const { data: ExpensePaymentCategoryEnum } = useGetEnum(EnumType.ExpensePaymentCategory);

  const { from: fromDateInitial } = getFromToFullMonthDateRange(12);

  const [filters, setFilters] = useState<RecurringExpensesFilters>({
    mode: FinancesFilterSectionMode.Filter,
    fromDate: fromDateInitial,
    toDate: undefined,
    fromYear: R.last(yearsList),
    toYear: undefined,
    timeIntervalGroupingType: TimeIntervalsGroupingType.Monthly,
    includeIncompleteMonths: true,
  });

  const lastSyncedAt = getLastSyncedFromBankAccounts(bankAccounts, filters.bankAccountIds);

  useEffect(() => {
    if (!filters.fromYear && yearsList?.length) {
      setFilters({
        ...filters,
        fromYear: R.last(yearsList),
      });
    }
  }, [yearsList]);

  const debouncedFilters = useDebounce(
    filters,
    FILTER_DEBOUNCE_TIME,
  );

  const {
    data: budgets = [],
  } = useGetBudgetsQuery(propertyId ? { propertyId } : skipToken);

  const {
    data: filtration,
    isLoading: isFiltrationLoading,
    isFetching: isFiltrationFetching,
  } = useGetRecurringExpensesFiltrationQuery({
    from: debouncedFilters.fromDate?.toISOString(),
    to: debouncedFilters.toDate?.toISOString(),
    accounts: debouncedFilters.bankAccountIds,
    grouping: debouncedFilters.timeIntervalGroupingType,
    includeIncompleteMonths: debouncedFilters.includeIncompleteMonths,
    expenses: expenseId,
  }, { skip: debouncedFilters.mode === FinancesFilterSectionMode.Compare });

  const {
    data: comparison,
    isLoading: isComparisonLoading,
    isFetching: isComparisonFetching,
  } = useGetRecurringExpensesComparisonQuery({
    year: debouncedFilters.fromYear,
    compareToYear: debouncedFilters.toYear,
    fromMonth: debouncedFilters.fromMonth,
    toMonth: debouncedFilters.toMonth,
    budget: debouncedFilters.budgetId,
    accounts: debouncedFilters.bankAccountIds,
    grouping: debouncedFilters.timeIntervalGroupingType,
    includeIncompleteMonths: debouncedFilters.includeIncompleteMonths,
    expenses: expenseId,
  }, { skip: debouncedFilters.mode === FinancesFilterSectionMode.Filter });

  const isLoading = isFiltrationLoading || isComparisonLoading;
  const isFetching = isFiltrationFetching || isComparisonFetching;
  const isLoadingOrFetching = isLoading || isFetching;

  const selectedBudget = filters.budgetId ? budgets?.find((item) => item.id === filters.budgetId) : undefined;
  const selectedBudgetName = selectedBudget?.name;

  const costsLabel: string = t('finances:costs');

  const expense = filtration?.expenses?.[0];
  const categoryId = routeCategoryId || expense?.category;

  const categoryLabel = !categoryId || categoryId === ALL_RECURRING_EXPENSES_CATEGORY
    ? `${t('common:all_label')} ${costsLabel.toLowerCase()}`
    : (ExpensePaymentCategoryEnum || {})[categoryId];

  const filtrationColumns: Array<GridColDef> = [
    {
      field: 'date',
      headerName: t('finances:payment_date'),
      flex: 0.25,
      type: 'string',
      sortable: true,
      sortComparator: compareByDate,
      valueGetter: (params: GridValueGetterParams) => {
        const { transaction } = params.row as TransactionWithLast;
        return formatDate(new Date(transaction?.date), DateTimeFormats.DATE_ONLY);
      },
    },
    !isDownSm && ({
      field: 'lastTransactionDate',
      headerName: t('finances:last_paid'),
      flex: 0.25,
      type: 'string',
      sortable: false,
      align: 'right' as GridAlignment,
      headerAlign: 'right' as GridAlignment,
      valueGetter: (params: GridValueGetterParams) => {
        const { last } = params?.row as TransactionWithLast;
        return formatDate(new Date(last?.date), DateTimeFormats.DATE_ONLY);
      },
    }),
    !isDownSm && ({
      field: 'lastTransactionAmount',
      headerName: t('finances:last_amount'),
      flex: 0.25,
      type: 'string',
      sortable: false,
      align: 'right' as GridAlignment,
      headerAlign: 'right' as GridAlignment,
      valueGetter: (params: GridValueGetterParams) => {
        const { last } = params?.row as TransactionWithLast;
        return formatMoney(last?.amount?.value, true);
      },
    }),
    {
      field: 'amount',
      headerName: t('finances:amount'),
      flex: 0.25,
      type: 'string',
      sortable: true,
      sortComparator: (a: { value: number }, b: { value: number }) => a.value - b.value,
      align: 'right' as GridAlignment,
      headerAlign: 'right' as GridAlignment,
      valueGetter: (params: GridValueGetterParams) => {
        const { transaction } = params?.row as TransactionWithLast;
        return formatMoney(Math.abs(transaction?.amount?.value), true);
      },
    },
  ].filter(Boolean);

  const comparisonColumns = useGetComparisonColumns();

  const handleUpdate = () => navigate(getPathWithPropertyIdOrInit(getUpdateRecurringExpensePath, { propertyId, id: expenseId }));

  const comparisonChartData = comparison?.chart?.map(
    debouncedFilters.budgetId
      ? (chartDataItem: ChartDataItem) => ({ ...chartDataItem, secondary: chartDataItem.line })
      : R.identity,
  );

  const chartData = debouncedFilters.mode === FinancesFilterSectionMode.Filter
    ? filtration?.chart
    : comparisonChartData;

  const filtrationExpense: Array<TransactionWithLast> = expense?.transactions?.map((transaction) => ({
    id: transaction.id,
    transaction,
    last: expense?.lastTransaction,
  })) || [];

  const comparisonExpense = getComparisonList(
    comparisonChartData || [],
    debouncedFilters.toYear || selectedBudget?.year,
    debouncedFilters.timeIntervalGroupingType,
  );

  const rows = debouncedFilters.mode === FinancesFilterSectionMode.Filter ? filtrationExpense : comparisonExpense || [];

  const [openConfirmDelete, closeConfirmDeleteDialog] = useDialog(DialogNames.YES_NO_CANCEL_DIALOG);

  const handleDelete = () => openConfirmDelete({
    fullScreen: false,
    title: t('common:are_you_sure_you_want_to_delete'),
    loading: isDeleting,
    description: expense?.label,
    onYes: () => deleteRecurringExpense({ id: expenseId, action: ModifyActionType.DELETE })
      .then(() => handleGoBack()),
    onNo: closeConfirmDeleteDialog,
  });

  const [openManageConnectionsDialog] = useDialog(DialogNames.MANAGE_CONTENT_CONNECTIONS_DIALOG);
  const handleManageConnections = () => {
    if (expense) {
      openManageConnectionsDialog({
        entityType: EntityType.RECURRING_EXPENSE,
        entity: expense,
      });
    }
  };

  return (
    <HomeListLayout
      ListHeaderComponent={
        <Stack spacing={3}>
          <ExpensesComparisonChart
            data={chartData || []}
            groupingType={filters.timeIntervalGroupingType}
            isLoading={isLoadingOrFetching}
            lastSyncedAt={lastSyncedAt}
            mode={filters.mode}
            primaryLabel={filters.fromYear ? `${t('forms_common:total')} ${filters.fromYear.toString()}` : undefined}
            secondaryLabel={
              filters.toYear
                ? `${t('forms_common:total')} ${filters.toYear.toString()}`
                : filters.budgetId
                  ? `${t('forms_common:total')} ${selectedBudgetName}`
                  : undefined
            }
          />
          {expense !== undefined && (
            <>
              <ExpenseInformationSection expense={expense} />
              <ExpenseInsightsSection expense={expense} />
              <ExpenseDetailedInformationSection expense={expense} />
            </>
          )}
        </Stack>
      }
      SideColumn={
        <>
          <Card sx={{ padding: 2 }}>
            <ViewEntityActions
              onDelete={handleDelete}
              onManageConnections={handleManageConnections}
              onUpdate={handleUpdate}
            />
          </Card>
          <Card sx={{ padding: 2 }}>
            <RecurringExpensesFilterSection
              bankAccounts={bankAccounts}
              budgetsList={budgetsList}
              filters={filters}
              yearsList={yearsList}
              onChange={setFilters}
            />
          </Card>
        </>
      }
      breadcrumbsLinks={[
        {
          link: getPathWithPropertyIdOrInit(getFinancesPath, { propertyId }),
          name: t('finances:finances'),
        },
        {
          link: getPathWithPropertyIdOrInit(getRecurringExpensesCategoriesPath, { propertyId }),
          name: t('finances:fixed_expenses'),
        },
        (categoryId || expense?.category) && {
          link: getPathWithPropertyIdOrInit(getRecurringExpenseCategoryPath, { propertyId, categoryId: categoryId || expense?.category }),
          name: categoryLabel,
        },
      ].filter(Boolean)}
      columns={debouncedFilters.mode === FinancesFilterSectionMode.Filter ? filtrationColumns : comparisonColumns}
      initialState={{
        sorting: {
          sortModel: [
            {
              field: 'date',
              sort: 'desc',
            },
          ],
        },
      }}
      isLoading={isLoadingOrFetching}
      rows={rows}
      sideDrawerElements={
        [
          <ViewEntityActions
            key={ViewEntityActions.name}
            onDelete={handleDelete}
            onManageConnections={handleManageConnections}
            onUpdate={handleUpdate}
          />,
          <RecurringExpensesFilterSection
            bankAccounts={bankAccounts}
            budgetsList={budgetsList}
            filters={filters}
            key={RecurringExpensesFilterSection.name}
            yearsList={yearsList}
            onChange={setFilters}
          />,
        ]
      }
      title={expense?.label || ' '}
      onBack={handleGoBack}
    />
  );
};

export default RecurringExpenseTransactions;
