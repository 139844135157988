import React, {
  FC,
  useEffect,
  useState,
} from 'react';
import {
  Card,
  Stack,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  GridAlignment,
  GridColDef,
  GridEventListener,
  GridRenderCellParams,
  GridValueGetterParams,
} from '@mui/x-data-grid';
import { useNavigate } from 'react-router';
import { useDebounce } from 'usehooks-ts';
import * as R from 'ramda';
import { RecurringExpense } from '@house-id/houseid-types/dist/finances/recurringExpenses';
import { ChartDataItem } from '@house-id/houseid-types/dist/finances/finances';

import useGetCurrentPropertyId from '../../../../../hooks/useGetCurrentPropertyId';
import { getHomePath } from '../../../../../navigation/navigation.property';
import {
  useNavigateBackOr,
  useRouteParams,
} from '../../../../../../../utils/routes';
import RecurringExpensesActions from '../components/RecurringExpensesActions';
import useGetEnum from '../../../../../../../hooks/useGetEnum';
import { EnumType } from '../../../../../../../types/common';
import { LIST_CHEVRON_COLUMN_CONFIG } from '../../../../../../../constants/columns';
import useBreakPointsSizes from '../../../../../../../hooks/useBreakpointsSizes';
import HomeListLayout from '../../../../../pages/Home/components/HomeListLayout';
import { formatMoney } from '../../../../../../../utils/string';
import {
  DateTimeFormats,
  formatDate,
} from '../../../../../../../utils/date';
import { ALL_RECURRING_EXPENSES_CATEGORY } from '../../../constants.finance';
import {
  useGetRecurringExpensesBankAccountsQuery,
  useGetRecurringExpensesComparisonQuery,
  useGetRecurringExpensesFiltrationQuery,
} from '../api/api.recurringExpenses';
import HIDTypography from '../../../../../../../components/HIDTypography';
import {
  getRecurringExpensePath,
  getRecurringExpensesCategoriesPath,
} from '../navigation.recurringExpenses';
import { getPathWithPropertyIdOrInit } from '../../../../../../Auth/navigation/navigation.auth';
import ExpensesPaymentCategoriesSection from '../components/ExpensesPaymentCategoriesSection';
import RecurringExpensesFilterSection, { RecurringExpensesFilters } from '../components/RecurringExpensesFilterSection';
import useGetBudgetsList from '../../Budgets/hooks/useGetBudgetsList';
import { TimeIntervalsGroupingType } from '../../../../Content/types/types.content';
import { FILTER_DEBOUNCE_TIME } from '../../../../../../../constants/layout';
import ExpensesComparisonChart from '../components/ExpensesComparisonChart';
import { getFinancesPath } from '../../../navigation.finances';
import { FinancesFilterSectionMode } from '../../../types.finances';
import useGetComparisonColumns from '../hooks/useGetComparisonColumns';
import {
  getComparisonList,
  getFromToFullMonthDateRange,
  getLastSyncedFromBankAccounts,
  getYearsListFromBankAccounts,
} from '../utils.recurringExpenses';
import { useGetBudgetsQuery } from '../../Budgets/api/api.budgets';
import { skipToken } from '@reduxjs/toolkit/query';

type UseGetRecurringExpensesDataGridColumns = (props: {
  isSelectionMode: boolean,
}) => Array<GridColDef>;

export const useGetRecurringExpensesDataGridColumns: UseGetRecurringExpensesDataGridColumns = ({
  isSelectionMode,
}) => {
  const { t } = useTranslation(['common', 'finances']);
  const { isDownSm } = useBreakPointsSizes();
  const { data: ExpensePaymentCategoryEnum } = useGetEnum(EnumType.ExpensePaymentCategory);

  const columns: Array<GridColDef> = [
    {
      field: 'id',
      headerName: t('common:name'),
      flex: 0.25,
      type: 'string',
      sortable: true,
      valueGetter: (params: GridValueGetterParams) => params?.row as RecurringExpense,
      sortComparator: (a: RecurringExpense, b: RecurringExpense) => a.label?.localeCompare(b.label),
      renderCell: (params: GridRenderCellParams) => {
        const { label, category } = params.row as RecurringExpense;
        return (
          <Stack direction="column" spacing={0.5}>
            <HIDTypography noWrap variant="subtitle2">
              {label}
            </HIDTypography>
            <HIDTypography noWrap variant="body1">
              {(ExpensePaymentCategoryEnum || {})[category]}
            </HIDTypography>
          </Stack>
        );
      },
    },
    !isDownSm && ({
      field: 'lastTransactionDate',
      headerName: t('finances:last_paid'),
      flex: 0.25,
      type: 'string',
      sortable: true,
      align: 'right' as GridAlignment,
      headerAlign: 'right' as GridAlignment,
      valueGetter: (params: GridValueGetterParams) => {
        const expense = params?.row as RecurringExpense;
        return formatDate(new Date(expense.lastTransaction.date), DateTimeFormats.DATE_ONLY);
      },
    }),
    !isDownSm && ({
      field: 'lastTransactionAmount',
      headerName: t('finances:last_amount'),
      flex: 0.25,
      type: 'string',
      sortable: true,
      align: 'right' as GridAlignment,
      headerAlign: 'right' as GridAlignment,
      valueGetter: (params: GridValueGetterParams) => {
        const expense = params?.row as RecurringExpense;
        return formatMoney(expense.lastTransaction.amount.value, true);
      },
    }),
    {
      field: 'totalAmount',
      headerName: t('finances:total_amount'),
      flex: 0.25,
      type: 'string',
      sortable: true,
      align: 'right' as GridAlignment,
      headerAlign: 'right' as GridAlignment,
      renderCell: (params: GridRenderCellParams) => {
        const expense = params?.row as RecurringExpense;
        return (
          <HIDTypography variant="subtitle1">
            {(formatMoney(expense.totalAmount, false))}
          </HIDTypography>
        );
      },
    },
    !isSelectionMode && LIST_CHEVRON_COLUMN_CONFIG,
  ].filter(Boolean);

  return columns;
};

const RecurringExpenses: FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation(['common', 'forms_common', 'finances']);

  const { categoryId } = useRouteParams<{ categoryId: string }>();

  const navigateBackOr = useNavigateBackOr();
  const { data: propertyId } = useGetCurrentPropertyId();

  const handleGoBack = () => navigateBackOr(propertyId ? getHomePath({ propertyId }) : undefined);

  const { data: bankAccounts = [] } = useGetRecurringExpensesBankAccountsQuery();
  const { data: budgetsList } = useGetBudgetsList();

  const yearsList = getYearsListFromBankAccounts(bankAccounts);

  const { from: fromDateInitial } = getFromToFullMonthDateRange(12);

  const [filters, setFilters] = useState<RecurringExpensesFilters>({
    mode: FinancesFilterSectionMode.Filter,
    fromDate: fromDateInitial,
    toDate: undefined,
    fromYear: R.last(yearsList),
    toYear: undefined,
    timeIntervalGroupingType: TimeIntervalsGroupingType.Monthly,
    includeIncompleteMonths: true,
  });

  const lastSyncedAt = getLastSyncedFromBankAccounts(bankAccounts, filters.bankAccountIds);

  useEffect(() => {
    if (!filters.fromYear && yearsList?.length) {
      setFilters({
        ...filters,
        fromYear: R.last(yearsList),
      });
    }
  }, [yearsList]);

  const debouncedFilters = useDebounce(
    filters,
    FILTER_DEBOUNCE_TIME,
  );

  const {
    data: budgets = [],
  } = useGetBudgetsQuery(propertyId ? { propertyId } : skipToken);

  const {
    data: filtration,
    isLoading: isFiltrationLoading,
    isFetching: isFiltrationFetching,
  } = useGetRecurringExpensesFiltrationQuery({
    from: debouncedFilters.fromDate?.toISOString(),
    to: debouncedFilters.toDate?.toISOString(),
    accounts: debouncedFilters.bankAccountIds,
    grouping: debouncedFilters.timeIntervalGroupingType,
    includeIncompleteMonths: debouncedFilters.includeIncompleteMonths,
    categories: categoryId === ALL_RECURRING_EXPENSES_CATEGORY ? undefined : categoryId,
  }, { skip: debouncedFilters.mode === FinancesFilterSectionMode.Compare });

  const {
    data: comparison,
    isLoading: isComparisonLoading,
    isFetching: isComparisonFetching,
  } = useGetRecurringExpensesComparisonQuery({
    year: debouncedFilters.fromYear,
    compareToYear: debouncedFilters.toYear,
    fromMonth: debouncedFilters.fromMonth,
    toMonth: debouncedFilters.toMonth,
    budget: debouncedFilters.budgetId,
    accounts: debouncedFilters.bankAccountIds,
    grouping: debouncedFilters.timeIntervalGroupingType,
    includeIncompleteMonths: debouncedFilters.includeIncompleteMonths,
    categories: categoryId === ALL_RECURRING_EXPENSES_CATEGORY ? undefined : categoryId,
  }, { skip: debouncedFilters.mode === FinancesFilterSectionMode.Filter });

  const isLoading = isFiltrationLoading || isComparisonLoading;
  const isFetching = isFiltrationFetching || isComparisonFetching;
  const isLoadingOrFetching = isLoading || isFetching;

  const selectedBudget = filters.budgetId ? budgets?.find((item) => item.id === filters.budgetId) : undefined;
  const selectedBudgetName = selectedBudget?.name;

  const { data: ExpensePaymentCategoryEnum } = useGetEnum(EnumType.ExpensePaymentCategory);

  const comparisonChartData = comparison?.chart?.map(
    debouncedFilters.budgetId
      ? (chartDataItem: ChartDataItem) => ({ ...chartDataItem, secondary: chartDataItem.line })
      : R.identity,
  );

  const chartData = debouncedFilters.mode === FinancesFilterSectionMode.Filter
    ? filtration?.chart
    : comparisonChartData;

  const filtrationExpense = filtration?.expenses || [];

  const comparisonExpense = getComparisonList(
    comparisonChartData || [],
    debouncedFilters.toYear || selectedBudget?.year,
    debouncedFilters.timeIntervalGroupingType,
  );

  const rows = debouncedFilters.mode === FinancesFilterSectionMode.Filter ? filtrationExpense : comparisonExpense || [];

  const costsLabel: string = t('finances:costs');

  const title = categoryId === ALL_RECURRING_EXPENSES_CATEGORY
    ? `${t('common:all_label')} ${costsLabel.toLowerCase()}`
    : (ExpensePaymentCategoryEnum || {})[categoryId];

  const filtrationColumns: Array<GridColDef> = useGetRecurringExpensesDataGridColumns({
    isSelectionMode: false,
  });

  const comparisonColumns = useGetComparisonColumns();

  const handleRowClick: GridEventListener<'rowClick'> = (params) => {
    const expense = params?.row as RecurringExpense;
    navigate(getPathWithPropertyIdOrInit(getRecurringExpensePath, { propertyId, categoryId, id: expense.id }));
  };

  return (
    <HomeListLayout
      ListHeaderComponent={
        <ExpensesComparisonChart
          data={chartData || []}
          groupingType={debouncedFilters.timeIntervalGroupingType}
          isLoading={isLoadingOrFetching}
          lastSyncedAt={lastSyncedAt}
          mode={filters.mode}
          primaryLabel={filters.fromYear ? `${t('forms_common:total')} ${filters.fromYear.toString()}` : undefined}
          secondaryLabel={
            filters.toYear
              ? `${t('forms_common:total')} ${filters.toYear.toString()}`
              : filters.budgetId
                ? `${t('forms_common:total')} ${selectedBudgetName}`
                : undefined
          }
        />
      }
      SideColumn={
        <>
          <Card sx={{ padding: 2 }}>
            <RecurringExpensesActions />
          </Card>
          <Card sx={{ padding: 2 }}>
            <RecurringExpensesFilterSection
              bankAccounts={bankAccounts}
              budgetsList={budgetsList}
              filters={filters}
              yearsList={yearsList}
              onChange={setFilters}
            />
          </Card>
          <Card sx={{ padding: 2 }}>
            <ExpensesPaymentCategoriesSection
              activeCategoryId={categoryId}
              showIncompleteCosts={filters.includeIncompleteMonths || false}
            />
          </Card>
        </>
      }
      breadcrumbsLinks={[
        {
          link: getPathWithPropertyIdOrInit(getFinancesPath, { propertyId }),
          name: t('finances:finances'),
        },
        {
          link: getPathWithPropertyIdOrInit(getRecurringExpensesCategoriesPath, { propertyId }),
          name: t('finances:fixed_expenses'),
        },
      ]}
      columns={debouncedFilters.mode === FinancesFilterSectionMode.Filter ? filtrationColumns : comparisonColumns}
      initialState={
        debouncedFilters.mode === FinancesFilterSectionMode.Filter
          ? {
            sorting: {
              sortModel: [
                {
                  field: 'totalAmount',
                  sort: 'desc',
                },
              ],
            },
          }
          : undefined
      }
      isLoading={isLoadingOrFetching}
      rows={rows}
      sideDrawerElements={
        [
          <RecurringExpensesActions
            isSideDrawer
            key={RecurringExpensesActions.name}
          />,
          <RecurringExpensesFilterSection
            bankAccounts={bankAccounts}
            budgetsList={budgetsList}
            filters={filters}
            key={RecurringExpensesFilterSection.name}
            yearsList={yearsList}
            onChange={setFilters}
          />,
          <ExpensesPaymentCategoriesSection
            activeCategoryId={categoryId}
            key={ExpensesPaymentCategoriesSection.name}
            showIncompleteCosts={filters.includeIncompleteMonths || false}
          />,
        ]
      }
      title={title}
      onBack={handleGoBack}
      onRowClick={debouncedFilters.mode === FinancesFilterSectionMode.Filter ? handleRowClick : undefined}
    />
  );
};

export default RecurringExpenses;
